import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Styled from './RadioGroup.styles';
import RadioButton from './RadioButton';
// @ts-ignore
const RadioGroup = ({
    // @ts-ignore

    label,
    // @ts-ignore

    value,
    // @ts-ignore

    id,
    // @ts-ignore

    type,
    // @ts-ignore

    options,
    // @ts-ignore

    update,
}) => {
    const [_value, setValue] = useState(value);

    const handleUpdate = (newValue: any) => {
        setValue(newValue);
        update(newValue);
    };

    return (
        <Styled.Container id={id}>
            {label && <Styled.Label >{label}</Styled.Label>}
            <Styled.InnerContainer className={type}>
                {options.map((opt: { label: string | null | undefined; value: any; }, i: React.Key | null | undefined) => (
                    <RadioButton
                        key={i}
                        label={opt.label}
                        value={opt.value}
                        type={type}
                        //selected={opt.value === _value}
                        update={handleUpdate}
                    />
                ))}
            </Styled.InnerContainer>
        </Styled.Container>
    );
};

RadioGroup.propTypes = {
    label: PropTypes.string,
    value: PropTypes.any,
    id: PropTypes.string,
    type: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.any,
        label: PropTypes.string})),
    update: PropTypes.func,
};

export default RadioGroup;
