/** Common Formatters **/
//TYPES: dollar, phone, zip

import { number } from "prop-types";

class Format {

    //TODO: DOLLAR, DOLLARNEGATIVE AND DOLLARNEGATIVEPOSITIVE should be combined to have an inclusive dollar format
    static dollar(str, prevStr, cursorStart ,prevCursorStart, maxLength=12) {
        const allowedRegEx = /[^\d.]/g;
        if (typeof str === 'number') str = str + '';
        const rawCursorStr = str.slice(0, cursorStart).replace(allowedRegEx, '');

        // Code for Truncate to two decimal for str
        const decimalCheck = str.toString().split('.');
        if (decimalCheck.length === 2 && decimalCheck[1].length > 2) {
            str = decimalCheck[0] + '.' + decimalCheck[1].slice(0, 2);
        }
        const allowed = str.replace(allowedRegEx, '');

        // Check if there's more than one decimal point or if there's only a dot
        if (allowed.split('.').length > 2 || allowed === '.') return prevStr;

        const splitDecimal = allowed.split('.');
        if (splitDecimal.length > 2) return prevStr;
        let dollarInteger = splitDecimal[0] || '';

        dollarInteger = dollarInteger.replace(/\,/g, '');
        let decimal = '';
        if (typeof splitDecimal[1] === 'string') {
            if (splitDecimal[1].length > 2) return prevStr;
            else if (splitDecimal[1].length === 0) decimal = '.';
            else decimal = '.' + splitDecimal[1];
        }

        const cents = decimal;
        let dollars = '';
        let digitCounter = 0;

        for (let i = dollarInteger.length; i--; i >= 0) {
            if (digitCounter && digitCounter % 3 === 0)
                dollars = dollarInteger[i] + ',' + dollars;
            else dollars = dollarInteger[i] + dollars;
            digitCounter++;
        }
        let display = dollars + cents; // '1,234,567.89'

        // control cursor when editing the middle of the number (remember, commas are auto-added)
        let found = false;
        let cursorEnd = null;
        for (let j = rawCursorStr.length - 1; j <= display.length; j++) {
            const temp = display.slice(0, j).replace(allowedRegEx, '');
            if (temp === rawCursorStr) {
                found = true;
                cursorEnd = j;
                break;
            }
        }
        //ERRORING
        // if (found) return [display, cursorEnd];
        //Puts limiter on number of digits
        if (dollars.length >= maxLength) display = prevStr;
        if (found) return display;
        return display;
    }

    static dollarV2(str, prevStr, cursorStart, prevCursorStart) {
        const maxLength = 12;
        const display= Format.dollar(str, prevStr, cursorStart, prevCursorStart, maxLength);
        return [display, display.length];
    }

    //Formats negative for dollar amounts
    static dollarNegative(str, prevStr, cursorStart, prevCursorStart, maxLength) {
        const negativeDollar = Format.dollar(str.toString(), prevStr, cursorStart, prevCursorStart, maxLength);
        return negativeDollar ? (typeof negativeDollar === 'string' && negativeDollar.includes('-'))? negativeDollar: '-' + negativeDollar : negativeDollar;
    }

    //Formats negative or positive dollar amounts
    static dollarNegativePositive(str, prevStr, cursorStart, prevCursorStart, maxLength) {
        const dollar = Format.dollar(str, prevStr, cursorStart, prevCursorStart, maxLength);
        let checkNegativeValue;
        if(str.toString().includes("-")){ checkNegativeValue = true;}
        else{ checkNegativeValue = false;}
        return (dollar && Math.sign(str) === -1) || checkNegativeValue ? '-' + dollar : dollar;
    }

    static zip(str, prevStr) {
        if (!/^[\d-]*$/.test(str) || str?.length > 10) {
            return prevStr;
        }

        let newValue = str;
        if (newValue?.length > 5) {
            newValue = str.replace(/-/g, '');
            newValue = newValue.substring(0, 5) + '-' + newValue.substring(5);
        }

        return [newValue,newValue?.length];
    }

    static alpha(str, prevStr) {
        if (!Format._checkIfString(str)) return prevStr;
        if (!str.match(/^[a-zA-Z]+$/)) return prevStr;
        else return str;
    }

    static alphaSpace(str, prevStr) {
        if (!Format._checkIfString(str)) return prevStr;
        if (!str.match(/^[a-zA-Z ]+$/)) return prevStr;
        else return str;
    }

    static alphaNumeric(str, prevStr) {
        if (!Format._checkIfString(str)) return prevStr;
        if (!str.match(/^[a-zA-Z0-9]+$/)) return prevStr;
        else return str;
    }

    static alphaNumericSpace(str, prevStr, maxLength=200) {
        if (!Format._checkIfString(str)) return prevStr;
        if (!str.match(/^[a-zA-Z0-9 ]+$/)) return prevStr;
        if (str.length > maxLength) return prevStr.substring(0, maxLength);
        else return str;
    }
    static numeric(str, prevStr) {
        if (!Format._checkIfString(str)) return prevStr;
        if (!str.match(/^[0-9]+$/)) return prevStr;
        else return str;
    }

    static numericLimitTwo(str, prevStr) {
        if (typeof str === 'number') {str = str.toString();}
        if (!Format._checkIfString(str)) return prevStr;
        if (!str.match(/^[0-9]{0,2}$/i)) return prevStr;
        else return str;
    }

    static numericLimitThree(str, prevStr) {
        if (typeof str === 'number') {str = str.toString();}
        if (!Format._checkIfString(str)) return prevStr;
        if (!str.match(/^[0-9]{0,3}$/i)) return prevStr;
        else return str;
    }

    static numericPercent(str, prevStr) {
        const sanitizedValue = str.toString().replace(/[^0-9.%]/g, '');
        // Remove leading zeros, except when followed by a dot
        const noLeadingZeros = sanitizedValue.replace(/^0+(?!$|\.)/, '');
        // Remove multiple dots, keeping only the first one
        const noMultipleDots = noLeadingZeros.replace(/\.(?=.*\.)/g, '');
        // Limit decimal places to 4
        const roundedValue = noMultipleDots.replace(/(\.\d{4})\d+/g, '$1');
        // Add a leading zero if the value is less than 1
        const numericValue = parseFloat(roundedValue);
        const formattedValue = numericValue < 1 && numericValue >= 0 ? '0' + roundedValue : roundedValue;
        //Check if the value exceeds 100 and revert to previous value if it does
        if (numericValue > 100) {
            return prevStr;
        }
        return formattedValue;
    }

    static numericPercentAll(str, prevStr) {
        const sanitizedValue = str.toString().replace(/[^0-9.%]/g, '');
        // Remove leading zeros, except when followed by a dot
        const noLeadingZeros = sanitizedValue.replace(/^0+(?!$|\.)/, '');
        // Remove multiple dots, keeping only the first one
        const noMultipleDots = noLeadingZeros.replace(/\.(?=.*\.)/g, '');
        // Limit decimal places to 4
        const roundedValue = noMultipleDots.replace(/(\.\d{4})\d+/g, '$1');
        // Add a leading zero if the value is less than 1
        const numericValue = parseFloat(roundedValue);
        const formattedValue = numericValue < 1 && numericValue >= 0 ? '0' + roundedValue : roundedValue;
        // Check if the value exceeds 100 and revert to previous value if it does
        // if (numericValue > 100) {
        //   return prevStr;
        // }
        return formattedValue;
    }
    static numericPercentSpecific(str, prevStr, maxLength) {
        const sanitizedValue = str.toString().replace(/[^0-9.]/g, '');
        // Remove leading zeros, except when followed by a dot
        const noLeadingZeros = sanitizedValue.replace(/^0+(?!$|\.)/, '');
        // Remove multiple dots, keeping only the first one
        const noMultipleDots = noLeadingZeros.replace(/\.(?=.*\.)/g, '');
        // Limit decimal places to 4
        const roundedValue = noMultipleDots.replace(/(\.\d{3})\d+/g, '$1');
        // Add a leading zero if the value is less than 1
        const numericValue = parseFloat(roundedValue);
        const formattedValue = numericValue < 1 && numericValue >= 0 ? '0' + roundedValue : roundedValue;
        // Check if the value exceeds 100 and revert to previous value if it does
        if (numericValue > 100) {
            return prevStr;
        }
        return formattedValue;
    }

    /** "private" methods **/
    static _checkIfString(str) {
        if (typeof str !== 'string') {
            console.error(
                'Format function received a non-string argument of type',
                typeof str,
                'and value',
                str
            );
            return false;
        }
        return true;
    }
    static twoNumberRange(str, prevStr) {
        if (str.length === 0) return "";
        const [lowerBound, upperBound] = str.split(' - ');
        if (isNaN(lowerBound) && isNaN(upperBound)) return "";
        else if (lowerBound === '0' && upperBound === '0') return "";
        else if (!lowerBound && !upperBound) return "";
        let formattedLowerBound = lowerBound !== undefined && lowerBound !== null ? parseFloat(lowerBound.replace(/,/g, '')).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : '';
        let formattedUpperBound = upperBound !== undefined && upperBound !== null ? parseFloat(upperBound.replace(/,/g, '')).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') : '';
        const numberRange = (formattedLowerBound == undefined || formattedUpperBound == undefined) ? "" : `${formattedLowerBound} - ${formattedUpperBound}`;
        return (formattedLowerBound === "" && formattedUpperBound !== "") ? upperBound : (formattedLowerBound !== "" && formattedUpperBound === "") ? lowerBound : numberRange;
    }

    // This formatter allows all special characters and strings, and limits the string length
    static stringFormat(str, prevStr, maxLength=200) {
        if (!Format._checkIfString(str)) return prevStr;
        if (str.length > maxLength) return str.substring(0, maxLength);
        return str;
    }


}

export default Format;
