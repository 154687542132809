import React, { ReactNode } from "react";
import ReactDOM from "react-dom";
import CloseIcon from "@mui/icons-material/Close";
import { Modal, Box, Typography, Backdrop, Button } from "@mui/material";
import { ReactComponent as MyIcon } from "assets/images/office.svg";
interface EPCModalProps {
  open: boolean;
  onClose: () => void;
  title?: any;
  children: ReactNode;
  askConfirmation?: boolean;
  onConfirmClick?: () => void;
  disableEscapeKeyDown?: boolean;
  disableClickOutside?: boolean;
  showOkButton?: boolean;
  isHeader?: boolean;
  width?: string | number;
  height?: string | number; // Optional height
  isContinue?: boolean;
  onContinue?: () => void;
  oncancel?: () => void;
  isProvidersHeader?: boolean;
}

const EPCModal: React.FC<EPCModalProps> = ({
  open,
  onClose,
  title = "Information",
  children,
  width,
  askConfirmation = false,
  onConfirmClick,
  disableEscapeKeyDown = false,
  disableClickOutside = false,
  showOkButton = false,
  isHeader = true,
  height,
  isContinue = false,
  onContinue,
  isProvidersHeader = false,
}) => {
  if (!open) return null;

  return ReactDOM.createPortal(
    <Modal
      open={open}
      onClose={onClose}
      disableEscapeKeyDown={disableEscapeKeyDown}
      BackdropProps={disableClickOutside ? { onClick: () => {} } : undefined}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: width || 400,
          bgcolor: "background.paper",
          border: "none",
          boxShadow:
            "0 8px 24px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.12)",

          animation: "fade-in 0.3s",
          // overflowY: 'auto',
          "&:focus-visible": {
            outline: "none",
          },
        }}
      >
        {isProvidersHeader && (
          <Box
            px={4}
            pt={3}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
          >
            <>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mr={6}
                borderRadius="8px"
                width="40px"
                height="40px"
                sx={{
                  backgroundColor: (theme) =>
                    theme.palette.customColors.buttonColor,
                }}
              >
                <MyIcon style={{ fontSize: 24, fill: "#ffff" }} />
              </Box>

              <Typography sx={{ fontSize: "22px", fontWeight: 600 }}>
                Select Settlement Office
              </Typography>
            </>
          </Box>
        )}
        {isHeader && (
          <Box
            display="flex"
            alignItems="center"
            sx={{
              bgcolor: "#0075AA",
              px: 4,
              py: 2,
              overflowY: "auto",
              "&:focus-visible": {
                outline: "none",
              },
            }}
          >
            <Typography variant="h6" component="div" flexGrow={1} color="white">
              {title}
            </Typography>
            <CloseIcon
              onClick={onClose}
              sx={{ cursor: "pointer", color: "#FFF" }}
            />
          </Box>
        )}
        <Box sx={{ px: 4, my: 2 }}>{children}</Box>
        {showOkButton && (
          <Box display="flex" justifyContent="end" sx={{ px: 2 }}>
            <Button
              size="small"
              onClick={onClose}
              variant="contained"
              sx={{ mr: 1, mb: 2, p: "0 !important" }}
            >
              OK
            </Button>
          </Box>
        )}
        {askConfirmation && (
          <Box my={2} px={2} display="flex" justifyContent="flex-end">
            <Button
              size="small"
              onClick={onConfirmClick}
              variant="contained"
              color="primary"
            >
              Yes
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              size="small"
              color="inherit"
              onClick={onClose}
              variant="outlined"
              sx={{ mr: 1 }}
            >
              No
            </Button>
          </Box>
        )}
        {isContinue && (
          <Box
            sx={{ display: "flex", justifyContent: "flex-start", my: 4, px: 4 }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{ mr: 1 }}
              onClick={onContinue}
            >
              Continue
            </Button>
            <Button variant="outlined" color="secondary" onClick={onClose}>
              Cancel
            </Button>
          </Box>
        )}
      </Box>
    </Modal>,
    document.body
  );
};

export default EPCModal;
