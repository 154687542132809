import React, { useState } from "react";
import Button from "../Button/Button";
import { createEPCTransaction } from "utils/saveOrderupdates";
import {
  setClientRefId,
  setFeeList,
  setPricingQuotes,
  setpriceQuoteTransactionID,
} from "redux/features/feeQuoteSlice";
import { useDispatch, useSelector } from "react-redux";
import { getPricingQuoteReferenceNumber } from "utils/helpers";
import { getFullUrl } from "utils/UrlMap";
import { ApiPathConsts } from "constants/strings";
import { RootState } from "interfaces/rootState";
import { PriceQuote } from "utils/feeList/priceQuotes";
import axiosEcpInstance from "lib/axiosEcp";
import Modal from "@mui/material/Modal";
import ImportFeeGrid from "componnets/organisms/ImportFeeGrid";
import { fetchGetFeeImport } from "componnets/StewartSRC/utils";
import type { AppDispatch } from "redux/store/index";
import priceRes from "componnets/organisms/ImportFeeGrid/data.json";

interface ImportFeeButtonProps {}

const ImportFeeButton: React.FC<ImportFeeButtonProps> = ({}) => {
  const { loanParams, feeQuote, lender, Loan, ctsOffice } = useSelector(
    (state: RootState) => ({
      Loan: state.loan,
      lender: state.lender,
      loanParams: state.loanParams.data,
      loan: state.loan.data.loan ?? {},
      ctsOffice: state.officeData.officeData.Stewart?.OfficeInfo ?? {},
      transactionId: state.loan.transactionObject.transactionId,
      transactionStatus: state.loan.transactionStatus,

      feeQuote: state.feeQuotes,
    })
  );
  const { options: feeQuoteOptions, transactionID: feeQuoteTransactionId } =
    feeQuote;
  const { lenderSettings } = lender || {};
  const { options } = Loan;
  const loanData = Loan?.data?.loan ?? {};
  const {
    IsCTS,

    localOfficeAddress,
  } = options;
  const { feeList } = feeQuoteOptions;
  const Address = IsCTS ? ctsOffice : localOfficeAddress;

  const { loanNumber, property } = loanData;
  const { state } = property ?? {};
  const { clientID } = loanParams;
  const dispatch = useDispatch<AppDispatch>();
  const [toggleFeeImportGrid, setToggleFeeImportGrid] =
    useState<boolean>(false);

  const importFee = async () => {
    if (!(process.env.NODE_ENV === "development")) {
      const response = await createEPCTransaction({}, "Fee Quotes");
      dispatch(setpriceQuoteTransactionID(response?.id));
    }

    setToggleFeeImportGrid(true);
    const clientRefId = getPricingQuoteReferenceNumber(clientID, loanNumber);

    try {
      const response = await dispatch(fetchGetFeeImport(clientRefId));

      const QuoteData = response?.payload?.QuoteResponse ?? {};
      const priceQuotes = PriceQuote.load(
        Address?.name ?? "",
        state,
        lenderSettings,
        QuoteData
      );
      dispatch(setPricingQuotes(QuoteData));
      dispatch(setFeeList(priceQuotes.feeList));
      dispatch(setClientRefId(clientRefId));
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      <Modal
        open={toggleFeeImportGrid}
        onClose={() => setToggleFeeImportGrid(false)}
        aria-labelledby="l-l-title"
        aria-describedby="l-modal-description"
      >
        <ImportFeeGrid
          rows={feeList}
          onClose={() => setToggleFeeImportGrid(false)}
          onImportSelectedFees={() => {}}
        />
      </Modal>
      <Button onClick={importFee} label="Import Fees" type="secondary" />
    </>
  );
};

export default ImportFeeButton;
