import { EncompassFieldIDs } from "constants/strings";
import { PriceQuote, PriceQuoteFee } from "./priceQuotes";
import { getAmount } from "./utils";

export function addTitleInsuranceFees(priceQuote: PriceQuote, data: any): void {
  const {
    ownerTitleServiceChargeAmount,
    BuyerAmount,
    ownerTRIDPremiumAmount,
    shouldPickTRIDValues,
    TridOwnerSplitPremium,
    PricingtridOwnerSplitsSalesTax,
    TridOwnerSplitSellerPremium,
    PricingtridOwnerSplitsSellerSalesTax,
    pricingRMOwnerPremium,
    OwnersSplitBuyerAmount,
    OwnersSplitBuyerPremium,
    OwnersSplitBuyerSalesTax,
    lenderSplitsSellerpremium,
    OwnerrateSplitsSellerSalesTax,
    ownerSplitsSellertitleService,
    lenderTitleServiceChargeAmount,
    lendersSplitBuyerTitleservicecharge,
    lenderSplitsSellertitleService,
    lenderTRIDPremiumAmount,
    TridLenderSplitPremium,
    PricingLenderSplitsSalesTax,
    TridLenderSplitSellerPremium,
    PricingtridLenderSplitsSellerSalesTax,
    rateMannualLenderpremium,
    rateMannualLenderSalesTax,
    lenderTitleServiceCharge,
    lendersSplitBuyerTitleservicePremium,
    lendersSplitBuyerTitleserviceSalesTax,
    lendersSplitSellerTitleservicePremium,
    lenderSplitsSellerSalesTax,
  } = data;
  console.log(data, "data");

  if (ownerTitleServiceChargeAmount !== 0) {
    const ownerTitleServiceFee: PriceQuoteFee = {};
    ownerTitleServiceFee.HudLineNumber = "1101";
    ownerTitleServiceFee.Description = "Owner's Policy - Title Service Charge";
    ownerTitleServiceFee.EncompassFieldID_Description =
      EncompassFieldIDs.HUDLine1101aDescription;
    ownerTitleServiceFee.Payee = data.officeName;
    ownerTitleServiceFee.EncompassFieldID_Payee =
      EncompassFieldIDs.HUDLine1101aPayee;
    ownerTitleServiceFee.BuyerAmount = getAmount(BuyerAmount);
    ownerTitleServiceFee.EncompassFieldID_BuyerAmount =
      EncompassFieldIDs.HUDLine1101aBuyerAmount;
    ownerTitleServiceFee.SellerAmount = getAmount(
      ownerSplitsSellertitleService
    );
    ownerTitleServiceFee.EncompassFieldID_SellerAmount =
      EncompassFieldIDs.HUDLine1101aSellerAmount;
    priceQuote.feeList.push(ownerTitleServiceFee);
  }

  if (ownerTRIDPremiumAmount !== 0 && shouldPickTRIDValues) {
    const ownerTRIDPremiumFee: PriceQuoteFee = {};

    ownerTRIDPremiumFee.HudLineNumber = "1103";
    ownerTRIDPremiumFee.Description = "TRID - Owner's Policy";
    ownerTRIDPremiumFee.Payee = priceQuote.officeName;
    ownerTRIDPremiumFee.EncompassFieldID_Payee =
      EncompassFieldIDs.HUDLine1103Payee;
    ownerTRIDPremiumFee.BuyerAmount =
      getAmount(TridOwnerSplitPremium) +
      getAmount(PricingtridOwnerSplitsSalesTax);
    ownerTRIDPremiumFee.EncompassFieldID_BuyerAmount =
      EncompassFieldIDs.HUDLine1103BuyerAmount;
    ownerTRIDPremiumFee.SellerAmount =
      getAmount(TridOwnerSplitSellerPremium) +
      getAmount(PricingtridOwnerSplitsSellerSalesTax);
    ownerTRIDPremiumFee.EncompassFieldID_SellerAmount =
      EncompassFieldIDs.HUDLine1103SellerAmount;
    priceQuote.feeList.push(ownerTRIDPremiumFee);
  } else {
    const ownerPremiumAmount = getAmount(pricingRMOwnerPremium) || 0;

    if (ownerPremiumAmount !== 0) {
      const ownerPremiumFee: PriceQuoteFee = {};
      ownerPremiumFee.HudLineNumber = "1103";
      ownerPremiumFee.Description = "Owner's Policy";
      ownerPremiumFee.Payee = priceQuote.officeName;
      ownerPremiumFee.EncompassFieldID_Payee =
        EncompassFieldIDs.HUDLine1103Payee;

      // Calculate BuyerAmount
      ownerPremiumFee.BuyerAmount =
        getAmount(OwnersSplitBuyerPremium) +
        getAmount(OwnersSplitBuyerSalesTax) -
        getAmount(OwnersSplitBuyerAmount);
      ownerPremiumFee.EncompassFieldID_BuyerAmount =
        EncompassFieldIDs.HUDLine1103BuyerAmount;

      // Calculate SellerAmount
      ownerPremiumFee.SellerAmount =
        getAmount(lenderSplitsSellerpremium) +
        getAmount(OwnerrateSplitsSellerSalesTax) -
        getAmount(ownerSplitsSellertitleService);
      ownerPremiumFee.EncompassFieldID_SellerAmount =
        EncompassFieldIDs.HUDLine1103SellerAmount;

      priceQuote.feeList.push(ownerPremiumFee);
    }
  }

  if (lenderTitleServiceChargeAmount !== 0) {
    let buyerSplit = getAmount(lendersSplitBuyerTitleservicecharge);
    let sellerSplit = getAmount(lenderSplitsSellertitleService);

    if (buyerSplit === 0 && sellerSplit === 0) {
      buyerSplit = lenderTitleServiceChargeAmount;
    }

    const lenderTitleServiceFee: PriceQuoteFee = {};
    lenderTitleServiceFee.HudLineNumber = "1109";
    lenderTitleServiceFee.Description =
      "Lender's Policy - Title Service Charge";
    lenderTitleServiceFee.EncompassFieldID_Payee =
      EncompassFieldIDs.HUDLine1109Payee;
    lenderTitleServiceFee.Payee = priceQuote.officeName;
    lenderTitleServiceFee.EncompassFieldID_Description =
      EncompassFieldIDs.HUDLine1109Description;
    lenderTitleServiceFee.BuyerAmount = buyerSplit;
    lenderTitleServiceFee.EncompassFieldID_BuyerAmount =
      EncompassFieldIDs.HUDLine1109BuyerAmount;
    lenderTitleServiceFee.SellerAmount = sellerSplit;
    lenderTitleServiceFee.EncompassFieldID_SellerAmount =
      EncompassFieldIDs.HUDLine1109SellerAmount;
    lenderTitleServiceFee.EncompassFieldID_BuyerCanShop =
      EncompassFieldIDs.HUDLine1109BuyerCanShop;
    priceQuote.feeList.push(lenderTitleServiceFee);
  }

  if (lenderTRIDPremiumAmount !== 0 && shouldPickTRIDValues) {
    const lenderTRIDPremiumFee: PriceQuoteFee = {};
    lenderTRIDPremiumFee.HudLineNumber = "1104";
    lenderTRIDPremiumFee.Description = "TRID - Lender's Policy";
    lenderTRIDPremiumFee.Payee = priceQuote.officeName;
    lenderTRIDPremiumFee.EncompassFieldID_Payee =
      EncompassFieldIDs.HUDLine1104Payee;
    lenderTRIDPremiumFee.BuyerAmount =
      getAmount(TridLenderSplitPremium) +
      getAmount(PricingLenderSplitsSalesTax);
    lenderTRIDPremiumFee.EncompassFieldID_BuyerAmount =
      EncompassFieldIDs.HUDLine1104BuyerAmount;
    lenderTRIDPremiumFee.SellerAmount =
      getAmount(TridLenderSplitSellerPremium) +
      getAmount(PricingtridLenderSplitsSellerSalesTax);
    lenderTRIDPremiumFee.EncompassFieldID_SellerAmount =
      EncompassFieldIDs.HUDLine1104SellerAmount;
    priceQuote.feeList.push(lenderTRIDPremiumFee);
  } else {
    const lenderPremiumAmount =
      getAmount(rateMannualLenderpremium) +
      getAmount(rateMannualLenderSalesTax) -
      getAmount(lenderTitleServiceCharge);

    if (lenderPremiumAmount !== 0) {
      let buyerSplit =
        getAmount(lendersSplitBuyerTitleservicePremium) +
        getAmount(lendersSplitBuyerTitleserviceSalesTax) -
        getAmount(lendersSplitBuyerTitleservicecharge);

      let sellerSplit =
        getAmount(lendersSplitSellerTitleservicePremium) +
        getAmount(lenderSplitsSellerSalesTax) -
        getAmount(lenderSplitsSellertitleService);

      if (buyerSplit === 0 && sellerSplit === 0) {
        buyerSplit = lenderPremiumAmount;
      }

      const lenderPremiumFee: PriceQuoteFee = {};
      lenderPremiumFee.HudLineNumber = "1104";
      lenderPremiumFee.Description = "Lender's Policy";
      lenderPremiumFee.Payee = priceQuote.officeName;
      lenderPremiumFee.EncompassFieldID_Payee =
        EncompassFieldIDs.HUDLine1104Payee;
      lenderPremiumFee.BuyerAmount = buyerSplit;
      lenderPremiumFee.EncompassFieldID_BuyerAmount =
        EncompassFieldIDs.HUDLine1104BuyerAmount;
      lenderPremiumFee.SellerAmount = sellerSplit;
      lenderPremiumFee.EncompassFieldID_SellerAmount =
        EncompassFieldIDs.HUDLine1104SellerAmount;
      priceQuote.feeList.push(lenderPremiumFee);
    }
  }
}
