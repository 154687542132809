import styled from '@emotion/styled'
import colors from "../../../Theme/colors";
import typography from "../../../Theme/typography";

export const Input = styled.input`
  height: 32px;
  width: 426px;
  border:none;
  margin-left:2px;
  padding: 6px 12px 6px 12px;
  color: ${colors.input};
  outline: none;
  box-sizing: border-box;
  text-align: right;
  ${typography.grid}
  &.primary {
    
 }
  &.small {
    width: 241px;

  }
`;

export const Border = styled.div`
  border: solid 1px ${colors.border};
  border-radius: 4px;
  width: 434px;
  margin: 0 12px;
  &:hover {
    border: 2px solid ${colors.titleBlue};
    margin: -1px 12px;
  }
  &.primary {

  }
  &.small {
    width: 257px;
  }
`;

// export const SmallBorder = styled.div`
//   border: solid 1px ${colors.border};
//   border-radius: 4px;
//   width: 257px;
//   margin: 0 12px;
// `;
//
// export const SmallInput = styled.input`
//   height: 32px;
//   width: 241px;
//   border:none;
//   margin-left:2px;
//   padding: 6px 12px 6px 12px;
//   color: ${colors.input};
//   outline: none;
//   box-sizing: border-box;
//   text-align: right;
//   ${typography.grid}
//   &:hover {
//     border: 2px solid;
//   }
// `;
