//export const transactionType =  'Refi';
export const transactionType =  'Purchase';
export const defaultSections = [
    {
        // transaction: {
            title: 'Transaction Details',
            accordion: false,
            type: 'TextOnly',
    },
    {
        // titleInsurance: {
            title: 'Title Insurance Premium',
            accordion: false,
            type: 'VerticalGrid',
    },
    {
        // endorsements: {
            title: 'Endorsements / Other Products',
            accordion: false,
            type: 'EndorsementGrid',
            Titles: ['Lender’s Endorsements','Buyer Amount', 'Seller Amount','Total'],
            TitlesTwo: ['Owners’s Endorsements','Buyer Amount', 'Seller Amount','Total'],
            buttonRight: '+ Add Endorsements',
            buttonTypeRight: 'primarySm',
    },
    {
        // titleService: {
            title: 'Title Service Fees',
            accordion: false,
            type: 'TitleGrid',
            Titles: ['','Buyer Amount', 'Seller Amount','Total'],
            buttonRight: 'Select Settlement Office',
            buttonTypeRight: 'secondarySm',
    },
    {
        // Recording: {
            title: 'Recording Fees and Taxes',
            accordion: false,
            type: 'RecordingGrid',
            Titles: ['','Buyer Amount', 'Seller Amount','Total']
    }
]


export const DummyData = {
    endorsements: {
        lender: [
            {
                description: 'T-19 Restrictions, Encroachments, Minerals (Residential)',
                premium: '$340.00',
                buyer: '$130.00',
                seller: '$20.00',
                inputs: true,
                element: 'inputRow',
                formatter: "dollar",
            },
            {
                description: 'T-30 Tax Deletion',
                premium: '$310.00',
                buyer: '$100.00',
                seller: '$20.00',
                inputs: true,
                element: 'inputRow',
                formatter: "dollar",
            },
            {
                description: 'T-36 Environmental Protection Lien',
                premium: '$380.00',
                buyer: '$10.00',
                seller: '$70.00',
                inputs: true,
                element: 'inputRow',
                formatter: "dollar",
            },
            {
                description: 'Total for Endorsements (pre-tax)',
                premium: '$360.00',
                buyer: '$60.00',
                seller: '$20.00',
                inputs: false,
                element: 'infoRow',
                formatter: "dollar",
            },
            {
                description: 'Total Tax for Endorsements',
                premium: '$370.00',
                buyer: '$10.00',
                seller: '$50.00',
                inputs: false,
                element: 'infoRow',
                formatter: "dollar",
            },
            {
                description: 'Total for Endorsements (including tax)',
                premium: '$320.00',
                buyer: '$90.00',
                seller: '$40.00',
                inputs: false,
                element: 'infoRow',
                formatter: "dollar",
            },
            {
                description: 'Total Premium and Endorsements (including tax):',
                premium: '$300.00',
                buyer: '$10.00',
                seller: '$80.00',
                inputs: false,
                element: 'infoRow',
                formatter: "dollar",
            },
        ],
    owner : [
    {
        description: 'T-24.1 Non-Imputation - Mezzanine Financing',
        premium:'$3240.00',
        buyer: '$130.00',
        seller: '$120.00',
        inputs: true,
        element: 'inputRow',
        formatter: "dollar",
    },
    {
        description: 'T-23 Access',
        premium:'$6630.00',
        buyer: '$400.00',
        seller: '$270.00',
        inputs: true,
        element: 'inputRow',
        formatter: "dollar",
    },
    {
        description: 'T-24.1 Non-Imputation - Mezzanine Financing',
        premium:'$80.00',
        buyer: '$108.00',
        seller: '$570.00',
        inputs: true,
        element: 'inputRow',
        formatter: "dollar",
    },
    {
        description: 'T-25.1 Contiguity',
        premium:'$3760.00',
        buyer: '$360.00',
        seller: '$270.00',
        inputs: true,
        element: 'inputRow',
        formatter: "dollar",
    },
    {
        description: 'Total Tax for Endorsements',
        premium:'$3870.00',
        buyer: '$130.00',
        seller: '$150.00',
        inputs: false,
        element: 'infoRow',
        formatter: "dollar",
    },
    {
        description: 'Total for Endorsements (including tax)',
        premium:'$9320.00',
        buyer: '$190.00',
        seller: '$420.00',
        inputs: false,
        element: 'infoRow',
        formatter: "dollar",
    },
    {
        description: 'Total Premium and Endorsements (including tax):',
        premium:'$6300.00',
        buyer: '$140.00',
        seller: '$280.00',
        inputs: false,
        element: 'infoRow',
        formatter: "dollar",
    }
]},
    titleInsurance: {
        lender: [
            {
                description: 'Policy/Rate Type',
                premium: '$310.00',
                buyer: '$100.00',
                seller: '$20.00',
                inputs: true,
                element: 'dropdownRow',
                type: 'dropdown',
                formatter: "dollar",
            },
            {
                description: 'Buyer %',
                premium: '$380.00',
                buyer: '$10.00',
                seller: '$70.00',
                inputs: true,
                element: 'inputRow',
                type: 'input',
                formatter: "dollar",
            },
            {
                description: 'Buyer Amount',
                premium: '$360.00',
                buyer: '$60.00',
                seller: '$20.00',
                inputs: true,
                element: 'inputRow',
                type: 'input',
                formatter: "dollar",
            },
            {
                description: 'Tax',
                premium: '$370.00',
                buyer: '$10.00',
                seller: '$50.00',
                inputs: false,
                element: 'infoRow',
                type: 'static',
                formatter: "dollar",
            },
            {
                description: 'TRID',
                premium: '$320.00',
                buyer: '$90.00',
                seller: '$40.00',
                inputs: false,
                element: 'infoRow',
                type: 'static',
                formatter: "dollar",
            },
            {
                description: 'Tax-TRID',
                premium: '$300.00',
                buyer: '$10.00',
                seller: '$80.00',
                inputs: false,
                element: 'infoRow',
                type: 'static',
                formatter: "dollar",
            },
            {
                description: 'Seller %',
                premium: '$300.00',
                buyer: '$10.00',
                seller: '$80.00',
                inputs: true,
                element: 'inputRow',
                type: 'input',
                formatter: "dollar",
            },
            {
                description: 'Seller Amount',
                premium: '$300.00',
                buyer: '$10.00',
                seller: '$80.00',
                inputs: true,
                element: 'inputRow',
                type: 'input',
                formatter: "dollar",
            },
            {
                description: 'Tax',
                premium: '$300.00',
                buyer: '$10.00',
                seller: '$80.00',
                inputs: false,
                element: 'infoRow',
                type: 'static',
                formatter: "dollar",
            },
            {
                description: 'TRID',
                premium: '$300.00',
                buyer: '$10.00',
                seller: '$80.00',
                inputs: false,
                element: 'infoRow',
                type: 'static',
                formatter: "dollar",
            },
            {
                description: 'Tax-TRID',
                premium: '$300.00',
                buyer: '$10.00',
                seller: '$80.00',
                inputs: false,
                element: 'infoRow',
                type: 'static',
                formatter: "dollar",
            },
            {
                description: 'Total',
                premium: '$300.00',
                buyer: '$10.00',
                seller: '$80.00',
                inputs: false,
                element: 'totalRow',
                type: 'static',
                formatter: "dollar",
            },
        ],
      },
    titleServices: {
        lender: [
            {
                description: 'Title Closing Fee',
                premium: '$310.00',
                buyer: '$100.00',
                seller: '$20.00',
                inputs: true,
                element: 'inputRow',
                formatter: "dollar",
            },
            {
                description: 'Title Policy Guaranty Fee',
                premium: '$380.00',
                buyer: '$10.00',
                seller: '$70.00',
                inputs: true,
                element: 'inputRow',
                formatter: "dollar",
            },
            {
                description: 'Title Tax Certificate',
                premium: '$360.00',
                buyer: '$60.00',
                seller: '$20.00',
                inputs: true,
                element: 'inputRow',
                formatter: "dollar",
            },
            {
                description: 'Total for Title Service Fees (pre-tax)',
                premium: '$370.00',
                buyer: '$10.00',
                seller: '$50.00',
                inputs: false,
                element: 'infoRow',
                formatter: "dollar",
            },
            {
                description: 'Total Tax for Title Service Fees',
                premium: '$320.00',
                buyer: '$90.00',
                seller: '$40.00',
                inputs: false,
                element: 'infoRow',
                formatter: "dollar",
            },
            {
                description: 'Total for Title Service Fees (including tax)',
                premium: '$300.00',
                buyer: '$10.00',
                seller: '$80.00',
                inputs: false,
                element: 'infoRow',
                formatter: "dollar",
            },
        ],
      },
    recordingFees: {
        lender: [
            {
                description: 'Deed Recording Fee',
                premium: '$310.00',
                buyer: '$100.00',
                seller: '$20.00',
                inputs: true,
                element: 'inputRow',
                formatter: "dollar",
            },
            {
                description: 'Mortgage Recording Fee',
                premium: '$310.00',
                buyer: '$100.00',
                seller: '$20.00',
                inputs: false,
                element: 'infoRow',
                formatter: "dollar",
            },
            {
                description: 'Release Recording Fee',
                premium: '$380.00',
                buyer: '$10.00',
                seller: '$70.00',
                inputs: false,
                element: 'infoRow',
                formatter: "dollar",
            },
        ],
    },
};

export const MockDropdownOptions= [
    {label:'One', value: 'One'},
    {label:'Two', value: 'Two'},
    {label:'Three', value: 'Three'},
    {label:'Four', value: 'Four'}
]

export function fauxReducer(value: any, thing: any) {
    console.log('FAKE REDUCER', value, thing)
}
