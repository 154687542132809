import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Styled from './RadioButton.styles';
import RadioChecked from "../../../Assets/RadioChecked.svg";
import RadioUnchecked from "../../../Assets/RadioUnchecked.svg";
import Chevron from "../../../Assets/Chevron.svg";


// @ts-ignore
const RadioButton = ({
    // @ts-ignore

    label,
    // @ts-ignore

    value,
    // @ts-ignore

    update,
    // @ts-ignore

    type,
}) => {
    const [selected, setSelected] = useState(false);

    const toggle = () => {
        setSelected(!selected);
        update(selected);
    };

    return (
        <Styled.Container className={type}>
            <Styled.Radio
                src ={selected ? RadioChecked : RadioUnchecked}
                width={'24px'}
                height={'24px'}
                alt="radio"
                onClick={toggle}/>
            <Styled.Label>
                {label}
            </Styled.Label>
        </Styled.Container>
    );
};

RadioButton.propTypes = {
    label: PropTypes.string,
    value: PropTypes.any,
    update: PropTypes.func,
};

export default RadioButton;
