import React, { useState } from 'react';
import * as Styled from './Dropdown.styles';
import Chevron from "../../../Assets/Chevron.svg";
import PropTypes from "prop-types";

const Dropdown = ({
    reducer,
    options,
    type,
}) => {
    const [expanded, setExpanded] = useState(false);
    const [currentValue, setCurrentValue] = useState();
    const [currentLabel, setCurrentLabel] = useState(' ');

    const toggle = () => {
        setExpanded(!expanded);
    };
    const select = (value, label) => {
        setCurrentValue(value);
        setCurrentLabel(label);
        setExpanded(!expanded);
        reducer(value)
    };
    //TODO: ADD LOGIC FOR CLOSING DROPDOWN IF CLICK ON ELSEWHERE PAGE
    return (
        <Styled.Container>
        <Styled.Select
            open={expanded}
            className={type}
            >
            <Styled.SelectedInput
                open={expanded}
                onClick={toggle}
                value={currentLabel}
                readOnly
                className={type}
            >
            </Styled.SelectedInput>
            <Styled.Chevron src ={Chevron} expanded={!expanded} width={'20px'} height={'20px'} alt="chevron" />
        </Styled.Select >
        <Styled.Options
            open={expanded}
            className={type}
        >
            {options.map((option, idx) => (
                <Styled.Option
                    className={type}
                    key={idx}
                    onClick={()=>select(option.value,option.label)}>
                    {option.label}
                </Styled.Option>
            ))}
        </Styled.Options>
        </Styled.Container>
    );
};

Dropdown.propTypes = {
    reducer:PropTypes.func,
    options: PropTypes.array,
};

export default Dropdown;
