import styled from "@emotion/styled";
//TODO: replace with non MUI
import Grid from "@mui/material/Grid";
import colors from '../../Theme/colors';
import typography from "../../Theme/typography";

// GRID------------------------------------------------------------------
//TODO: replace with non MUI
export const GridBox = styled(Grid)`
  justify-content: center;
`;

// VERTICAL GRID------------------------------------------------------------------
export const SmallBorder = styled.div`
  border: solid 1px ${colors.border};
  border-radius: 4px;
  width: 257px;
  margin: 0 12px;
`;

export const SmallTableData = styled.td`
  width: 280px;
  ${typography.grid}
`;

export const VerticalTableBody = styled.tbody`
  font-size: 0.8rem;
  letter-spacing: 1px;
  border-style: hidden;
`;

export const VerticalTable = styled.table`
  border-collapse: collapse;
  margin-top:24px;
`;

export const VerticalHeaderLeft = styled.th`
  width:281px;
  text-align: right;
  ${typography.gridTitle}
  padding-right:24px;
`;

export const VerticalTotal = styled.td`
  width: 281px;
  text-align: left;
  padding: 0 12px;
  ${typography.grid}
`;

export const VerticalTableRow = styled.tr`
  height:42px;
`;

export const VerticalTableHeader = styled.thead`
`;

export const VerticalTableTotalTotal = styled.td`
  width: 280px;
  text-align: left;
  padding-left:12px;  
  ${typography.rowTitle}
`;

// TEXT ONLY------------------------------------------------------------------

export const Border = styled.div`
  border: solid 1px ${colors.border};
  border-radius: 4px;
  width: 434px;
  margin: 0 12px;
  &:hover {
    border: 2px solid ${colors.titleBlue};
    margin: -1px 12px;
  }
`;

export const Table = styled.table`
  border-collapse: collapse;
  margin-top:24px;
  padding: 0 24px;
`;

export const TableHeader = styled.thead`
  border: solid 1px ${colors.border};
`;

export const TableRow = styled.tr`
  border: solid 1px ${colors.border};
  height:42px;
`;

export const Headers = styled.tr`
  height:42px;
  ${typography.gridTitle}
`;

export const TableData = styled.td`
  width: 434px;
  ${typography.grid}
`;

export const TableDataStatic = styled.td`
  width: 449px;
  ${typography.grid}
  padding-left: 8px;
`;

export const TableTotal = styled.td`
  width: 290px;
  text-align: right;
  padding-right:16px;
  ${typography.grid}
`;

export const TableTotalRow = styled.td`
  width: 280px;
  text-align: right;
  padding-right:28px;  
  ${typography.rowTitle}
`;

export const TableTotalTotal = styled.td`
  width: 280px;
  text-align: right;
  padding-right:18px;  
  ${typography.rowTitle}
`;

export const HeaderRow = styled.th`
  border-style: hidden;
  text-align: right;
  padding-right:24px;
`;

export const HeaderRowStatic = styled.th`
  border-style: hidden;
  text-align: left;
  padding-left: 8px;
`;

export const HeaderLeft = styled.th`
  width:582px;
  text-align: right;
  ${typography.rowTitle}
  padding-right:24px;
`;

export const TableBody = styled.tbody`
  border: 1px solid ${colors.border};
  font-size: 0.8rem;
  letter-spacing: 1px;
  border-radius: 4px;
  border-style: hidden;
  box-shadow: 0 0 0 1px ${colors.border};
`;



