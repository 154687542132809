import { EncompassFieldIDs, StewartConstants } from "constants/strings";
import { PriceQuote, PriceQuoteFee } from "./priceQuotes";
import { getTitleFeeAmount, getTitleProps } from "./utils";

export function addTitleFees(priceQuote: PriceQuote, jsonData: any): void {
  const { ItemizedTitleServiceFee: titleFees, ownerTitleServiceChargeAmount } =
    jsonData;
  let totalAmount = 0,
    buyerAmount = 0,
    sellerAmount = 0,
    feeName = "",
    additionalTitleFeesCount = 0;

  for (const titleFeeNode of titleFees) {
    const {
      Name: feeName,
      Amount,
      SalesTax,
      BuyerAmount,
      SalesTaxBuyerAmount,
      SellerAmount,
      SalesTaxSellerAmount,
    } = getTitleProps(titleFeeNode);

    totalAmount = getTitleFeeAmount(Amount, SalesTax);
    buyerAmount = getTitleFeeAmount(BuyerAmount, SalesTaxBuyerAmount);
    sellerAmount = getTitleFeeAmount(SellerAmount, SalesTaxSellerAmount);

    if (buyerAmount === 0 && sellerAmount === 0) {
      buyerAmount = totalAmount;
    }

    const titleFee: PriceQuoteFee = {};
    titleFee.Payee = priceQuote.officeName;
    titleFee.BuyerAmount = buyerAmount;
    titleFee.SellerAmount = sellerAmount;

    if (feeName.includes(StewartConstants?.SettlementFeeDescription || "")) {
      titleFee.HudLineNumber = "1102";
      titleFee.Description = StewartConstants.SettlementFeeDescription;
      titleFee.EncompassFieldID_Payee = EncompassFieldIDs.HUDLine1102aPayee;
      titleFee.EncompassFieldID_BuyerAmount =
        EncompassFieldIDs.HUDLine1102aBuyerAmount;
      titleFee.EncompassFieldID_SellerAmount =
        EncompassFieldIDs.HUDLine1102aSellerAmount;
      titleFee.EncompassFieldID_BuyerCanShop =
        EncompassFieldIDs.HUDLine1102aBuyerCanShop;
      priceQuote.feeList.push(titleFee);
    }
    // ... (inside the addTitleFees function, continuing from the previous 'else if' block)
    else if (feeName.includes(StewartConstants?.ClosingFeeDescription || "")) {
      titleFee.HudLineNumber = "1102";
      titleFee.Description = StewartConstants?.ClosingFeeDescription || ""; // Provide a default empty string
      titleFee.EncompassFieldID_Payee = EncompassFieldIDs.HUDLine1102bPayee;
      titleFee.EncompassFieldID_BuyerAmount =
        EncompassFieldIDs.HUDLine1102bBuyerAmount;
      titleFee.EncompassFieldID_SellerAmount =
        EncompassFieldIDs.HUDLine1102bSellerAmount;
      titleFee.EncompassFieldID_BuyerCanShop =
        EncompassFieldIDs.HUDLine1102bBuyerCanShop;
      priceQuote.feeList.push(titleFee);
    } else if (feeName.includes(StewartConstants?.EscrowFeeDescription || "")) {
      titleFee.HudLineNumber = "1102";
      titleFee.Description = StewartConstants?.EscrowFeeDescription || ""; // Provide a default empty string
      titleFee.EncompassFieldID_Payee = EncompassFieldIDs.HUDLine1102cPayee;
      titleFee.EncompassFieldID_BuyerAmount =
        EncompassFieldIDs.HUDLine1102cBuyerAmount;
      titleFee.EncompassFieldID_SellerAmount =
        EncompassFieldIDs.HUDLine1102cSellerAmount;
      titleFee.EncompassFieldID_BuyerCanShop =
        EncompassFieldIDs.HUDLine1102cBuyerCanShop;
      priceQuote.feeList.push(titleFee);
    } else if (
      feeName.includes(
        StewartConstants?.CAAffordableHousingFeeDescription || ""
      )
    ) {
      titleFee.HudLineNumber = "1208";
      titleFee.Description = feeName;
      titleFee.EncompassFieldID_Description =
        EncompassFieldIDs.HUDLine1208Description;
      titleFee.EncompassFieldID_Payee = EncompassFieldIDs.HUDLine1208Payee;
      titleFee.EncompassFieldID_BuyerAmount =
        EncompassFieldIDs.HUDLine1208BuyerAmount;
      titleFee.EncompassFieldID_SellerAmount =
        EncompassFieldIDs.HUDLine1208SellerAmount;
      priceQuote.feeList.push(titleFee);
    } else {
      additionalTitleFeesCount++;

      if (additionalTitleFeesCount < 12) {
        if (additionalTitleFeesCount === 6) {
          if (ownerTitleServiceChargeAmount !== 0) {
            additionalTitleFeesCount++;
          }
        }

        titleFee.Description = feeName;

        const fieldIdMapping: { [key: number]: { [key: string]: string } } = {
          1: {
            HudLineNumber: "1102",
            EncompassFieldID_Description:
              EncompassFieldIDs.HUDLine1102dDescription,
            EncompassFieldID_Payee: EncompassFieldIDs.HUDLine1102dPayee,
            EncompassFieldID_BuyerAmount:
              EncompassFieldIDs.HUDLine1102dBuyerAmount,
            EncompassFieldID_SellerAmount:
              EncompassFieldIDs.HUDLine1102dSellerAmount,
            EncompassFieldID_BuyerCanShop:
              EncompassFieldIDs.HUDLine1102dBuyerCanShop,
          },
          2: {
            HudLineNumber: "1102",
            EncompassFieldID_Description:
              EncompassFieldIDs.HUDLine1102eDescription,
            EncompassFieldID_Payee: EncompassFieldIDs.HUDLine1102ePayee,
            EncompassFieldID_BuyerAmount:
              EncompassFieldIDs.HUDLine1102eBuyerAmount,
            EncompassFieldID_SellerAmount:
              EncompassFieldIDs.HUDLine1102eSellerAmount,
            EncompassFieldID_BuyerCanShop:
              EncompassFieldIDs.HUDLine1102eBuyerCanShop,
          },
          3: {
            HudLineNumber: "1102",
            EncompassFieldID_Description:
              EncompassFieldIDs.HUDLine1102fDescription,
            EncompassFieldID_Payee: EncompassFieldIDs.HUDLine1102fPayee,
            EncompassFieldID_BuyerAmount:
              EncompassFieldIDs.HUDLine1102fBuyerAmount,
            EncompassFieldID_SellerAmount:
              EncompassFieldIDs.HUDLine1102fSellerAmount,
            EncompassFieldID_BuyerCanShop:
              EncompassFieldIDs.HUDLine1102fBuyerCanShop,
          },
          4: {
            HudLineNumber: "1102",
            EncompassFieldID_Description:
              EncompassFieldIDs.HUDLine1102gDescription,
            EncompassFieldID_Payee: EncompassFieldIDs.HUDLine1102gPayee,
            EncompassFieldID_BuyerAmount:
              EncompassFieldIDs.HUDLine1102gBuyerAmount,
            EncompassFieldID_SellerAmount:
              EncompassFieldIDs.HUDLine1102gSellerAmount,
            EncompassFieldID_BuyerCanShop:
              EncompassFieldIDs.HUDLine1102gBuyerCanShop,
          },
          5: {
            HudLineNumber: "1102",
            EncompassFieldID_Description:
              EncompassFieldIDs.HUDLine1102hDescription,
            EncompassFieldID_Payee: EncompassFieldIDs.HUDLine1102hPayee,
            EncompassFieldID_BuyerAmount:
              EncompassFieldIDs.HUDLine1102hBuyerAmount,
            EncompassFieldID_SellerAmount:
              EncompassFieldIDs.HUDLine1102hSellerAmount,
            EncompassFieldID_BuyerCanShop:
              EncompassFieldIDs.HUDLine1102hBuyerCanShop,
          },
          6: {
            HudLineNumber: "1101",
            EncompassFieldID_Description:
              EncompassFieldIDs.HUDLine1101aDescription,
            EncompassFieldID_Payee: EncompassFieldIDs.HUDLine1101aPayee,
            EncompassFieldID_BuyerAmount:
              EncompassFieldIDs.HUDLine1101aBuyerAmount,
            EncompassFieldID_SellerAmount:
              EncompassFieldIDs.HUDLine1101aSellerAmount,
            EncompassFieldID_BuyerCanShop:
              EncompassFieldIDs.HUDLine1101aBuyerCanShop,
          },
          7: {
            HudLineNumber: "1101",
            EncompassFieldID_Description:
              EncompassFieldIDs.HUDLine1101bDescription,
            EncompassFieldID_Payee: EncompassFieldIDs.HUDLine1101bPayee,
            EncompassFieldID_BuyerAmount:
              EncompassFieldIDs.HUDLine1101bBuyerAmount,
            EncompassFieldID_SellerAmount:
              EncompassFieldIDs.HUDLine1101bSellerAmount,
            EncompassFieldID_BuyerCanShop:
              EncompassFieldIDs.HUDLine1101bBuyerCanShop,
          },
          8: {
            HudLineNumber: "1101",
            EncompassFieldID_Description:
              EncompassFieldIDs.HUDLine1101cDescription,
            EncompassFieldID_Payee: EncompassFieldIDs.HUDLine1101cPayee,
            EncompassFieldID_BuyerAmount:
              EncompassFieldIDs.HUDLine1101cBuyerAmount,
            EncompassFieldID_SellerAmount:
              EncompassFieldIDs.HUDLine1101cSellerAmount,
            EncompassFieldID_BuyerCanShop:
              EncompassFieldIDs.HUDLine1101cBuyerCanShop,
          },
          9: {
            HudLineNumber: "1101",
            EncompassFieldID_Description:
              EncompassFieldIDs.HUDLine1101dDescription,
            EncompassFieldID_Payee: EncompassFieldIDs.HUDLine1101dPayee,
            EncompassFieldID_BuyerAmount:
              EncompassFieldIDs.HUDLine1101dBuyerAmount,
            EncompassFieldID_SellerAmount:
              EncompassFieldIDs.HUDLine1101dSellerAmount,
            EncompassFieldID_BuyerCanShop:
              EncompassFieldIDs.HUDLine1101dBuyerCanShop,
          },
          10: {
            HudLineNumber: "1101",
            EncompassFieldID_Description:
              EncompassFieldIDs.HUDLine1101eDescription,
            EncompassFieldID_Payee: EncompassFieldIDs.HUDLine1101ePayee,
            EncompassFieldID_BuyerAmount:
              EncompassFieldIDs.HUDLine1101eBuyerAmount,
            EncompassFieldID_SellerAmount:
              EncompassFieldIDs.HUDLine1101eSellerAmount,
            EncompassFieldID_BuyerCanShop:
              EncompassFieldIDs.HUDLine1101eBuyerCanShop,
          },
          11: {
            HudLineNumber: "1101",
            EncompassFieldID_Description:
              EncompassFieldIDs.HUDLine1101fDescription,
            EncompassFieldID_Payee: EncompassFieldIDs.HUDLine1101fPayee,
            EncompassFieldID_BuyerAmount:
              EncompassFieldIDs.HUDLine1101fBuyerAmount,
            EncompassFieldID_SellerAmount:
              EncompassFieldIDs.HUDLine1101fSellerAmount,
            EncompassFieldID_BuyerCanShop:
              EncompassFieldIDs.HUDLine1101fBuyerCanShop,
          },
        };

        // Assign properties based on additionalTitleFeesCount
        if (additionalTitleFeesCount in fieldIdMapping) {
          const fieldIds = fieldIdMapping[additionalTitleFeesCount];
          Object.assign(titleFee, fieldIds);
        } else {
          // Handle the case where additionalTitleFeesCount is out of range
          console.error("Exceeded maximum additional title fees limit!");
          // Or throw an error if you prefer
        }

        priceQuote.feeList.push(titleFee);
      }
    }
  }
}
