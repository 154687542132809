import styled from '@emotion/styled'
import typography from '../../Theme/typography';
import colors from '../../Theme/colors';

//REPLACE
import Box from '@mui/material/Box';

export const PageBox = styled(Box)`
  //font-family: 'Roboto',sans-serif;
  width: 100%;
  padding: 0 24px;
`;

export const SectionBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 200px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 24px 0;
  max-width:1807px;
`;

export const Grid = styled(Box)`
  width: 100%;
`;

export const Header = styled(Box)`
  width: 100%;
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  //position: sticky;
  height: 95px;
  top:0;
  background-color: ${colors.background};
`;

export const SectionTitle = styled('div')`
${typography.sectionTitle}
  padding-top: 24px;
  padding-bottom: 24px;
  height: 30px;
  display: flex;
  justify-content:space-between;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
${typography.sectionTitle}
  padding-top: 24px;
  padding-bottom: 24px;
  height: 72px;
`;

export const Spacer = styled.div`
    width:  ${(props) => (props.width ? props.width : `100%`)};
    height: 100%;
`;
