import BaseComponent from "../../Assets/BaseComponent";
import * as Styled from "./component.styles";
import { DummyData } from "../config";
import { transactionType } from "../config";
import Input from "../Components/Input/Input";

class Grid extends BaseComponent {
  constructor(props: {}) {
    super(props);
    this.state = {};
  }

  //TODO: PLUG IN HEADERS
  //TODO: REPLACE WITH INPUTS
  //TODO: TRASH MUI
  render() {
    const endorsementsLender = DummyData.endorsements.lender;
    const endorsementsOwner = DummyData.endorsements.owner;
    // let titleServices = DummyData.titleServices.lender;
    // let recordingFees = DummyData.recordingFees.lender;
    let gridConfig =
      this.props.section.type === "TitleGrid"
        ? DummyData.titleServices.lender
        : DummyData.recordingFees.lender;
    // @ts-ignore
    //TODO: REPLACE WITH SWITCH FOR ADDITIONAL TYPES
    const refiSwitch = "Purchase" === transactionType;
    return (
      <Styled.GridBox>
        {this.props.section.type === "EndorsementGrid" ? (
          <>
            <Styled.Table>
              <Styled.TableHeader>
                <Styled.Headers>
                  <Styled.HeaderRow>Lender's Endorsement</Styled.HeaderRow>
                  {refiSwitch ? (
                    <>
                      <Styled.HeaderRow>Buyer Amount</Styled.HeaderRow>
                      <Styled.HeaderRow>Seller Amount</Styled.HeaderRow>
                    </>
                  ) : null}
                  <Styled.HeaderRow>Total</Styled.HeaderRow>
                </Styled.Headers>
              </Styled.TableHeader>
              <Styled.TableBody>
                {endorsementsLender.map((item, idx) =>
                  item.inputs ? (
                    <Styled.TableRow key={idx}>
                      <Styled.HeaderLeft>{item.description}</Styled.HeaderLeft>
                      {refiSwitch ? (
                        <>
                          <Styled.TableData>
                            <Input type="primary" defaultValue={item.buyer} />
                          </Styled.TableData>
                          <Styled.TableData>
                            <Input type="primary" defaultValue={item.seller} />
                          </Styled.TableData>
                        </>
                      ) : null}
                      <Styled.TableTotal>{item.premium}</Styled.TableTotal>
                    </Styled.TableRow>
                  ) : (
                    <Styled.TableRow>
                      <Styled.HeaderLeft>{item.description}</Styled.HeaderLeft>
                      {refiSwitch ? (
                        <>
                          <Styled.TableTotalRow>
                            {item.buyer}
                          </Styled.TableTotalRow>
                          <Styled.TableTotalRow>
                            {item.seller}
                          </Styled.TableTotalRow>
                        </>
                      ) : null}
                      <Styled.TableTotalTotal>
                        {item.premium}
                      </Styled.TableTotalTotal>
                    </Styled.TableRow>
                  )
                )}
              </Styled.TableBody>
            </Styled.Table>
            <Styled.Table>
              <Styled.TableHeader>
                <Styled.Headers>
                  <Styled.HeaderRow>Owner's Endorsements</Styled.HeaderRow>
                  {refiSwitch ? (
                    <>
                      <Styled.HeaderRow>Buyer Amount</Styled.HeaderRow>
                      <Styled.HeaderRow>Seller Amount</Styled.HeaderRow>
                    </>
                  ) : null}
                  <Styled.HeaderRow>Total</Styled.HeaderRow>
                </Styled.Headers>
              </Styled.TableHeader>
              <Styled.TableBody>
                {endorsementsOwner.map((item, idx) =>
                  item.inputs ? (
                    <Styled.TableRow key={idx}>
                      <Styled.HeaderLeft>{item.description}</Styled.HeaderLeft>
                      {refiSwitch ? (
                        <>
                          <Styled.TableData>
                            <Input type="primary" defaultValue={item.buyer} />
                          </Styled.TableData>
                          <Styled.TableData>
                            <Input type="primary" defaultValue={item.seller} />
                          </Styled.TableData>
                        </>
                      ) : null}
                      <Styled.TableTotal>{item.premium}</Styled.TableTotal>
                    </Styled.TableRow>
                  ) : (
                    <Styled.TableRow>
                      <Styled.HeaderLeft>{item.description}</Styled.HeaderLeft>
                      {refiSwitch ? (
                        <>
                          <Styled.TableTotalRow>
                            {item.buyer}
                          </Styled.TableTotalRow>
                          <Styled.TableTotalRow>
                            {item.seller}
                          </Styled.TableTotalRow>
                        </>
                      ) : null}
                      <Styled.TableTotalTotal>
                        {item.premium}
                      </Styled.TableTotalTotal>
                    </Styled.TableRow>
                  )
                )}
              </Styled.TableBody>
            </Styled.Table>
          </>
        ) : (
          //TRANSACTION DETAILS
          <>
            <Styled.Table>
              <Styled.TableHeader>
                <Styled.Headers>
                  <Styled.HeaderRow></Styled.HeaderRow>
                  {refiSwitch ? (
                    <>
                      <Styled.HeaderRow>Buyer Amount</Styled.HeaderRow>
                      <Styled.HeaderRow>Seller Amount</Styled.HeaderRow>
                    </>
                  ) : null}
                  <Styled.HeaderRow>Total</Styled.HeaderRow>
                </Styled.Headers>
              </Styled.TableHeader>
              <Styled.TableBody>
                {gridConfig.map((item, idx) =>
                  item.inputs ? (
                    <Styled.TableRow key={idx}>
                      <Styled.HeaderLeft>{item.description}</Styled.HeaderLeft>
                      {refiSwitch ? (
                        <>
                          <Styled.TableData>
                            <Input
                              type="primary"
                              reducer={this.props.reducer}
                              formatter={item.formatter}
                              value="33343.3"
                            />
                          </Styled.TableData>
                          <Styled.TableData>
                            <Input type="primary" defaultValue={item.seller} />
                          </Styled.TableData>
                        </>
                      ) : null}
                      <Styled.TableTotal>{item.premium}</Styled.TableTotal>
                    </Styled.TableRow>
                  ) : (
                    <Styled.TableRow>
                      <Styled.HeaderLeft>{item.description}</Styled.HeaderLeft>
                      {refiSwitch ? (
                        <>
                          <Styled.TableTotalRow>
                            {item.buyer}
                          </Styled.TableTotalRow>
                          <Styled.TableTotalRow>
                            {item.seller}
                          </Styled.TableTotalRow>
                        </>
                      ) : null}
                      <Styled.TableTotalTotal>
                        {item.premium}
                      </Styled.TableTotalTotal>
                    </Styled.TableRow>
                  )
                )}
              </Styled.TableBody>
            </Styled.Table>
          </>
        )}
      </Styled.GridBox>
    );
  }
}

export default Grid;
