import { OptionsData } from "interfaces/rootState";
import { TransactionRequest } from "interfaces/stringsConstantstype";
import host from "@elliemae/em-ssf-guest";
import store from "redux/store";

import { escrowContacts, titleContacts } from "constants/strings";
import { toArray } from "utils/placeOrder";
import { logException } from "componnets/atoms/Logger";

type Contact = {
  [key: string]: any; // Allows any property name with any type of value
};

type Loan = {
  closingCost: {
    escrowCompanyName?: string;
    titleCompanyName?: string;
  };
  contacts?: Contact[] | undefined;
  closingDocument?: Record<string, string>;
  property?: Record<string, string>;
};

type LoanObject = {
  status: string;
  referenceNumber: string | number;
  loan: Loan;
  loanFormat: string;
  resources?: any[];
  partnerStatus?: string;
};

export function parseContactPath(path: string): Record<string, string> {
  const regex = /\$\.(.+)\[\?\(@\.(.+) == '(.+)'\)\]\.(.+)/;
  const match = path.match(regex);

  if (!match) {
    return {};
  }

  const [, arrayName, propertyToCheck, valueToMatch, propertyToUpdate] = match;

  return { arrayName, propertyToCheck, valueToMatch, propertyToUpdate };
}

const contactsObj: any = {
  ESCROW_COMPANY: escrowContacts,
  TITLE_COMPANY: titleContacts,
};

function ensureContactTypes(contactsArr: Contact[]): Contact[] {
  const lookuptable = ["ESCROW_COMPANY", "TITLE_COMPANY"];
  const missingTypes = lookuptable.filter(
    (type) => !contactsArr.some((contact) => contact.contactType === type)
  );

  const newContacts = missingTypes.map((type) => contactsObj[type]);
  return [...contactsArr, ...newContacts];
}

function createTransactionRequest(
  options: OptionsData,
  type?: string
): TransactionRequest {
  return {
    request: {
      ...(type && {
        type: type,
      }),
      options,
    },
  };
}

export async function updateEPCTransaction(
  options: OptionsData,
  orderType?: string
): Promise<{ success: boolean; id?: string; error?: Error } | undefined> {
  try {
    const actualOrderType = orderType ?? "New Order"; // Set default value if orderType is undefined
    const transactionRequest = createTransactionRequest(
      options,
      actualOrderType
    );

    const transactionObject = await host.getObject("transaction");
    const transactionData = await transactionObject.update(transactionRequest);
    return transactionData;
  } catch (error) {
    console.error("Error updating transaction:", error);
    logException("Err at updateEPCTransaction [saveOrderUpdates]", {
      error: error || {},
      message: "Err at updateEPCTransaction [saveOrderUpdates]",
    });
    return undefined;
  }
}

export async function createEPCTransaction(
  options: OptionsData,
  orderType = "New Order"
): Promise<{ success: boolean; id?: string; error?: Error } | undefined> {
  try {
    // Create the transaction request object
    const transactionRequest = createTransactionRequest(options, orderType);

    // Assuming host.getObject and transactionObject.update are asynchronous operations
    const transactionObject = await host.getObject("transaction");
    const transactionData = await transactionObject.create(transactionRequest);
    return transactionData;
  } catch (error) {
    console.error("Error creating transaction:", error);
    logException("Err at createEPCTransaction [saveOrderUpdates]", {
      error: error || {},
      message: "Err at createEPCTransaction [saveOrderUpdates]",
    });
    return undefined;
  }
}
