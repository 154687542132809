import styled from '@emotion/styled'
import css from '@emotion/styled'
import typography from '../../../Theme/typography';
import colors from '../../../Theme/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  user-select: none;
`;

export const Label = styled.div`
  ${typography.radio};
`;
export const Check = styled.img`
  margin: 6px;
`;
