import React, { useState } from 'react';
import PropTypes from 'prop-types';
import * as Styled from './Button.styles';
import BackArrow from "../../../Assets/BackArrow.svg";

const Button = ({ ...props }) => {
    const {
        onClick,
        type,
        label,
        icon,
   } = props;
    const [expanded, setExpanded] = useState(false);

        return (
            <Styled.Button
                className={type}
                type={type}
                onClick={onClick}
            >
                {icon ?
                    <Styled.Icon
                        src ={BackArrow}
                        width={'24px'}
                        height={'24px'}
                        alt="check"/>
                    : null }
                {label}
            </Styled.Button>
        );
    }


Button.propTypes = {
    onClick:PropTypes.func,
    type:PropTypes.string,
};

export default Button;
