import React from 'react';
import PropTypes from 'prop-types';
import BaseComponent from "../../../Assets/BaseComponent";
// import BlurFormat from './blurFormat';
// import Validate from './validate';
import * as Styled from './Input.styles';
import Format from "./format";

class Input extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value || '',
            dropdownOpen: false,
            previousValue: props.value || '',
        };

        this.handleChange = this.handleChange.bind(this);

        // pristine means nothing has been input yet
        this.state.pristine = ! this.state.value;

        // refs will be assigned to elements and can provide measurements
        this.outlineRef = React.createRef();
        this.inputRef = React.createRef();
        this.chevronRef = React.createRef();

        // format input string before state change
        this.formatter = props.formatter;

        if (typeof this.formatter === 'string') this.formatter = Format[this.formatter];
        if (! this.formatter || ! (this.formatter instanceof Function)) this.formatter = (str, prevStr, cursorStart, prevCursorStart) => str;
        let formatted = this.state.value;
        if (this.formatter) {
            formatted = this.formatter(
                formatted,
                this.state.value,
                this.state.cursorPosition,
                this.state.cursorPosition
            );
            if (Array.isArray(formatted)) formatted = formatted[0];
        }
        if (this.props.store?.type ==='currency' && formatted !== '') {
            const noCommas = formatted.toString().replace(/,/g, '');
            let newNum = parseFloat(noCommas);
            formatted = new Intl.NumberFormat('en-US', {style: 'decimal', minimumFractionDigits: 2}).format(newNum);
        }
        if (formatted) this.state.value = formatted;

        // blur formatter
        this.blurFormatter = props.blurFormatter;
        if (typeof this.blurFormatter === 'string')
            this.blurFormatter = BlurFormat[this.blurFormatter];

        // validate input string now and on blur
        this.validations = props.validations;
        if (typeof this.validations === 'string') this.validations = Validate[this.validations];
        else if (! Array.isArray(this.validations)) this.validations = []; // array contains validations objects with regex like {validPattern, errorMessage} OR {invalidPattern, errorMessage} OR {validFunction, errorMessage}

        if (this.state.value && this.validations.length) {
            const validation = Validate.run(this.validations, this.state.value);
            if (validation.invalid) {
                this.state.invalid = true;
                this.state.errorMessage = validation.errorMessage;
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value !== this.props.value) {
            let formatted = this.formatter(this.props.value || '', '', this.state.cursorPosition, this.state.cursorPosition);
            if (this.props.store?.type ==='currency' && formatted !== '') {
                let noCommas = formatted.toString().replace(/,/g, '');
                let newNum = parseFloat(noCommas);
                formatted = new Intl.NumberFormat('en-US', {style: 'decimal', minimumFractionDigits: 2}).format(newNum);
            }
            if (this.props.store?.type ==='date' && formatted !== '') {
                formatted = formatted[0];
            }
            this.setState({value: formatted});
        }
    }

    handleChange = (event, options) => {
        const { updateParent } = this.props;
        options = options || {};
        let value = '', cursorStart = 0;

        // capture value and cursorStart
        if (!event && options && typeof options.value !== 'undefined') {                                                   // setting value via explicit call to handleChange (event is null)
            value = options.value;
            if (options.cursorStart) cursorStart = options.cursorStart;
            else cursorStart = this.state.cursorPosition;
        }
        else {
            value = event.target.value;
            cursorStart = event.target.selectionStart;
        }

        //process with input's formatter
        const empty = typeof value === 'string' && value.length === 0;
        let formatted = null;
        if (! empty) {
            formatted = this.formatter(
                value,
                this.state.value,
                cursorStart,
                this.state.cursorPosition
            );
            value = formatted;
        }

        // did formatter want to control cursor position?
        let cursorPosition = null;
        if (Array.isArray(formatted)) {
            value = formatted[0];
            cursorPosition = formatted[1];
        }
        else
            cursorPosition = cursorStart;
        //value = formatCurrency(value);

        //value = Format.dollar(value);
        const update = {
            ...(options.isInputNodeReusedAndEmpty && { pristine: true }),
            ...(options.isInputNodeReusedAndPreviouslyEmpty && { pristine: false }),
            value,
            cursorPosition,
        };

        this.setState(update, () => {
            try {
                // Actually sets cursor position here. Note: .setSelectionRange exists but breaks if (this.props.type === 'email') thanks html.
                this.inputRef.current.setSelectionRange(this.state.cursorPosition, this.state.cursorPosition);
            } catch (e) { }
            if (updateParent && !options.updateCameFromParent) updateParent(value);
        });
        // this.props.reducer(value);
    };

    handleBlur = () => {
        const { closingForm, modal } = this.props;
            const {value, previousValue} = this.state;
            const {reducer
                // , store, addId, section
            } = this.props;
            // const {lineId, key} = store;
        console.log('HANDLE BLUR IN INPUT', value)


       reducer(value)
       // reducer(value, store, addId, section);

        // const isSpecificField = lineId === '1001' && key === 'poc';
        //     //  Code for compare current value and previous value without format
        //     const valueWithOutFormat = value.toString().includes(",") ? value.replace(/,/g, '') : value;
        //     const previousValueWithOutFormat = previousValue.toString().includes(",") ? previousValue.replace(/,/g, '') : previousValue;
        //     const numericValue = parseFloat(valueWithOutFormat);
        //     const numericPreviousValue = parseFloat(previousValueWithOutFormat);
        //     if (isSpecificField && numericValue !== numericPreviousValue) {
        //         this.setState({previousValue: value});
        //         reducer(value, store, addId, section);
        //     } else if (!isSpecificField) {
        //         reducer(value, store, addId, section);
        //     }
    };


    render() {

        const { prefix, suffix, inset, bubble, justify, background, width,
            height, type, dropdown, placeholder, bottomLeft, bottomRight,
            topRight, topLeft, reducer, invisibleLeft, invisibleRight,
            invisibleBottom, invisibleTop, disabled,  hidden, id, addId, addedId, section, closingStore } = this.props;
        let noInput = this.props.readOnly;
        const { value, dropdownOpen } = this.state;
        // const expand = <SvgSimple icon={CHEVRON_UP} size={'20px'} />;
        // const retract = <SvgSimple icon={CHEVRON_DOWN} size={'20px'} />;
        // const bool = section ? id <= addedId[section] : null;
        // const displayPrefix = (this.props?.store?.key === 'poc' && value !== '') ? <Styled.Prefix>{prefix}</Styled.Prefix> :
        //     (this.props?.store?.key === 'poc' && value === '') ? null : prefix ? <Styled.Prefix>{prefix}</Styled.Prefix> : null;
        // const line =  this.props?.store?.lineId;
        // const key =  this.props?.store?.key;
        // const linkedArray = Object.keys(linkedObjOne);
        // const linkedArrayTwo = Object.keys(linkedObjTwo);
        //
        // if (linkedArray.includes(line) && key === 'gfeAmount') {
        //     const linked = closingStore?.[linkedObjOne[line]]?.linkTo;
        //     if (linked) {noInput = true}
        //     else  if (!linked) {noInput = false}
        // }
        // if (linkedArrayTwo.includes(line) && key === 'gfeAmount') {
        //     const linked = closingStore?.[line]?.linkTo;
        //     if (linked) {noInput = false}
        //     else  if (!linked) {noInput = true}
        // }

        return (

            <Styled.Border
                className={type}
                inset={inset}
                width={width}
                id={id}
                hidden={hidden ? !bool : false}>
                 <Styled.Input
                        value={value}
                        justify={justify}
                        onChange={this.handleChange}
                      //  onBlur={this.handleBlur}
                        className={type}
                        placeholder={placeholder}
                        readOnly={noInput}
                    />
            </Styled.Border>
        );
    }
}

Input.propTypes = {
    // prefix: PropTypes.string,
    // suffix: PropTypes.string,
    // justify: PropTypes.string,
    // value: PropTypes.any,
    // dropdown: PropTypes.array,
    // type: PropTypes.string,
    // formatter: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    // placeholder: PropTypes.string,
    // reducer: PropTypes.func,
    // disabled: PropTypes.bool,
    // readOnly: PropTypes.bool,

};

export default Input;
