import styled from '@emotion/styled'

export const Container = styled.div``;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap:  16px;
  margin-bottom: 4px;
  &.verticalThreeRow {
    flex-direction: column;
    min-height: 68px;
    width: 659px;
  }
`;

export const Label = styled.div`
  font-size: 16px;
  font-weight: 600;
  height: 20px;
  margin-bottom: 8px;
`;
