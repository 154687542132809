import { EncompassFieldIDs, StewartConstants } from "constants/strings";
import { addTaxFee } from "./addTaxFee";
import { PriceQuote } from "./priceQuotes";

export const addTransferTaxes = (
  priceQuote: PriceQuote,
  jsonData: any
): void => {
  addTaxFee({
    priceQuote,
    taxType: "DeedTax",
    jurisdiction: "State",
    QuoteResponse: jsonData,
    hudLineNumber: StewartConstants.StateTransferTaxesLineNumber,
    description: StewartConstants.StateTransferTaxesDeedDescription,
    encompassFieldID_Amount: EncompassFieldIDs.HUDLine1205DeedAmount,
  });
  addTaxFee({
    priceQuote,
    QuoteResponse: jsonData,
    taxType: "MortgageTax",
    jurisdiction: "State",
    hudLineNumber: StewartConstants.StateTransferTaxesLineNumber,
    description: StewartConstants.StateTransferTaxesMortgageDescription,
    encompassFieldID_Amount: EncompassFieldIDs.HUDLine1205MortgageAmount,
  });

  const deedCountyTaxesAmount: number = addTaxFee({
    priceQuote,
    taxType: "DeedTax",
    jurisdiction: "County",
    QuoteResponse: jsonData,
    hudLineNumber: StewartConstants.CityCountyTransferTaxesLineNumber,
    description: StewartConstants.CountyTransferTaxesDeedDescription,
    encompassFieldID_Amount: EncompassFieldIDs.HUDLine1204DeedAmount,
  });

  const mortgageCountyTaxAmount: number = addTaxFee({
    priceQuote,
    taxType: "MortgageTax",
    QuoteResponse: jsonData,

    jurisdiction: "County",
    hudLineNumber: StewartConstants.CityCountyTransferTaxesLineNumber,
    description: StewartConstants.CountyTransferTaxesMortgageDescription,
    encompassFieldID_Amount: EncompassFieldIDs.HUDLine1204MortgageAmount,
  });
  let cityTaxLineNumber: string =
    StewartConstants.CityCountyTransferTaxesLineNumber;
  let cityTaxEncompassFieldID_Deed: string =
    EncompassFieldIDs.HUDLine1204DeedAmount;
  let cityTaxEncompassFieldID_Mortgage: string =
    EncompassFieldIDs.HUDLine1204MortgageAmount;

  if (deedCountyTaxesAmount !== 0 || mortgageCountyTaxAmount !== 0) {
    cityTaxLineNumber = StewartConstants.TransferTaxesLineNumber;
    cityTaxEncompassFieldID_Deed = "";
    cityTaxEncompassFieldID_Mortgage = "";
  }

  addTaxFee({
    priceQuote,
    taxType: "DeedTax",
    jurisdiction: "City",
    QuoteResponse: jsonData,
    hudLineNumber: cityTaxLineNumber,
    description: StewartConstants.CityTransferTaxesDeedDescription,
    encompassFieldID_Amount: cityTaxEncompassFieldID_Deed,
  });

  addTaxFee({
    priceQuote,
    taxType: "MortgageTax",
    jurisdiction: "City",
    QuoteResponse: jsonData,
    hudLineNumber: cityTaxLineNumber,
    description: StewartConstants.CityTransferTaxesMortgageDescription,
    encompassFieldID_Amount: cityTaxEncompassFieldID_Mortgage,
  });

  //FL $ GA Intangible Tax to Line#1203
  addTaxFee({
    priceQuote,
    taxType: "MortgageIntangibleTax",
    QuoteResponse: jsonData,
    jurisdiction: "State",
    hudLineNumber: StewartConstants.TransferTaxesLineNumber,
    description: StewartConstants.FLAdditionalTransferTaxesMortgageDescription,
    encompassFieldID_Amount: "", // string.Empty equivalent in TypeScript
    encompassFieldID_BuyerAmount: EncompassFieldIDs.HUDLine1203BuyerAmount,
    encompassFieldID_SellerAmount: EncompassFieldIDs.HUDLine1203SellerAmount,
    encompassFieldID_Description: EncompassFieldIDs.HUDLine1203Description,
    encompassFieldID_Payee: "",
  });

  //Minnesota - Mortgage Conservation Fund

  addTaxFee({
    QuoteResponse: jsonData,
    priceQuote,
    taxType: "MortgageConservationFund",
    jurisdiction: "State",
    hudLineNumber: StewartConstants.AdditionalTransferTaxesLineNumber,
    description: StewartConstants.MNAdditionalTransferTaxesMortgageDescription,
    encompassFieldID_Amount: "", // string.Empty equivalent in TypeScript
    encompassFieldID_BuyerAmount: EncompassFieldIDs.HUDLine1206BuyerAmount,
    encompassFieldID_SellerAmount: EncompassFieldIDs.HUDLine1206SellerAmount,
    encompassFieldID_Description: EncompassFieldIDs.HUDLine1206Description,
    encompassFieldID_Payee: EncompassFieldIDs.HUDLine1206Payee,
  });
  //Minnesota - Deed Conservation Fund
  addTaxFee({
    QuoteResponse: jsonData,
    priceQuote,
    taxType: "DeedConservationFund",
    jurisdiction: "State",
    hudLineNumber: StewartConstants.AdditionalTransferTaxesSecondLineNumber,
    description: StewartConstants.MNAdditionalTransferTaxesDeedDescription,
    encompassFieldID_Amount: "", // string.Empty equivalent in TypeScript
    encompassFieldID_BuyerAmount: EncompassFieldIDs.HUDLine1207BuyerAmount,
    encompassFieldID_SellerAmount: EncompassFieldIDs.HUDLine1207SellerAmount,
    encompassFieldID_Description: EncompassFieldIDs.HUDLine1207Description,
    encompassFieldID_Payee: EncompassFieldIDs.HUDLine1207Payee,
  });

  // Maryland - Mortgage Recordation
  addTaxFee({
    priceQuote,
    QuoteResponse: jsonData,
    taxType: "MortgageRecordationTax",
    jurisdiction: "State",
    hudLineNumber: StewartConstants.AdditionalTransferTaxesLineNumber, // Assuming this is the correct line number for Maryland
    description: StewartConstants.MDAdditionalTransferTaxesMortgageDescription,
    encompassFieldID_Amount: "", // string.Empty equivalent in TypeScript
    encompassFieldID_BuyerAmount: EncompassFieldIDs.HUDLine1206BuyerAmount, // Adjust if needed based on your EncompassFieldIDs
    encompassFieldID_SellerAmount: EncompassFieldIDs.HUDLine1206SellerAmount,
    encompassFieldID_Description: EncompassFieldIDs.HUDLine1206Description,
    encompassFieldID_Payee: EncompassFieldIDs.HUDLine1206Payee,
  });

  // Maryland - Deed Recordation
  addTaxFee({
    QuoteResponse: jsonData,
    priceQuote,
    taxType: "DeedRecordationTax",
    jurisdiction: "State",
    hudLineNumber: StewartConstants.AdditionalTransferTaxesSecondLineNumber, // Assuming this is the correct line number for Maryland
    description: StewartConstants.MDAdditionalTransferTaxesDeedDescription,
    encompassFieldID_Amount: "",
    encompassFieldID_BuyerAmount: EncompassFieldIDs.HUDLine1207BuyerAmount, // Adjust if needed
    encompassFieldID_SellerAmount: EncompassFieldIDs.HUDLine1207SellerAmount,
    encompassFieldID_Description: EncompassFieldIDs.HUDLine1207Description,
    encompassFieldID_Payee: EncompassFieldIDs.HUDLine1207Payee,
  });

  // Virginia - Grantor Fees
  addTaxFee({
    priceQuote,
    QuoteResponse: jsonData,

    taxType: "GrantorTax",
    jurisdiction: "City",
    hudLineNumber: StewartConstants.AdditionalTransferTaxesLineNumber, // Adjust if needed based on your implementation
    description:
      StewartConstants.VAAdditionalTransferTaxesGrantorCityDescription,
    encompassFieldID_Amount: "",
    encompassFieldID_BuyerAmount: EncompassFieldIDs.HUDLine1206BuyerAmount,
    encompassFieldID_SellerAmount: EncompassFieldIDs.HUDLine1206SellerAmount,
    encompassFieldID_Description: EncompassFieldIDs.HUDLine1206Description,
    encompassFieldID_Payee: EncompassFieldIDs.HUDLine1206Payee,
  });

  addTaxFee({
    priceQuote,
    QuoteResponse: jsonData,

    taxType: "GrantorTax",
    jurisdiction: "County",
    hudLineNumber: StewartConstants.AdditionalTransferTaxesLineNumber, // Adjust if needed
    description:
      StewartConstants.VAAdditionalTransferTaxesGrantorCountyDescription,
    encompassFieldID_Amount: "",
    encompassFieldID_BuyerAmount: EncompassFieldIDs.HUDLine1206BuyerAmount,
    encompassFieldID_SellerAmount: EncompassFieldIDs.HUDLine1206SellerAmount,
    encompassFieldID_Description: EncompassFieldIDs.HUDLine1206Description,
    encompassFieldID_Payee: EncompassFieldIDs.HUDLine1206Payee,
  });

  addTaxFee({
    priceQuote,
    QuoteResponse: jsonData,

    taxType: "GrantorTax",
    jurisdiction: "State",
    hudLineNumber: StewartConstants.AdditionalTransferTaxesSecondLineNumber, // Adjust if needed
    description:
      StewartConstants.VAAdditionalTransferTaxesGrantorStateDescription,
    encompassFieldID_Amount: "",
    encompassFieldID_BuyerAmount: EncompassFieldIDs.HUDLine1207BuyerAmount,
    encompassFieldID_SellerAmount: EncompassFieldIDs.HUDLine1207SellerAmount,
    encompassFieldID_Description: EncompassFieldIDs.HUDLine1207Description,
    encompassFieldID_Payee: EncompassFieldIDs.HUDLine1207Payee,
  });
  // Virginia - Grantee Fees
  addTaxFee({
    priceQuote,
    QuoteResponse: jsonData,

    taxType: "GranteeTax",
    jurisdiction: "City",
    hudLineNumber: StewartConstants.AdditionalTransferTaxesThirdLineNumber, // From AddAdditionalTaxFee_1208 definition
    description:
      StewartConstants.VAAdditionalTransferTaxesGranteeCityDescription,
    encompassFieldID_Amount: "",
    encompassFieldID_BuyerAmount: EncompassFieldIDs.HUDLine1208BuyerAmount,
    encompassFieldID_SellerAmount: EncompassFieldIDs.HUDLine1208SellerAmount,
    encompassFieldID_Description: EncompassFieldIDs.HUDLine1208Description,
    encompassFieldID_Payee: EncompassFieldIDs.HUDLine1208Payee,
  });

  addTaxFee({
    priceQuote,
    QuoteResponse: jsonData,

    taxType: "GranteeTax",
    jurisdiction: "County",
    hudLineNumber: StewartConstants.AdditionalTransferTaxesThirdLineNumber, // From AddAdditionalTaxFee_1208 definition
    description:
      StewartConstants.VAAdditionalTransferTaxesGranteeCountyDescription,
    encompassFieldID_Amount: "",
    encompassFieldID_BuyerAmount: EncompassFieldIDs.HUDLine1208BuyerAmount,
    encompassFieldID_SellerAmount: EncompassFieldIDs.HUDLine1208SellerAmount,
    encompassFieldID_Description: EncompassFieldIDs.HUDLine1208Description,
    encompassFieldID_Payee: EncompassFieldIDs.HUDLine1208Payee,
  });

  addTaxFee({
    priceQuote,
    QuoteResponse: jsonData,

    taxType: "GranteeTax",
    jurisdiction: "State",
    hudLineNumber: StewartConstants.AdditionalTransferTaxesFourthLineNumber, // From AddAdditionalTaxFee_1209 definition
    description:
      StewartConstants.VAAdditionalTransferTaxesGranteeStateDescription,
    encompassFieldID_Amount: "",
    encompassFieldID_BuyerAmount: EncompassFieldIDs.HUDLine1209BuyerAmount,
    encompassFieldID_SellerAmount: EncompassFieldIDs.HUDLine1209SellerAmount,
    encompassFieldID_Description: EncompassFieldIDs.HUDLine1209Description,
    encompassFieldID_Payee: EncompassFieldIDs.HUDLine1209Payee,
  });
};
