import styled from '@emotion/styled'
import css from '@emotion/styled'
import typography from '../../../Theme/typography';
import colors from '../../../Theme/colors';
import { jsx } from '@emotion/react'

// TODO squash Contained/Outlined/Text into a single interpolated literal to trim ~100 lines of code

export const Button = styled.button`
  overflow: hidden;
  height:  42px;
  min-width:60px;
  border-radius: 4px;
  border: none;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.4s ease;
  word-spacing: 0;
  text-align: center;
  display: flex;
  flex-direction: row;
  padding: 0  16px;
  line-height: 30px;
  align-items: center;
  &.primary {
    background-color: ${colors.titleBlue};
    ${typography.buttonPrimary};
    border: 1px solid ${colors.titleBlue};
    box-shadow: 0px 3px 3px -2px rgba(106, 116, 120, 0.20), 0px 3px 4px 0px rgba(106, 116, 120, 0.14), 0px 1px 8px 0px rgba(106, 116, 120, 0.12);
  }  
  &.secondary {
    background-color: ${colors.background};
    ${typography.buttonSecondary}; 
    border: 1px solid ${colors.titleBlue};
  }
  &.text {
    background-color: ${colors.background};
    ${typography.buttonSecondary};
    padding-left: 0;
  }
  &.primarySm {
    background-color: ${colors.titleBlue};
    ${typography.buttonPrimarySm};
    border: 1px solid ${colors.titleBlue};
    box-shadow: 0px 3px 3px -2px rgba(106, 116, 120, 0.20), 0px 3px 4px 0px rgba(106, 116, 120, 0.14), 0px 1px 8px 0px rgba(106, 116, 120, 0.12);
  }
  &.secondarySm {
    background-color: ${colors.background};
    ${typography.buttonSecondarySm};
    border: 1px solid ${colors.titleBlue};
  }
  &.link {
    background-color: ${colors.background};
    ${typography.link};
    text-decoration: underline;
  }
`;

export const Icon = styled.img`
  margin: 6px;

`;





