import { fauxReducer, MockDropdownOptions } from "../RateCalculator/config";

export const ConfigComponents = {
    'Button': [
        {
            label: 'Primary',
            type: 'primary',
            onClick: fauxReducer,
        },
        {
            label: 'Secondary',
            type: 'secondary',
            onClick: fauxReducer,
        },
        {
            label: 'Text',
            type: 'Text',
            onClick: fauxReducer,
        },
        {
            label: 'Text',
            type: 'text',
            onClick: fauxReducer,
            icon:true,
        },
        {
            label: 'PrimarySm',
            type: 'primarySm',
            onClick: fauxReducer,
        },
        {
            label: 'SecondarySm',
            type: 'secondarySm',
            onClick: fauxReducer,
        },
        {
            label: 'Link',
            type: 'link',
            onClick: fauxReducer,
        },
    ],
    'Checkbox': [
        {
        label: 'Label for checkmark',
        update: fauxReducer,
        },
    ],
    'Dropdown': [
        {
            options: MockDropdownOptions,
            type: 'primary',
            reducer: fauxReducer,
        },
        {
            options: MockDropdownOptions,
            type: 'small',
            reducer: fauxReducer,
        },
    ],
    'Input': [
        {
            type: 'primary',
            onClick: fauxReducer,
        },
        {
            type: 'small',
            onClick: fauxReducer,
        },
    ],
    'Radio': [
        {
            label: 'Label for radio button',
            type: 'none',
            update: fauxReducer,
        },
    ],
    'RadioBox': [
        {
            options:[{ label: 'The quick brown fox jumps over the lazy dog', value: 'The quick brown fox jumps over the lazy dog' },
                { label: 'The quick brown fox jumps over the lazy dog', value: 'The quick brown fox jumps over the lazy dog' },
                { label: 'The quick brown fox jumps over the lazy dog', value: 'The quick brown fox jumps over the lazy dog' }],
            update: fauxReducer,
            type: 'verticalThreeRow',
        },
        {
            options: [{ label: 'thing', value: 'thins' }, { label: 'thing', value: 'thins' }],
            update: fauxReducer,
        },
    ],
    // 'Modal': [
    //     {
    //         label: 'Secondary',
    //         type: 'secondary',
    //         onClick: fauxReducer,
    //     },
    // ],

};
