import styled from '@emotion/styled'
import css from '@emotion/styled'
import typography from '../../../Theme/typography';
import colors from '../../../Theme/colors';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
  
`;
export const Select = styled.div`
  display: flex;
  flex-direction: row;
  border: solid 1px ${colors.border};
  border-radius: 4px;
  width: 434px;
  margin: 0 12px;
  height:32px;  
  align-items: center;
  justify-content: center;
  border-bottom-right-radius: ${(props) => (props.open ? '0px' : '4px')};
  border-bottom-left-radius: ${(props) => (props.open ? '0px' : '4px')};
  &:hover {
    border: 2px solid ${colors.titleBlue};
  }
  &.small {
    width: 257px;
  }
`;

export const Selected = styled.div`
  padding: 0 12px;
  color: ${colors.input};
  outline: none;
  box-sizing: border-box;
  text-align: left;
  vertical-align: middle;
  ${typography.grid};
`;

export const SelectedInput = styled.input`
  padding: 0 12px;
  color: ${colors.input};
  outline: none;
  border:none;
  width: 432px;
  box-sizing: border-box;
  text-align: left;
  vertical-align: middle;
  ${typography.grid};
  cursor: pointer;
  &.small {
    width: 255px;
  }
`;

export const Options = styled.div`
  display: ${(props) => (props.open ? 'block' : 'none')};
  height: ${(props) => (props.open ? '0' : 'auto')};
  ${typography.grid}
  border: solid 1px ${colors.border};
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  width: 434px;
  margin: -1px 12px;
  position: relative;
  z-index: 20;
  &.small {
    width: 257px;
  }
`;
export const Option = styled.div`
  padding: 0 12px;
  text-align: left;
  margin-left: -1px;
  width: 434px;
  background-color: ${colors.background};
  border-left: solid 1px ${colors.border};
  border-right: solid 1px ${colors.border};

  &:last-of-type {
    border-bottom: solid 1px ${colors.border};
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &:hover {
    background-color: ${colors.hover};
  }
  &.small {
    width: 257px;
  }
`;

export const Chevron = styled.img`
  transform: ${(props)=> (props.expanded ? "rotate(180deg)" : 'none')};
`;


