import { useEffect, useState } from "react";
import { Box, Button, SelectChangeEvent } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState, UpstreamRequestParams } from "interfaces/rootState";
import {
  createResourceOnEpcForRwFiles,
  getTransactionTypeParam,
} from "utils/helpers";
import axiosEcpInstance from "lib/axiosEcp";
import StewartIcon from "assets/images/stewartlogo.png";
import { useNavigate } from "react-router-dom";
import {
  createFeeQuotePayload,
  feeQuotePdfPayload,
} from "utils/createFeeQuote";
import {
  createEPCTransaction,
  updateEPCTransaction,
} from "utils/saveOrderupdates";
import { setpriceQuoteTransactionID } from "redux/features/feeQuoteSlice";
import { logException } from "componnets/atoms/Logger";
import { setRWDocs } from "redux/features/loanSlice";
import { getMissingFields } from "./srcHelper";

import TransactionDetails from "componnets/organisms/src/transactionDetails";
import TitleInsurance from "componnets/organisms/src/TitleInsurancepremium";
import Endorsement from "componnets/organisms/src/endorsements";
import TitleServicefee from "componnets/organisms/src/titleserviceFee";
import Recordingfee from "componnets/organisms/src/recordingFees";
import { FormProvider, useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import MainLayout from "layouts/mainLayout";
import {
  TransactionUpObject,
  fetchPolicyInsuredTypes,
  selectLoanData,
  fetchEndorsements,
  fetchStateSettings,
  fetchRecordings,
  fetchCounty,
  fetchTransactionTypes,
  fetchCoverageTypes_OP,
  fetchCoverageTypes_MP,
  fetchQuoteResponse,
} from "./utils";
import type { AppDispatch } from "redux/store/index";
import useTransactionDetails from "hooks/useTransactionDetails";
import { useDeepCompareEffect } from "hooks/useDeepCompareMemoize";
import EPCModal from "componnets/atoms/EpcModal";
import useTitleInsuraceDetails from "hooks/useTitleInsuranceDetails";
import { setCountyCode } from "redux/features/contyCodeSlice";
import { getCountyCode } from "utils/rateCalculator";
import useRunOnceAfterValues from "hooks/useRunOnceAfterValue";
import { AxiosError } from "axios";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function StewartRC() {
  const {
    loanData: loan,
    property,
    CountyList,
    slectedTransaction,
    countyCode,
    PolicyInsuredType,
    selectedTransObject,
    coverageTypes_mp,
    coverageTypes_op,
    ProviderID,
    ownerEndorsements,
    lenderEndorsements,
    PageRec,
    IsTorren,
    DefaultNumberOfPagesDeed,
    DefaultNumberOfPagesMortgageRefi,
    DefaultNumberOfPagesMortgageSale,
    DefaultNumberOfPagesRelease,
  } = useSelector(selectLoanData);

  const { city, state, postalCode, county, loanPurposeType } = property ?? {};

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const epcTransactype =
    getTransactionTypeParam(loanPurposeType)?.toUpperCase() || "";

  const [validAlert, setValidAlert] = useState<string>("");

  const initTransactionDetails = useTransactionDetails();
  const initTitleInsuranceDetails = useTitleInsuraceDetails();

  useRunOnceAfterValues(() => {
    dispatch(setCountyCode(getCountyCode(CountyList, state, county)));
  }, [CountyList, state, county]);

  useRunOnceAfterValues(() => {
    Promise.all([
      dispatch(fetchEndorsements(state)),
      dispatch(fetchStateSettings(state)),
      dispatch(fetchCounty(state)),
      dispatch(fetchTransactionTypes(state)),
    ]).catch((error: AxiosError) => {
      console.log("initialsNetwork calls error ", error);
    });
  }, [state]);

  useEffect(() => {
    const missingFields = getMissingFields({
      city,
      county,
      postalCode,
      state,
      loanPurposeType: epcTransactype,
      ...(epcTransactype === "REFI" && {
        loanAmount: loan?.baseLoanAmount || 0,
      }),
      ...(epcTransactype === "SALE" && {
        loanAmount: loan?.baseLoanAmount || 0,
        salesPrice: loan?.purchasePriceAmount || 0,
      }),
    });
    if (missingFields.length) {
      setValidAlert(missingFields);
      return;
    }
    return () => console.log("unmounted src");
  }, []);

  useEffect(() => {
    const requestParams: UpstreamRequestParams = {
      stateCode: state,
      ...(slectedTransaction && {
        transactionType: TransactionUpObject?.[slectedTransaction] ?? "",
      }),
    };
    if (slectedTransaction.length) {
      dispatch(fetchPolicyInsuredTypes(requestParams));
    }
  }, [state, slectedTransaction]);

  useEffect(() => {
    if (countyCode) dispatch(fetchRecordings({ state, countyCode }));
  }, [countyCode]);

  useEffect(() => {
    if (PolicyInsuredType?.length && selectedTransObject?.Code) {
      PolicyInsuredType.forEach((insuredType: any) => {
        if (insuredType.Code === "OP") {
          dispatch(
            fetchCoverageTypes_OP({
              state,
              transactionType: selectedTransObject?.Description || "",
              policyInsuredTypeCode: insuredType.Code,
            })
          );
        } else if (insuredType.Code === "MP") {
          dispatch(
            fetchCoverageTypes_MP({
              state,
              transactionType: selectedTransObject?.Description || "",
              policyInsuredTypeCode: insuredType.Code,
            })
          );
        }
      });
    }
  }, [PolicyInsuredType, selectedTransObject?.Code]);

  useRunOnceAfterValues(createEstimate, [ProviderID]);

  const initVals = {
    ...initTransactionDetails,
    ...initTitleInsuranceDetails,
  };

  const methods = useForm({ defaultValues: initVals });
  const { reset, control } = methods;

  useDeepCompareEffect(() => {
    reset(initVals);
  }, [initVals]);

  function createEstimate() {
    const payload = createFeeQuotePayload({
      salesPrice: loan?.purchasePriceAmount
        ? `${loan?.purchasePriceAmount?.toFixed(2)}` || "0.00"
        : "0.00",
      loanAmount: loan?.baseLoanAmount
        ? `${loan?.baseLoanAmount?.toFixed(2)}` || "0.00"
        : "0.00",
      transactionType: epcTransactype,
      countyCode,
      ...property,
      lenderPolicyCoverageCode:
        coverageTypes_mp.find((x: any) => x.IsDefault === "true")?.Code || "",
      ownerPolicyCoverageCode:
        coverageTypes_op.find((x: any) => x.IsDefault === "true")?.Code || "",
      ProviderID,
      ownerEndorsements,
      lenderEndorsements,
      PageRec,
      IsTorren,
      DefaultNumberOfPagesDeed,
      DefaultNumberOfPagesMortgageRefi,
      DefaultNumberOfPagesMortgageSale,
      DefaultNumberOfPagesRelease,
    });
    dispatch(fetchQuoteResponse(payload));
  }

  function goback() {
    navigate("/");
  }

  return (
    <Box width={"100%"} p={2}>
      <MainLayout sx={{ display: "block" }}>
        <FormProvider {...methods}>
          <EPCModal
            open={validAlert.length > 0}
            onClose={goback}
            title="Information"
            showOkButton
          >
            {`${validAlert} ${
              validAlert?.split(",")?.length > 1 ? "are" : "is"
            } required`}
          </EPCModal>
          <TransactionDetails />
          <TitleInsurance />
          <Endorsement />
          <TitleServicefee />
          <Recordingfee />
          <DevTool control={control} />
        </FormProvider>
      </MainLayout>
      <br />
      <Box display="flex" gap={2}>
        <Button size="small" variant="text" onClick={goback}>
          <ArrowBackIcon />
          Back
        </Button>
        <Button size="small" variant="contained" onClick={createEstimate}>
          Create Estimate
        </Button>
        <Button size="small" color="inherit" variant="outlined">
          New Estimate
        </Button>
      </Box>
    </Box>
  );
}

export default StewartRC;
