import StewartLogo from "../../Assets/StewartLogo.jpg";
import HeaderRight from "../../Assets/HeaderRight.jpg";
import * as Styled from './RateCalculator.styles.js';
import { defaultSections } from '../config';
import TextOnly from './TextOnly';
import VerticalGrid from './VerticalGrid';
import GridBox from './Grid';
import BaseComponent from '../../Assets/BaseComponent'
import { calculatorUtils } from "../util";
import Button from "../Components/Button/Button";
import MainLayout from "layouts/mainLayout";
import ImportFeeButton from "../Components/importFeetButton";

class RateCalculator extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
        };
        // this.initForm = this.initForm.bind(this)
        this.handleUpdateField = calculatorUtils.handleUpdateField.bind(this)
    }

    render() {
    return (
        <Styled.PageBox >
             <MainLayout sx={{ display: "block" }}>
            {/* <Styled.Header>
            <img src={StewartLogo}  alt="Mock Stewart Logo" height='40' />
            <img src={HeaderRight}  alt="Mock Right Header" height='60' />
            </Styled.Header> */}
                {defaultSections.map((section, idx) => (
                    <Styled.SectionBox key={idx}>
                        <Styled.SectionTitle >
                            {section.title}
                            {section.buttonRight ? <Button label={section.buttonRight} type={section.buttonTypeRight}/>: null}

                        </Styled.SectionTitle>
                        {section.type==='TextOnly'?
                            <TextOnly
                                section={section}
                            />
                       : section.type==='VerticalGrid' ?
                            <VerticalGrid
                                section={section}
                                reducer={this.handleUpdateField}
                            />
                       :
                          <GridBox
                            section={section}
                            reducer={this.handleUpdateField}
                          />
                        }
                    </Styled.SectionBox>
                    )
            )}
            <Styled.Footer>
                <Button label='Back' type='text' icon />
                <Styled.Spacer width='24px'/>
                <Button label='New Estimate' type='primary'/>
                <Styled.Spacer width='24px'/>
                <Button label='Create Estimate' type='secondary'/>
                <Styled.Spacer width='24px'/>
                {/* <Button onClick= {importFee} label='Import Fees' type='secondary'/> */}
                <ImportFeeButton/>
            </Styled.Footer>
            </MainLayout>
        </Styled.PageBox>
    )}
}

export default RateCalculator;
