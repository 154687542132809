export default {
    input: 'rgba(18, 18, 18, 0.87)',
    title: '#212121',
    border: '#BDBDBD',
    background: '#FFFFFF',
    hover: '#EFF7FF',
    titleBlue: '#0075AA',
    stewartRed:'#9E233A',
    liteBackground: '#EDF2F2'
};
