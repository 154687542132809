import React, { Component } from "react";
import { DummyData, transactionType } from "../config";
import * as Styled from './component.styles';

//TODO:
// const TextOnly = () => {
//     const {
//         data: {
//             lenderClientName = "",
//             lenderPhoneNumber = "",
//             clientID = "",
//             propertyStreet = "",
//             propertyZipCode = "",
//             propertyState = "",
//             propertyCity = "",
//             propertyCounty = "",
//         },
//     } = useSelector((st: RootState) => st.loanParams) || {};
//     const lenderAddress = `${propertyStreet} ${propertyCounty}, ${propertyState} ${propertyZipCode}`;
//     const phoneNum = lenderPhoneNumber?.includes("null") ? "" : lenderPhoneNumber;
//     return (
//         <div>TEXT ONLY</div>
//     );
// };

class TextOnly extends Component {

    constructor(props: {}) {
        super(props);
        this.state = {}
    }
    //TODO: PLUG IN HEADERS
    //TODO: REPLACE WITH INPUTS
    //TODO: TRASH MUI
    render(){
        const refiSwitch = 'Purchase' === transactionType;
        const endorsementsLender = DummyData.endorsements.lender;

        return(
            <Styled.GridBox>
                <Styled.Table>
                    <Styled.TableHeader>
                        <Styled.Headers>
                            <Styled.HeaderRowStatic>Property Location</Styled.HeaderRowStatic>
                            <Styled.HeaderRowStatic>Transaction Type</Styled.HeaderRowStatic>
                            {refiSwitch ?
                                <Styled.HeaderRowStatic>Sales Price</Styled.HeaderRowStatic>
                            : null}
                            <Styled.HeaderRowStatic>Loan Amount</Styled.HeaderRowStatic>
                        </Styled.Headers>
                    </Styled.TableHeader>
                    <Styled.TableBody>
                        <Styled.TableRow>
                            <Styled.TableDataStatic>
                                Houston, TX Harris
                            </Styled.TableDataStatic>
                            <Styled.TableDataStatic>
                                {refiSwitch ? "Purchase with Mortgage" : "Refinance"}
                            </Styled.TableDataStatic>
                                {refiSwitch ?
                                    <Styled.TableDataStatic>
                                    $250,000.00
                                    </Styled.TableDataStatic>
                                : null
                                }
                            <Styled.TableDataStatic>$200,000.00</Styled.TableDataStatic>
                        </Styled.TableRow>
                    </Styled.TableBody>
                </Styled.Table>
            </Styled.GridBox>
        )
    }
}

export default TextOnly;
