import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import BaseComponent from '../../../Assets/BaseComponent';
import Button from '../../../RateCalculator/Components/Button/Button';
import * as Styled from './Modal.styles';
import CLOSE from './../../../Assets/Close.svg'
import BUILDING from './../../../Assets/Building.svg'
import CART from './../../../Assets/Cart.svg'

class Modal extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id || '',
            fadeType: null,
        };
    }

    componentDidMount() {
        // window.addEventListener('keydown', this.onEscKeyDown, false);
        // setTimeout(() => this.setState({ fadeType: 'in' }), 0);
        if (this.props.isOpen) {
            setTimeout(() => this.setState({ fadeType: 'in' }));
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.props.isOpen && prevProps.isOpen) {
            this.setState({ fadeType: 'out' });
        } else if (this.props.isOpen && !prevProps.isOpen) {
            this.setState({ fadeType: 'in' });
        }
    }

    transitionEnd = (e) => {
        if (e.propertyName !== 'opacity' || this.state.fadeType === 'in') return;

        if (this.state.fadeType === 'out') {
            this.handleClose(e);
        }
    };

    handleClose = (e) => {
        e.preventDefault();
        this.setState({ fadeType: 'out' });
        if (typeof this.props.onClose === 'function') {
            setTimeout(this.props.onClose, 150); // 0.15s
        } else
            console.log('props.onClose function is required to unmount this controlled Modal');
    };

    render() {
        const header= this.props.header;

        if (this.props.isOpen) {
            return ReactDOM.createPortal(
                <Styled.StyledModal
                    id={this.props.id}
                    className={`wrapper fade-${this.state.fadeType}`}
                    role='dialog'
                    onTransitionEnd={this.transitionEnd}
                >
                    <Styled.ModalBox>
                            <Styled.Header >
                                <Styled.IconBox>
                                    <Styled.HeaderIcon src={BUILDING}/>
                                </Styled.IconBox>
                                <Styled.HeaderText>{header}</Styled.HeaderText>
                                        <Styled.CloseButton src={CLOSE} onClick={this.handleClose}/>
                            </Styled.Header>
                            <Styled.ModalBody>
                                <Styled.SearchBox></Styled.SearchBox>
                                <Styled.ListBox>

                                </Styled.ListBox>
                            </Styled.ModalBody>
                            <Styled.ModalButtons>
                                    <Button
                                        type='primary'
                                        label='Save'
                                    />
                                <Styled.Spacer width='24px'/>
                                <Button
                                        type='secondary'
                                        label='Cancel'
                                        onClick={this.handleClose}
                                    />
                            </Styled.ModalButtons>
                    </Styled.ModalBox>
                    <div className={'background'} onMouseDown={this.handleClose} />
                </Styled.StyledModal>, document.body);
        } else {
            return null;
        }
    }
}

Modal.propTypes = {
    id: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    header: PropTypes.string,
    subhead: PropTypes.string,
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    handleClick: PropTypes.func,    //buttonOne Click
    buttonOne: PropTypes.string,    //buttonOne label
    handleClickSecondary: PropTypes.func, //buttonTwo Click
    buttonTwo: PropTypes.string,    //buttonTwo label
    handleClickWait: PropTypes.func,
};

export default Modal;
