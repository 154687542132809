import { substituteParams } from "utils/UrlMap";
import { PriceQuote, PriceQuoteFee } from "./priceQuotes";
import { getAmount, getValueFromJson } from "./utils";
import { StewartConstants } from "constants/strings";

interface AddTaxFeeParams {
  priceQuote: PriceQuote;
  taxType?: string;
  jurisdiction?: string;
  QuoteResponse: any;
  hudLineNumber?: string;
  description: string;
  encompassFieldID_Amount?: string;
  encompassFieldID_BuyerAmount?: string;
  encompassFieldID_SellerAmount?: string;
  encompassFieldID_Description?: string;
  encompassFieldID_Payee?: string;
}

export function addTaxFee({
  priceQuote,
  taxType = "",
  jurisdiction = "",
  QuoteResponse,
  hudLineNumber,
  description,
  encompassFieldID_Amount,
  encompassFieldID_BuyerAmount,
  encompassFieldID_SellerAmount,
  encompassFieldID_Description,
  encompassFieldID_Payee,
}: AddTaxFeeParams): number {
  const totalTaxXPath =
    "/Recording/CFPB2015/CFPBTax[TaxType='{taxType}']/TaxingEntities/TaxingEntity[TaxingEntityJurisdiction='{jurisdiction}']/TaxAmount";
  const buyerSplitXPath =
    "/Recording/CFPB2015/CFPBTax[TaxType='{taxType}']/TaxingEntities/TaxingEntity[TaxingEntityJurisdiction='{jurisdiction}']/BuyerSplit";
  const sellerSplitXPath =
    "/Recording/CFPB2015/CFPBTax[TaxType='{taxType}']/TaxingEntities/TaxingEntity[TaxingEntityJurisdiction='{jurisdiction}']/SellerSplit";
  const payeeXPath =
    "/Recording/CFPB2015/CFPBTax[TaxType='{taxType}']/TaxingEntities/TaxingEntity[TaxingEntityJurisdiction='{jurisdiction}']/TaxingEntityName";

  let totalTaxAmount = getAmount(
    getValueFromJson(
      QuoteResponse,
      substituteParams(totalTaxXPath, {
        taxType,
        jurisdiction,
      })
    )
  );

  console.log(totalTaxAmount, "totalTaxAmount");

  let buyerTaxAmount = getAmount(
    getValueFromJson(
      QuoteResponse,
      substituteParams(buyerSplitXPath, {
        taxType,
        jurisdiction,
      })
    )
  );
  let sellerTaxAmount = getAmount(
    getValueFromJson(
      QuoteResponse,
      substituteParams(sellerSplitXPath, {
        taxType,
        jurisdiction,
      })
    )
  );
  let payeeTaxAmount = getAmount(
    getValueFromJson(
      QuoteResponse,
      substituteParams(payeeXPath, {
        taxType,
        jurisdiction,
      })
    )
  );
  if (buyerTaxAmount === 0 && sellerTaxAmount === 0) {
    switch (taxType) {
      case StewartConstants?.MNAdditionalTransferTaxesDeedDescription:
      case StewartConstants?.MDAdditionalTransferTaxesDeedDescription:
      case StewartConstants?.VAAdditionalTransferTaxesGrantorCityDescription:
      case StewartConstants?.VAAdditionalTransferTaxesGrantorCountyDescription:
      case StewartConstants?.VAAdditionalTransferTaxesGrantorStateDescription:
        sellerTaxAmount = totalTaxAmount;
        break;
      default:
        buyerTaxAmount = totalTaxAmount;
        break;
    }
  }
  if (totalTaxAmount !== 0) {
    const taxFee: PriceQuoteFee = {
      HudLineNumber: hudLineNumber,
      Description: description,
      EncompassFieldID_Description: encompassFieldID_Description,
      Payee: jurisdiction + " of " + payeeTaxAmount,
      EncompassFieldID_Payee: encompassFieldID_Payee,
      TotalAmount: totalTaxAmount,
      EncompassFieldID_TotalAmount: encompassFieldID_Amount,
      EncompassFieldID_BuyerAmount: encompassFieldID_BuyerAmount,
      EncompassFieldID_SellerAmount: encompassFieldID_SellerAmount,
      BuyerAmount: buyerTaxAmount,
      SellerAmount: sellerTaxAmount,
    };

    priceQuote.feeList.push(taxFee);
  }
  return totalTaxAmount;
}
