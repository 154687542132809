import { EncompassFieldIDs } from "constants/strings";
import { Endorsement, PriceQuote, PriceQuoteFee } from "./priceQuotes";
import { toArray } from "utils/placeOrder";

export function getAmount(value: string | undefined): number {
  let amount = 0;

  if (value !== undefined && value !== "") {
    amount = parseFloat(value);

    if (isNaN(amount)) {
      console.error(`Failed to parse value '${value}' to a number`);
    }
  }

  return amount;
}

export const loadPricingProps = (QuoteResponse: any) => {
  const {
    Pricing: {
      RateManual: {
        OwnerPolicy: {
          TitleServiceCharge = "",
          Premium: pricingRMOwnerPremium = "",
          Splits: {
            Buyer: {
              TitleServiceCharge: OwnersSplitBuyerAmount = "",
              Premium: OwnersSplitBuyerPremium = "",
              SalesTax: OwnersSplitBuyerSalesTax = "",
            } = {},
            Seller: {
              Premium: lenderSplitsSellerpremium = "",
              SalesTax: OwnerrateSplitsSellerSalesTax = "",
              TitleServiceCharge: ownerSplitsSellertitleService = "",
            } = {},
          } = {},
        } = {},
        LenderPolicy: {
          TitleServiceCharge: lenderTitleServiceCharge = "",
          Premium: rateMannualLenderpremium = "",
          SalesTax: rateMannualLenderSalesTax = "",
          Splits: {
            Buyer: {
              TitleServiceCharge: lendersSplitBuyerTitleservicecharge = "",
              Premium: lendersSplitBuyerTitleservicePremium = "",
              SalesTax: lendersSplitBuyerTitleserviceSalesTax = "",
            } = {},
            Seller: {
              Premium: lendersSplitSellerTitleservicePremium = "",
              SalesTax: lenderSplitsSellerSalesTax = "",
              TitleServiceCharge: lenderSplitsSellertitleService = "",
            } = {},
          } = {},
        } = {},
      } = {},
      TRID: {
        TRIDOwnerPolicy: {
          Premium: ownerPremiumAmount = "",
          Splits: {
            Buyer: {
              Premium: TridOwnerSplitPremium = "",
              SalesTax: PricingtridOwnerSplitsSalesTax = "",
            } = {},
            Seller: {
              Premium: TridOwnerSplitSellerPremium = "",
              SalesTax: PricingtridOwnerSplitsSellerSalesTax = "",
            } = {},
          } = {},
        } = {},
        TRIDLenderPolicy: {
          Premium: lenderPremiumAmount = "",
          Splits: {
            Buyer: {
              Premium: TridLenderSplitPremium = "",
              SalesTax: PricingLenderSplitsSalesTax = "",
            } = {},
            Seller: {
              Premium: TridLenderSplitSellerPremium = "",
              SalesTax: PricingtridLenderSplitsSellerSalesTax = "",
            } = {},
          } = {},
        } = {},
      } = {},
      TitleServiceFee: {
        ItemizedTitleServiceFeeList: { ItemizedTitleServiceFee = "" } = {},
      } = {},
    } = {},
  } = QuoteResponse;
  const ownerTitleServiceChargeAmount = getAmount(TitleServiceCharge);
  const lenderTitleServiceChargeAmount = getAmount(lenderTitleServiceCharge);
  const ownerTRIDPremiumAmount = getAmount(ownerPremiumAmount);
  const lenderTRIDPremiumAmount = getAmount(lenderPremiumAmount);

  return {
    ownerTitleServiceChargeAmount,
    ownerTRIDPremiumAmount,
    lenderTitleServiceChargeAmount,
    lenderTRIDPremiumAmount,
    OwnersSplitBuyerAmount,
    TridOwnerSplitPremium,
    PricingtridOwnerSplitsSalesTax,
    TridOwnerSplitSellerPremium,
    PricingtridOwnerSplitsSellerSalesTax,
    pricingRMOwnerPremium,
    OwnersSplitBuyerPremium,
    OwnersSplitBuyerSalesTax,
    lenderSplitsSellerpremium,
    lenderSplitsSellerSalesTax,
    OwnerrateSplitsSellerSalesTax,
    lenderSplitsSellertitleService,
    lendersSplitBuyerTitleservicecharge,
    TridLenderSplitPremium,
    PricingLenderSplitsSalesTax,
    TridLenderSplitSellerPremium,
    PricingtridLenderSplitsSellerSalesTax,
    rateMannualLenderpremium,
    rateMannualLenderSalesTax,
    lendersSplitBuyerTitleservicePremium,
    lendersSplitBuyerTitleserviceSalesTax,
    lendersSplitSellerTitleservicePremium,
    ItemizedTitleServiceFee,
  };
};

export const getEndorsementNode = (QuoteResponse: any) => {
  const {
    Premium = "",
    SalesTax = "",
    Name,
    Splits: {
      Buyer: { Premium: buyerPremium = "", SalesTax: buyerSalesTax = "" } = {},
      Seller: {
        Premium: sellelrPremium = "",
        SalesTax: sellelrSalesTax = "",
      } = {},
    } = {},
  } = QuoteResponse;

  return {
    Premium,
    SalesTax,
    buyerPremium,
    buyerSalesTax,
    sellelrPremium,
    sellelrSalesTax,
    Name,
  };
};

export const getTitleProps = (titleNode: any) => {
  const {
    Name,
    Amount,
    SalesTax,
    BuyerAmount,
    SalesTaxBuyerAmount,
    SellerAmount,
    SalesTaxSellerAmount,
  } = titleNode;
  return {
    Name,
    Amount,
    SalesTax,
    BuyerAmount,
    SalesTaxBuyerAmount,
    SellerAmount,
    SalesTaxSellerAmount,
  };
};
export const loadRecordingProps = (recoringNode: any) => {
  const { Deed, Mortgage, Release } = recoringNode;
  return { Deed, Mortgage, Release };
};

// Load Endorsments from feeQuotes Response
export function loadEndorsements(
  jsonData: any,
  endorsementType: string
): any[] {
  // Access data using dot notation and variable interpolation

  return (
    jsonData?.Pricing?.[endorsementType + "EndorsementList"]?.Endorsement || []
  );
}

export function getEndorsementAmount(
  amountValue: string,
  taxValue: string
): number {
  let totalAmount = 0;

  if (amountValue !== undefined && amountValue !== "") {
    totalAmount += parseFloat(amountValue); // Parse to a number
  }

  if (taxValue !== undefined && taxValue !== "") {
    totalAmount += parseFloat(taxValue); // Parse to a number
  }

  return totalAmount;
}

export function getTitleFeeAmount(
  amountValue: string,
  taxValue: string
): number {
  let totalAmount = 0;

  if (amountValue !== undefined && amountValue !== "") {
    totalAmount += parseFloat(amountValue); // Parse to a number
  }

  if (taxValue !== undefined && taxValue !== "") {
    totalAmount += parseFloat(taxValue); // Parse to a number
  }

  return totalAmount;
}

export function getValueFromJson(jsonObject: any, pathTemplate: string): any {
  // Split the path into segments
  const segments = pathTemplate.split("/").filter(Boolean);

  // Navigate the JSON object
  let currentObject = jsonObject;
  for (const segment of segments) {
    if (segment.includes("[")) {
      const [propertyName, filterCondition] = segment.split("[");
      const [filterProperty, filterValue] = filterCondition
        .slice(0, -1)
        .split("=");
      currentObject = toArray(currentObject?.[propertyName])?.find(
        (item: any) => item[filterProperty] === filterValue.replace(/'/g, "")
      );
    } else {
      currentObject = currentObject?.[segment];
    }

    if (currentObject === undefined) {
      return ""; // Or throw an error if you prefer stricter handling
    }
  }

  return currentObject || null;
}
