// store.js
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "redux/features/authSlice";
import OfficeData from "redux/features/contractsSlice";
import lenderSetting from "redux/features/lenderSlice";
import loader from "redux/features/loader";
import loanParams from "redux/features/loanParams";
import loanReducer from "redux/features/loanSlice";
import officeListReducer from "redux/features/officeListSlice";
import operationId from "redux/features/operationId";
import src from "redux/features/srcSlice";
import statusList from "redux/features/statusList";
import feeQuoteReducer from "redux/features/feeQuoteSlice";
import policyInsuredTypesSlice from "redux/features/policyInsuredTypesSlice";
import transactionDetailsSlice from "redux/features/transactionDetailsSlice";
import titleInsurancePremiumSlice from "redux/features/titleInsurancePremiumSlice";
import endorsementsSlice from "redux/features/endorsementsSlice";
import stateSettingsSlice from "redux/features/stateSettingsSlice";
import recordingsSlice from "redux/features/recordingsSlice";
import transactionTypesSlice from "redux/features/transactionTypesSlice";
import countyCodeSlice from "redux/features/contyCodeSlice";

import coverageTypes_OP_Slice from "redux/features/covergeTypes_OP_Slice";
import coverageTypes_MP_Slice from "redux/features/coverageTypes_MP_Slice";
import quoteResponseSlice from "redux/features/quoteResponseSlice";
import feeImportSlice from "redux/features/feeImportSlice";

export type AppDispatch = typeof store.dispatch;

const reducer = {
  auth: authReducer,
  loan: loanReducer,
  officeData: OfficeData,
  officeList: officeListReducer,
  lender: lenderSetting,
  loader: loader,
  loanParams: loanParams,
  statusList: statusList,
  operationId: operationId,
  feeQuotes: feeQuoteReducer,
  src: src,
  policyInsuredTypes: policyInsuredTypesSlice,
  endorsements: endorsementsSlice,
  stateSettings: stateSettingsSlice,
  recordings: recordingsSlice,
  coverageTypes_OP: coverageTypes_OP_Slice,
  coverageTypes_MP: coverageTypes_MP_Slice,
  transactionDetails: transactionDetailsSlice,
  insurancePremim: titleInsurancePremiumSlice,
  transactionalTypes: transactionTypesSlice,
  countyCode: countyCodeSlice,
  quoteResponse: quoteResponseSlice,
  feeImport: feeImportSlice,
};

const store = configureStore({
  reducer,
});

export default store;
