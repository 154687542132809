import { defaultSections } from "./config";
import * as Styled from "./Pages/RateCalculator.styles.js";
import TextOnly from './Pages/TextOnly';
import VerticalGrid from './Pages/VerticalGrid';
import Grid from './Pages/Grid';

const calculatorUtils = {
//TODO:
    initForm() {
        const GridComponents = {
            TextOnly: <TextOnly/>,
            Grid: <Grid/>,
            VerticalGrid: <VerticalGrid/>
        };
        return defaultSections.map((section, idx) => {
            const title = section.title;
            // @ts-ignore
            const Component = GridComponents[section.type];
            return (
                <Styled.SectionBox>
                    <Styled.SectionTitle >
                        {title}
                </Styled.SectionTitle>
                <Component/>
            </Styled.SectionBox>
        )
        })
    },
    handleUpdateField() {
        console.log('IN UPDATE')
    }
}

export { calculatorUtils };

// initForm() {
//    const GridComponents = {
//        TextOnly: <TextOnly/>,
//        Grid: <GridBox/>,
//        VerticalGrid: <VerticalGrid/>
//    };
//    return defaultSections.map((section, idx) => {
//    const title = section.title;
//    // @ts-ignore
//    const Component = GridComponents[section.type];
//    return (
//            <Styled.SectionBox>
//                <Styled.SectionTitle >
//                    {title}
//                    {section.buttonRight ? <Button text={section.text} type={section.type}/>: null}
//                </Styled.SectionTitle>
//                <Component
//                    section={section}
//                    />
//            </Styled.SectionBox>
//            )
//    })
// };

// handleUpdateField(value, store, addId, section) {
//     console.log('IN REDUCER', value)
// const {key, type, lineId} = store;
// let formattedValue = value;
// if (type === 'currency' && value !== '') {
//     const noCommas = value.replace(/,/g, '');
//     let newNum = parseFloat(noCommas);
//     formattedValue = new Intl.NumberFormat('en-US', {style: 'decimal', minimumFractionDigits: 2}).format(newNum);
// }
// //ADDED LINES LOGIC
// let addedId = this.state.addedId;
// if (addId && section && (addId >= this.state.addedId[section])) {
//     addedId[section] = addId;
//     this.setState({addedId})
// }
// const dataStorePath = lineId ? lineId : '';
// const action = {
//     dataStorePath,
//     key,
//     value: formattedValue,
//     type,
//     duplicateIdx: undefined,
// };
// const settlementId = parseInt(this.props.store.file.settlementId);
// const body = {
//     settlementId: settlementId,
//     lineId: dataStorePath,
//     fieldValue: formattedValue,
//     fieldName: key,
// };
// try {
//     await this.props.updateSettlement(body);
// } catch {
//     console.log('LINE UPDATE ERROR')

// await this.props.objectAction(action);
// }
