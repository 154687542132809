import React from "react";
import * as Styled from "./component.styles";
import { DummyData, fauxReducer, MockDropdownOptions, transactionType } from "../config";
import BaseComponent from "../../Assets/BaseComponent";
import DropDown from "../../../componnets/atoms/DropDown";
import Dropdown from "../Components/Dropdown/Dropdown";
import Input from "../Components/Input/Input";

class VerticalGrid extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {}}
    //TODO: PLUG IN HEADERS
    //TODO: REPLACE WITH INPUTS
    //TODO: TRASH MUI
    render(){
        const titleInsurance = DummyData.titleInsurance.lender;
        const refiSwitch = 'Purchase' === transactionType;

        return (
            <Styled.GridBox>
                {refiSwitch ? (
                    <Styled.VerticalTable>
                        <Styled.VerticalTableHeader>
                            <Styled.Headers>
                                <Styled.HeaderRow>Policy</Styled.HeaderRow>
                                <Styled.VerticalTotal>Owner's:</Styled.VerticalTotal>
                                <Styled.VerticalTotal>Lender's:</Styled.VerticalTotal>
                            </Styled.Headers>
                        </Styled.VerticalTableHeader>
                        <Styled.VerticalTableBody>
                            {titleInsurance.map((item, idx) => {
                                switch (item.element) {
                                    case 'inputRow':


                                        // (item.inputs) ?
                                        // (
                                        return ( <Styled.VerticalTableRow>
                                            <Styled.VerticalHeaderLeft>{item.description}</Styled.VerticalHeaderLeft>
                                            <Styled.SmallTableData>
                                                <Input type='small'/>
                                            </Styled.SmallTableData>
                                            <Styled.SmallTableData>
                                                <Input type='small'/>
                                            </Styled.SmallTableData>
                                        </Styled.VerticalTableRow>)

                                    case 'totalRow':

                                        // ) : (
                                        //     (item.description === 'Total') ? (
                                        return (   <Styled.VerticalTableRow>
                                            <Styled.VerticalHeaderLeft>{item.description}</Styled.VerticalHeaderLeft>
                                            <Styled.VerticalTableTotalTotal>$100.00</Styled.VerticalTableTotalTotal>
                                            <Styled.VerticalTableTotalTotal>$100.00</Styled.VerticalTableTotalTotal>
                                        </Styled.VerticalTableRow>

                                        )
                                    case 'infoRow':

                                        // ) : (
                                        return ( <Styled.VerticalTableRow>
                                            <Styled.VerticalHeaderLeft>{item.description}</Styled.VerticalHeaderLeft>
                                            <Styled.VerticalTotal>$100.00</Styled.VerticalTotal>
                                            <Styled.VerticalTotal>$100.00</Styled.VerticalTotal>
                                        </Styled.VerticalTableRow>
                                        )
                                    case 'dropdownRow':

                                        // ) : (
                                        return ( <Styled.VerticalTableRow>
                                            <Styled.VerticalHeaderLeft>{item.description}</Styled.VerticalHeaderLeft>
                                            <Styled.SmallTableData>
                                                <Dropdown type='small' reducer={fauxReducer} options={MockDropdownOptions}/>
                                            </Styled.SmallTableData>
                                            <Styled.SmallTableData>
                                                <Dropdown type='small' reducer={fauxReducer} options={MockDropdownOptions} />
                                            </Styled.SmallTableData>
                                        </Styled.VerticalTableRow>)
                                    default: return;
                                }}
                                        //         )
                            //     )
                            // )


                            )}
                        </Styled.VerticalTableBody>
                    </Styled.VerticalTable>
                ) : (
                    <Styled.VerticalTable>
                        <Styled.VerticalTableHeader>
                            <Styled.Headers>
                                <Styled.HeaderRow>Policy</Styled.HeaderRow>
                                <Styled.HeaderRowStatic>Policy/Rate Type</Styled.HeaderRowStatic>
                                <Styled.HeaderRowStatic>Total</Styled.HeaderRowStatic>
                            </Styled.Headers>
                        </Styled.VerticalTableHeader>
                        <Styled.VerticalTableBody>
                            <Styled.VerticalTableRow>
                                <Styled.VerticalHeaderLeft>Refi Loan:</Styled.VerticalHeaderLeft>
                                <Styled.SmallTableData>
                                   <Dropdown reducer={fauxReducer} options={MockDropdownOptions} />
                                </Styled.SmallTableData>
                                <Styled.VerticalTotal>$100.00</Styled.VerticalTotal>
                            </Styled.VerticalTableRow>
                        </Styled.VerticalTableBody>
                    </Styled.VerticalTable>
                )}
            </Styled.GridBox >
        )
    }
}

export default VerticalGrid
