import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Styled from './Checkbox.styles';
import CheckboxFilled from "../../../Assets/CheckboxFilled.svg";
import CheckboxOutline from "../../../Assets/CheckboxOutline.svg";

const Checkbox = ({
    // @ts-ignore

    label,
    // @ts-ignore

    update,
}) => {
    const [selected, setSelected] = useState(false);

    const toggle = () => {
        setSelected(!selected);
        update(selected);
    };

    // @ts-ignore
    return (
    <Styled.Container>
        <Styled.Check
            src ={selected ? CheckboxFilled : CheckboxOutline}
            width={'24px'}
            height={'24px'}
            alt="check"
            onClick={toggle}/>
        <Styled.Label>
            {label}
        </Styled.Label>
    </Styled.Container>
    );
};

Checkbox.propTypes = {
    label: PropTypes.string,
    selected: PropTypes.bool,
    update: PropTypes.func,
};

export default Checkbox;
