import { EncompassFieldIDs } from "constants/strings";
import { Endorsement, PriceQuote, PriceQuoteFee } from "./priceQuotes";
import {
  getEndorsementAmount,
  getEndorsementNode,
  loadEndorsements,
} from "./utils";

export function addEndorsementsSeparately(
  priceQuote: PriceQuote,
  QuoteResponse: any,
  endorsementTypes: string[]
): void {
  let amount = 0,
    buyerAmount = 0,
    sellerAmount = 0,
    totalEndorsementCount = 0;

  for (const endorsement of endorsementTypes) {
    const endorsementType: any[] = loadEndorsements(QuoteResponse, endorsement);
    console.log(endorsementType, "endorsementType");
    for (const endorsement of endorsementType) {
      const {
        Premium,
        SalesTax,
        buyerPremium,
        buyerSalesTax,
        sellelrPremium,
        sellelrSalesTax,
        Name,
      } = getEndorsementNode(endorsement);

      amount = getEndorsementAmount(Premium, SalesTax);
      buyerAmount = getEndorsementAmount(buyerPremium, buyerSalesTax);
      sellerAmount = getEndorsementAmount(sellelrPremium, sellelrSalesTax);

      if (buyerAmount == 0 && sellerAmount == 0) {
        buyerAmount = amount;
      }
      // ... (inside the addEndorsements or addEndorsementsSeparately function, depending on context)

      if (totalEndorsementCount <= 20 && amount > 0) {
        totalEndorsementCount++;

        const endorsementFee: PriceQuoteFee = {};
        endorsementFee.Description = Name;
        endorsementFee.Payee = priceQuote.officeName;
        endorsementFee.BuyerAmount = buyerAmount;
        endorsementFee.SellerAmount = sellerAmount;

        // Use a mapping object or function for cleaner code
        const fieldIdMapping: { [key: number]: { [key: string]: string } } = {
          1: {
            HudLineNumber: "1110",
            EncompassFieldID_Description:
              EncompassFieldIDs.HUDLine1110Description,
            EncompassFieldID_Payee: EncompassFieldIDs.HUDLine1110Payee,
            EncompassFieldID_BuyerAmount:
              EncompassFieldIDs.HUDLine1110BuyerAmount,
            EncompassFieldID_SellerAmount:
              EncompassFieldIDs.HUDLine1110SellerAmount,
            EncompassFieldID_BuyerCanShop:
              EncompassFieldIDs.HUDLine1110BuyerCanShop,
          },
          2: {
            HudLineNumber: "1111",
            EncompassFieldID_Description:
              EncompassFieldIDs.HUDLine1111Description,
            EncompassFieldID_Payee: EncompassFieldIDs.HUDLine1111Payee,
            EncompassFieldID_BuyerAmount:
              EncompassFieldIDs.HUDLine1111BuyerAmount,
            EncompassFieldID_SellerAmount:
              EncompassFieldIDs.HUDLine1111SellerAmount,
            EncompassFieldID_BuyerCanShop:
              EncompassFieldIDs.HUDLine1111BuyerCanShop,
          },
          3: {
            HudLineNumber: "1112",
            EncompassFieldID_Description:
              EncompassFieldIDs.HUDLine1112Description,
            EncompassFieldID_Payee: EncompassFieldIDs.HUDLine1112Payee,
            EncompassFieldID_BuyerAmount:
              EncompassFieldIDs.HUDLine1112BuyerAmount,
            EncompassFieldID_SellerAmount:
              EncompassFieldIDs.HUDLine1112SellerAmount,
            EncompassFieldID_BuyerCanShop:
              EncompassFieldIDs.HUDLine1112BuyerCanShop,
          },
          4: {
            HudLineNumber: "1113",
            EncompassFieldID_Description:
              EncompassFieldIDs.HUDLine1113Description,
            EncompassFieldID_Payee: EncompassFieldIDs.HUDLine1113Payee,
            EncompassFieldID_BuyerAmount:
              EncompassFieldIDs.HUDLine1113BuyerAmount,
            EncompassFieldID_SellerAmount:
              EncompassFieldIDs.HUDLine1113SellerAmount,
            EncompassFieldID_BuyerCanShop:
              EncompassFieldIDs.HUDLine1113BuyerCanShop,
          },
          5: {
            HudLineNumber: "1114",
            EncompassFieldID_Description:
              EncompassFieldIDs.HUDLine1114Description,
            EncompassFieldID_Payee: EncompassFieldIDs.HUDLine1114Payee,
            EncompassFieldID_BuyerAmount:
              EncompassFieldIDs.HUDLine1114BuyerAmount,
            EncompassFieldID_SellerAmount:
              EncompassFieldIDs.HUDLine1114SellerAmount,
            EncompassFieldID_BuyerCanShop:
              EncompassFieldIDs.HUDLine1114BuyerCanShop,
          },

          6: {
            HudLineNumber: "1303",
            EncompassFieldID_Description:
              EncompassFieldIDs.HUDLine1303Description,
            EncompassFieldID_Payee: EncompassFieldIDs.HUDLine1303Payee,
            EncompassFieldID_BuyerAmount:
              EncompassFieldIDs.HUDLine1303BuyerAmount,
            EncompassFieldID_SellerAmount:
              EncompassFieldIDs.HUDLine1303SellerAmount,
          },
          7: {
            HudLineNumber: "1305",
            EncompassFieldID_Description:
              EncompassFieldIDs.HUDLine1305Description,
            EncompassFieldID_Payee: EncompassFieldIDs.HUDLine1305Payee,
            EncompassFieldID_BuyerAmount:
              EncompassFieldIDs.HUDLine1305BuyerAmount,
            EncompassFieldID_SellerAmount:
              EncompassFieldIDs.HUDLine1305SellerAmount,
          },

          8: {
            HudLineNumber: "1306",
            EncompassFieldID_Description:
              EncompassFieldIDs.HUDLine1306Description,
            EncompassFieldID_Payee: EncompassFieldIDs.HUDLine1306Payee,
            EncompassFieldID_BuyerAmount:
              EncompassFieldIDs.HUDLine1306BuyerAmount,
            EncompassFieldID_SellerAmount:
              EncompassFieldIDs.HUDLine1306SellerAmount,
          },
          9: {
            HudLineNumber: "1307",
            EncompassFieldID_Description:
              EncompassFieldIDs.HUDLine1307Description,
            EncompassFieldID_Payee: EncompassFieldIDs.HUDLine1307Payee,
            EncompassFieldID_BuyerAmount:
              EncompassFieldIDs.HUDLine1307BuyerAmount,
            EncompassFieldID_SellerAmount:
              EncompassFieldIDs.HUDLine1307SellerAmount,
          },
          10: {
            HudLineNumber: "1308",
            EncompassFieldID_Description:
              EncompassFieldIDs.HUDLine1308Description,
            EncompassFieldID_Payee: EncompassFieldIDs.HUDLine1308Payee,
            EncompassFieldID_BuyerAmount:
              EncompassFieldIDs.HUDLine1308BuyerAmount,
            EncompassFieldID_SellerAmount:
              EncompassFieldIDs.HUDLine1308SellerAmount,
          },
          11: {
            HudLineNumber: "1309",
            EncompassFieldID_Description:
              EncompassFieldIDs.HUDLine1309Description,
            EncompassFieldID_Payee: EncompassFieldIDs.HUDLine1309Payee,
            EncompassFieldID_BuyerAmount:
              EncompassFieldIDs.HUDLine1309BuyerAmount,
            EncompassFieldID_SellerAmount:
              EncompassFieldIDs.HUDLine1309SellerAmount,
          },
          12: {
            HudLineNumber: "1310",
            EncompassFieldID_Description:
              EncompassFieldIDs.HUDLine1310Description,
            EncompassFieldID_Payee: EncompassFieldIDs.HUDLine1310Payee,
            EncompassFieldID_BuyerAmount:
              EncompassFieldIDs.HUDLine1310BuyerAmount,
            EncompassFieldID_SellerAmount:
              EncompassFieldIDs.HUDLine1310SellerAmount,
          },
          13: {
            HudLineNumber: "1312",
            EncompassFieldID_Description:
              EncompassFieldIDs.HUDLine1312Description,
            EncompassFieldID_Payee: EncompassFieldIDs.HUDLine1312Payee,
            EncompassFieldID_BuyerAmount:
              EncompassFieldIDs.HUDLine1312BuyerAmount,
            EncompassFieldID_SellerAmount:
              EncompassFieldIDs.HUDLine1312SellerAmount,
          },
          14: {
            HudLineNumber: "1313",
            EncompassFieldID_Description:
              EncompassFieldIDs.HUDLine1313Description,
            EncompassFieldID_Payee: EncompassFieldIDs.HUDLine1313Payee,
            EncompassFieldID_BuyerAmount:
              EncompassFieldIDs.HUDLine1313BuyerAmount,
            EncompassFieldID_SellerAmount:
              EncompassFieldIDs.HUDLine1313SellerAmount,
          },
          15: {
            HudLineNumber: "1314",
            EncompassFieldID_Description:
              EncompassFieldIDs.HUDLine1314Description,
            EncompassFieldID_Payee: EncompassFieldIDs.HUDLine1314Payee,
            EncompassFieldID_BuyerAmount:
              EncompassFieldIDs.HUDLine1314BuyerAmount,
            EncompassFieldID_SellerAmount:
              EncompassFieldIDs.HUDLine1314SellerAmount,
          },
          16: {
            HudLineNumber: "1315",
            EncompassFieldID_Description:
              EncompassFieldIDs.HUDLine1315Description,
            EncompassFieldID_Payee: EncompassFieldIDs.HUDLine1315Payee,
            EncompassFieldID_BuyerAmount:
              EncompassFieldIDs.HUDLine1315BuyerAmount,
            EncompassFieldID_SellerAmount:
              EncompassFieldIDs.HUDLine1315SellerAmount,
          },
          17: {
            HudLineNumber: "1316",
            EncompassFieldID_Description:
              EncompassFieldIDs.HUDLine1316Description,
            EncompassFieldID_Payee: EncompassFieldIDs.HUDLine1316Payee,
            EncompassFieldID_BuyerAmount:
              EncompassFieldIDs.HUDLine1316BuyerAmount,
            EncompassFieldID_SellerAmount:
              EncompassFieldIDs.HUDLine1316SellerAmount,
          },
          18: {
            HudLineNumber: "1317",
            EncompassFieldID_Description:
              EncompassFieldIDs.HUDLine1317Description,
            EncompassFieldID_Payee: EncompassFieldIDs.HUDLine1317Payee,
            EncompassFieldID_BuyerAmount:
              EncompassFieldIDs.HUDLine1317BuyerAmount,
            EncompassFieldID_SellerAmount:
              EncompassFieldIDs.HUDLine1317SellerAmount,
          },
          19: {
            HudLineNumber: "1318",
            EncompassFieldID_Description:
              EncompassFieldIDs.HUDLine1318Description,
            EncompassFieldID_Payee: EncompassFieldIDs.HUDLine1318Payee,
            EncompassFieldID_BuyerAmount:
              EncompassFieldIDs.HUDLine1318BuyerAmount,
            EncompassFieldID_SellerAmount:
              EncompassFieldIDs.HUDLine1318SellerAmount,
          },
          20: {
            HudLineNumber: "1319",
            EncompassFieldID_Description:
              EncompassFieldIDs.HUDLine1319Description,
            EncompassFieldID_Payee: EncompassFieldIDs.HUDLine1319Payee,
            EncompassFieldID_BuyerAmount:
              EncompassFieldIDs.HUDLine1319BuyerAmount,
            EncompassFieldID_SellerAmount:
              EncompassFieldIDs.HUDLine1319SellerAmount,
          },
          21: {
            HudLineNumber: "1320",
            EncompassFieldID_Description:
              EncompassFieldIDs.HUDLine1320Description,
            EncompassFieldID_Payee: EncompassFieldIDs.HUDLine1320Payee,
            EncompassFieldID_BuyerAmount:
              EncompassFieldIDs.HUDLine1320BuyerAmount,
            EncompassFieldID_SellerAmount:
              EncompassFieldIDs.HUDLine1320SellerAmount,
          },
        };

        // Assign properties based on totalEndorsementCount
        if (totalEndorsementCount in fieldIdMapping) {
          const fieldIds = fieldIdMapping[totalEndorsementCount];
          Object.assign(endorsementFee, fieldIds);
        } else {
          // Handle the case where totalEndorsementCount is greater than 21
          console.error("Exceeded maximum endorsement limit!");
          // Or throw an error if you prefer a stricter approach
        }

        priceQuote.feeList.push(endorsementFee);
      }
    }
  }
}
