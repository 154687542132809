import {
  StatusStringsConstantsProps,
  StewartConstantsProps,
  stringsConstantsProps,
} from "interfaces/stringsConstantstype";

export const stringsConstants: stringsConstantsProps = {
  headerButton2: "Help",
  headerButton3: "Close",
  headerTypoLoan: "Loan Number:",
  HederTypoWelcome: "Welcome",
  hideAllCom: "Show last 5 Communications",
  hideAllDocs: "Show last 5 Documents",
  orderTrac: "OrderTrac",
  actions: "Actions",
  obtainFee: "Obtain Fee Quotes",
  placeOrder: "Place Order",
  viewData: "View Data Fields",
  cancelOrder: "Cancel Order",
  payoffsdetails: "Payoff Details",
  importantNotesLabel: "Important Notes",
  //  importantNotes: "Welcome to Stewart Title! Please utilize the Obtain Fee quote or Place order buttons to get started",
  orderDetaillabel: "Order Details",
  orderDate: "Order Date:",
  escrowFileNo: "Escrow File Number:",
  orderDataComparison: "Order Data Comparison:",
  selectOffice: "Select Office",
  centralized: "Centralized",
  localOffice: "Local Office",
  locateOffice: "Locate Office",
  borrowerslabel: "Borrowers",
  propertyInformationLabel: "Property Information",
  communications: "Communications",
  showAllComms: "Show All Communications",
  showAllDocs: "Show All Documents",
  addMessage: "Add Message",
  addDocument: "Add Document",
  loanInformationlabel: "Loan Information",
  documents: "Documents",
  loanType: "Loan Type:",
  loanPurpose: "Loan Purpose:",
  amortization: "Amortization Type:",
  loanAmt: "Loan Amount:",
  purchasedPrice: "Purchase price:",
  estCosting: "Estimated Closing:",
  address: "Address:",
  county: "County:",
  propertyType: "Property Type:",
  borrowerLabel: "Borrower:",
  coBorrowerlabel: "CoBorrower:",
  findOffices: "Find Office(s)",
  cancel: "Cancel",
  distanceMiles: "Distance (miles)",
  locateAnOffice: "Locate an Office",
  browse: "Browse",
};
export const EncompassConstants: Record<string, string> = {
  CompanyName: "Stewart Title Company",
  EFolderDocumentID: "EFolderDocumentID",
  DocumentAttachmentID: "DocumentAttachmentID",
  Purchase: "Purchase",
  NoCashOutRefinance: "NoCash-Out Refinance",
  CashOutRefinance: "Cash-Out Refinance",
  Construction: "ConstructionOnly",
  ConstructionToPermanent: "ConstructionToPermanent",
  PayoffAccountTypeMortgage: "MortgageLoan",
  PayoffAccountTypeHELOC: "HELOC",
};

function dynamicString(key: string, ...args: any[]): string {
  const templates: { [key: string]: (...args: any[]) => string } = {
    OrderConfirmedMessage: (fileNumber: string) =>
      `Thank you for choosing Stewart! We have received your order. Please utilize the Payoff Details button to provide payoff information.Your File number is ${fileNumber}`,
    titleDelivered: (titleVesting: string) =>
      `Title is complete and the curative review is in progress. We will issue curative cleared once all requirements have been satisfied.Title is vested as ${titleVesting}`,
    curativeCleared: (signReq: string) =>
      `File is clear to close the following are required to sign ${signReq}`,
    settlementStatement: (Cash: string) =>
      `The Settlement statement has been delivered. Please send us a message and new Closing Instructions if modifications are needed.Cash due to/from borrower  ${Cash}`,
  };
  return templates[key] ? templates[key](...args) : "Key not found";
}

export const statusStringsConstants: StatusStringsConstantsProps = {
  importantNotes:
    "Welcome to Stewart Title! Please utilize the Obtain Fee quote or Place order buttons to get started",
  "Order Confirmed": (...args: any[]) =>
    dynamicString("OrderConfirmedMessage", ...args),
  "Title Delivered": (...args: any[]) =>
    dynamicString("titleDelivered", ...args),
  "Curative Cleared": (...args: any[]) =>
    dynamicString("curativeCleared", ...args),
  "Settlement Statement Complete": (...args: any[]) =>
    dynamicString("settlementStatement", ...args),
  "Closing Complete": "The signing event has been completed for this order",
  "Loan Funded":
    "This order has funded. All checks and wires have been disbursed. Please review the disbursement summary for additional details",
  "Final Policy": "Final Policy has been issued",
  "Commitment Completed": (...args: any[]) =>
    dynamicString("titleDelivered", ...args),
};

export const Messages: Record<string, string> = {
  AcceptedLoanPurpose:
    "The loan purpose must be a Purchase, Cash-Out Refinance, No Cash-Out Refinance, or Construction.",
  OfficeLocationRequired: "Office Location is required.",
  ValidSelection: "Please select appropriate option to proceed with order.",
  selectvalidOptions: "Please select appropriate option to proceed with order.",
  // OrderConfirmedMessage:
  // "Thank you for your order. Please Click the 'Get Order Updates' button to receive status, document, and message updates.",
  cancelOrderMessage:
    "Your cancellation request has been submitted. Please visit the Status tab for updates.",
};

export const LenderCTSContract: Record<string, number> = {
  NoCTSContract: 0,
  CTSPurchaseOnly: 1,
  CTSRefinanceOnly: 2,
  CTSPurchaseAndRefinance: 3,
} as const;

export const ApiPathConsts: Record<string, string> = {
  orderStatus: "orderStatus",
  placeOrder: "placeOrder",
  getLenderSettings: "getLenderSettings",
  validateAddress: "validateAddress",
  validateZipcode: "validateZipcode",
  sendNotes: "sendNotes",
  updateTransaction: "updateTransaction",
  getStatusList: "getStatusList",
  cancelOrder: "cancelOrder",
  revocation: "revocation",
  getSystemAttributes: "getSystemAttributes",
  getCounty: "getCounty",
  getProviders: "getProviders",
  getPricingPolicyInsuredTypes: "getPolicyInsuredTypes",
  stateSettings: "stateSettings",
  importFees: "importFees",
} as const;
export const HelocOptions = {
  Off: "0",
  InsuredSelected: "1",
  UninsuredSelected: "2",
} as const;

export const Resonoptions = [
  { code: "Application denied", description: "Application denied" },
  { code: "Apllication Withdrwan", description: "Apllication Withdrwan" },
  {
    code: "File Closed for incompleteness",
    description: "File Closed for incompleteness",
  },
  {
    code: "Preapproved request denied by FI",
    description: "Preapproved request denied by FI",
  },
  {
    code: "Request Approved but not accpeted",
    description: "Request Approved but not accpeted",
  },
  {
    code: "Three day right of recession (Right to Rescind)",
    description: "Three day right of recession (Right to Rescind)",
  },
  { code: "Other", description: "Other" },
];

export const StewartConstants: StewartConstantsProps = {
  StewartCompanyID: "STW987654321",
  ClientReferenceNumber: "ClientReferenceNumber",
  DocumentList: "DocumentList",
  DocumentReferenceNumberPrefix: "Stewart",
  SaveLoginInformation: "SaveLoginInformation",
  LoginUserName: "LoginUserName",
  // LoginPassword: "LoginPassword",
  IV: "IV",
  IV_Value: "hOrSLFl3Wgqf2xB+58tqow==",
  StatusList: "StatusList",
  StewartContextID: "StewartContextID",
  IsCancelled: "IsCancelled",
  Success: "success",
  TestUser: "testuser01",
  SelectedAgentID: "SelectedAgentID",
  SelectedOfficeName: "SelectedOfficeName",
  SelectedOfficeAddress1: "SelectedOfficeAddress1",
  SelectedOfficeAddress2: "SelectedOfficeAddress2",
  SelectedOfficeCity: "SelectedOfficeAddressCity",
  SelectedOfficeState: "SelectedOfficeAddressState",
  SelectedOfficePostalCode: "SelectedOfficeAddressPostalCode",
  SelectedOfficePhone: "SelectedOfficePhone",
  SelectedOfficeEmailAdress: "SelectedOfficeEmailAddress",
  IsOfficeAddressSaved: "IsOfficeAddressSaved",
  LenderContractID: "LenderContractID",
  IsCTS: "IsCTS",
  MessageList: "MessageList",
  PayoffList: "PayoffList",
  StewartFileNumber: "StewartFileNumber",
  FNCFileNumber: "FNCFileNumber",
  StewartRateCalculator_LenderRole: 1,
  StewartRateCalculator_LoanEstimate: 3,
  RecordingFeesLineNumber: "1202",
  TransferTaxesLineNumber: "1203",
  CityCountyTransferTaxesLineNumber: "1204",
  StateTransferTaxesLineNumber: "1205",
  AdditionalTransferTaxesLineNumber: "1206",
  AdditionalTransferTaxesSecondLineNumber: "1207",
  AdditionalTransferTaxesThirdLineNumber: "1208",
  AdditionalTransferTaxesFourthLineNumber: "1209",
  RecordingFeeDeedDescription: "Deed - Recording Fees",
  RecordingFeeMortgageDescription: "Mortgage - Recording Fees",
  RecordingFeeReleaseDescription: "Release - Recording Fees",
  CityTransferTaxesDeedDescription: "Deed - City Transfer Tax",
  CityTransferTaxesMortgageDescription: "Mortgage - City Transfer Tax",
  CountyTransferTaxesDeedDescription: "Deed - County Transfer Tax",
  CountyTransferTaxesMortgageDescription: "Mortgage - County Transfer Tax",
  StateTransferTaxesDeedDescription: "Deed - State Transfer Tax",
  StateTransferTaxesMortgageDescription: "Mortgage - State Transfer Tax",
  FLAdditionalTransferTaxesMortgageDescription: "Mortgage - Intangible Tax",
  MNAdditionalTransferTaxesMortgageDescription: "Mortgage - Conservation Fund",
  MNAdditionalTransferTaxesDeedDescription: "Deed - Conservation Fund",
  MDAdditionalTransferTaxesDeedDescription: "Deed Recordation Tax - State",
  MDAdditionalTransferTaxesMortgageDescription:
    "Mortgage Recordation Tax - State",
  VAAdditionalTransferTaxesGrantorStateDescription: "Grantor - State",
  VAAdditionalTransferTaxesGrantorCountyDescription: "Grantor - County",
  VAAdditionalTransferTaxesGrantorCityDescription: "Grantor - City",
  VAAdditionalTransferTaxesGranteeStateDescription: "Grantee - State",
  VAAdditionalTransferTaxesGranteeCountyDescription: "Grantee - County",
  VAAdditionalTransferTaxesGranteeCityDescription: "Grantee - City",
  SettlementFeeDescription: "Settlement Fee",
  ClosingFeeDescription: "Closing Fee",
  EscrowFeeDescription: "Escrow Fee",
  ClosingProtectionLetterDescription: "Closing Protection Letter",
  CPLDescription: "CPL",
  InsuredClosingLetterDescription: "Insured Closing Letter",
  CAAffordableHousingFeeDescription:
    "Title S B2 Affordable Housing Recording Fee",
  TotalSettlementServiceProviderListItems: 10,
  PendingPlaceOrderStatus: "Pending (Place Order)",
  PendingFileNumberStatus: "Pending (Escrow File Number)",
  SuccessStatus: "Success",
  OrderConfirmedStatus: "Order Confirmed",
  Yes: "Y",
  PayoffTypeDemand: "Demand",
  PayoffTypeSubordination: "Subordination",
  CTSOfficeHeading: "Stewart Centralized Title Services",
  FNC_ORDER_NOTE_SUBJECT: "FNC FILE NUMBER:",
} as const;

export const requiredFieldIds: string[] = [
  "loanNumber",
  "baseLoanAmount",
  "loanPurpose",
  "purchasePriceAmount",
  "propertyStreet",
  "propertyCity",
  "propertyState",
  "propertyZipCode",
  "borrowerFirstName",
  "borrowerLastName",
  "borrowerAddress",
  "borrowerCity",
  "borrowerState",
  "borrowerZipCode",
];

export const coBorrowerFieldIds: string[] = [
  "coBorrowerFirstName",
  "coBorrowerMiddleName",
  "coBorrowerLastName",
  "coBorrowerSuffix",
  "coBorrowerPhoneNumber",
  "coBorrowerWorkNumber",
  "coBorrowerCellNumber",
  "coBorrowerEmailAddress",
  "coBorrowerMaritalStatus",
  "coBorrowerAddress",
  "coBorrowerCity",
  "coBorrowerState",
  "coBorrowerZipCode",
];
export const RichTexts: Record<string, string> = {
  orderprocessing: `Thank you for your request. We will begin processing your order shortly.

    Click ‘Get Order Updates’ later to see your file number and/or receive updates to the order. 
    If you do not receive your file number within 2 - 4 hours, contact the selected office or Stewart Customer Care at (855)330-2800 for assistance.`,
  SubmissionResultsRichTextBox:
    "Welcome back! Please Click the 'Get Order Updates' button to receive status, document, and message updates.",

  PendingNotes: "Pending message will be sent when Place Order is selected.",
  MessageSent: "Message has been sent.",
  cancelOrder:
    "Your cancellation request has been submitted. Please visit the Status tab for updates.",
} as const;
export const RadioConsts: Record<string, any> = {
  isHelocRadios: ["Insured", "UnInsured"],
  isConstruction: ["Purchase", "Refinance"],
  isReverseOrder: ["Reverse"],
  isSigningOnlyOrder: ["Signing Only"],
} as const;

export const borrowerJsonPaths: string[] = [
  "$.applications[0].borrower.residences[?(@.residencyType == 'Current')][0].addressStreetLine1",
  "$.applications[0].borrower.residences[?(@.residencyType == 'Current')][0].addressCity",
  "$.applications[0].borrower.residences[?(@.residencyType == 'Current')][0].addressState",
  "$.applications[0].borrower.residences[?(@.residencyType == 'Current')][0].addressPostalCode",
];
export const coBorrowerJsonPaths: string[] = [
  "$.applications[0].coborrower.residences[?(@.residencyType == 'Current')][0].addressStreetLine1",
  "$.applications[0].coborrower.residences[?(@.residencyType == 'Current')][0].addressCity",
  "$.applications[0].coborrower.residences[?(@.residencyType == 'Current')][0].addressState",
  "$.applications[0].coborrower.residences[?(@.residencyType == 'Current')][0].addressPostalCode",
];

export const documentBodyTableCols: string[] = [
  "",
  "Name",
  "Date",
  "Status",
  "Click to View",
  "Download",
];

export const payOffGridCols: string[] = [
  "",
  "Type",
  "Lender Name",
  "Lender Phone/Fax",
  "Loan Amount",
  "Loan Number",
];

export const escrowContacts = {
  city: "",
  phone: "",
  state: "",
  address: "",
  postalCode: "",
  contactType: "ESCROW_COMPANY",
  referenceNumber: "",
};

export const titleContacts = {
  city: "",
  phone: "",
  state: "",
  address: "",
  postalCode: "",
  contactType: "TITLE_COMPANY",
  referenceNumber: "",
};

export const EncompassFieldIDs: Record<string, string> = {
  BorrowerFirstName: "4000",
  BorrowerMiddleName: "4001",
  BorrowerLastName: "4002",
  BorrowerSuffix: "4003",
  BorrowerPhoneNumber: "66",
  BorrowerWorkNumber: "FE0117",
  BorrowerCellNumber: "1490",
  BorrowerEmailAddress: "1240",
  BorrowerMaritalStatus: "52",
  BorrowerAddress: "FR0104",
  BorrowerCity: "FR0106",
  BorrowerState: "FR0107",
  BorrowerZipCode: "FR0108",
  BorrowerNumberOfYears: "FR0112",
  BorrowerNumberOfMonths: "FR0124",
  BorrowerOwnOrRent: "FR0115",
  BorrowerTaxIdentificationNumber: "65",
  BorrowerFinalVesting: "1867",

  CoBorrowerFirstName: "4004",
  CoBorrowerMiddleName: "4005",
  CoBorrowerLastName: "4006",
  CoBorrowerSuffix: "4007",
  CoBorrowerPhoneNumber: "98",
  CoBorrowerWorkNumber: "FE0217",
  CoBorrowerCellNumber: "1480",
  CoBorrowerEmailAddress: "1268",
  CoBorrowerMaritalStatus: "84",
  CoBorrowerAddress: "FR0204",
  CoBorrowerCity: "FR0206",
  CoBorrowerState: "FR0207",
  CoBorrowerZipCode: "FR0208",
  CoBorrowerNumberOfYears: "FR0212",
  CoBorrowerNumberOfMonths: "FR0224",
  CoBorrowerOwnOrRent: "FR0215",
  CoBorrowerTaxIdentificationNumber: "97",

  PropertyStreet: "11",
  PropertyStreetUrla20: "URLA.X73",
  PropertyUnitUrla20: "URLA.X75",

  PropertyCity: "12",
  PropertyCounty: "13",
  PropertyState: "14",
  PropertyZipCode: "15",
  PropertyLegalDescription: "17",
  ParcelID: "1894",
  PropertyLegalDescriptionInfo: "1846",

  LenderClientName: "1264",
  LenderFirstName: "1256",
  LenderLastName: "1256",
  LenderPhoneNumber: "1262",
  LenderFaxNumber: "1263",
  LenderEmailAddress: "1407",

  LoanPurpose: "19",
  // LoanProduct:"",
  ClientFileNumber: "305",
  SalePrice: "136",
  LoanAmount: "2",
  LoanNumber: "364",
  EstimatedSettlementDate: "763",
  ApplicationDate: "745",
  ClosingDate: "748",
  TransactionType: "19",
  LoanType: "1172",
  //US 97524
  LienPriorityType: "420",
  MailingAddressStreet: "1416",
  MailingAddressCity: "1417",
  MailingAddressState: "1418",
  MailingAddressZipCode: "1419",

  EscrowCompanyName: "610",
  EscrowAddress: "612",
  EscrowCity: "613",
  EscrowState: "1175",
  EscrowZip: "614",
  EscrowCaseNumber: "186",
  EscrowPhone: "615",

  TitleInsuranceCompanyName: "411",
  TitleInsuranceAddress: "412",
  TitleInsuranceCity: "413",
  TitleInsuranceState: "1174",
  TitleInsuranceZip: "414",
  TitleInsuranceCaseNumber: "187",
  TitleInsurancePhone: "417",

  SettlementAgentCompanyName: "VEND.X655",
  SettlementAgentAddress: "VEND.X656",
  SettlementAgentCity: "VEND.X657",
  SettlementAgentState: "VEND.X658",
  SettlementAgentZip: "VEND.X659",
  SettlementAgentPhone: "VEND.X669",

  HUDLine1101Payee: "NEWHUD.X202",

  HUDLine1101aDescription: "NEWHUD.X951",
  HUDLine1101aBuyerAmount: "NEWHUD.X952",
  HUDLine1101aSellerAmount: "NewHUD.X953",
  HUDLine1101aPayee: "NEWHUD.X1070",
  HUDLine1101aBuyerCanShop: "NEWHUD2.X2866",
  HUDLine1101bDescription: "NEWHUD.X960",
  HUDLine1101bBuyerAmount: "NEWHUD.X961",
  HUDLine1101bSellerAmount: "NEWHUD.X962",
  HUDLine1101bPayee: "NEWHUD.X1071",
  HUDLine1101bBuyerCanShop: "NEWHUD2.X2899",
  HUDLine1101cDescription: "NEWHUD.X969",
  HUDLine1101cBuyerAmount: "NEWHUD.X970",
  HUDLine1101cSellerAmount: "NEWHUD.X971",
  HUDLine1101cPayee: "NEWHUD.X1072",
  HUDLine1101cBuyerCanShop: "NEWHUD2.X2932",
  HUDLine1101dDescription: "NEWHUD.X978",
  HUDLine1101dBuyerAmount: "NEWHUD.X979",
  HUDLine1101dSellerAmount: "NEWHUD.X980",
  HUDLine1101dPayee: "NEWHUD.X1073",
  HUDLine1101dBuyerCanShop: "NEWHUD2.X2965",
  HUDLine1101eDescription: "NEWHUD.X987",
  HUDLine1101eBuyerAmount: "NEWHUD.X988",
  HUDLine1101eSellerAmount: "NEWHUD.X989",
  HUDLine1101ePayee: "NEWHUD.X1074",
  HUDLine1101eBuyerCanShop: "NEWHUD2.X2998",
  HUDLine1101fDescription: "NEWHUD.X996",
  HUDLine1101fBuyerAmount: "NEWHUD.X997",
  HUDLine1101fSellerAmount: "NEWHUD.X998",
  HUDLine1101fPayee: "NEWHUD.X1075",
  HUDLine1101fBuyerCanShop: "NEWHUD2.X3031",

  HUDLine1102aBuyerAmount: "NEWHUD2.X11",
  HUDLine1102aSellerAmount: "NEWHUD2.X12",
  HUDLine1102aPayee: "NEWHUD.X203",
  HUDLine1102aBuyerCanShop: "NEWHUD2.X3064",
  HUDLine1102bBuyerAmount: "NEWHUD2.X14",
  HUDLine1102bSellerAmount: "NEWHUD2.X15",
  HUDLine1102bPayee: "NEWHUD2.X13",
  HUDLine1102bBuyerCanShop: "NEWHUD2.X3097",
  HUDLine1102cBuyerAmount: "NEWHUD.X808",
  HUDLine1102cSellerAmount: "NEWHUD2.X17",
  HUDLine1102cPayee: "NEWHUD2.X16",
  HUDLine1102cBuyerCanShop: "NEWHUD2.X3130",
  HUDLine1102dDescription: "NEWHUD.X809",
  HUDLine1102dBuyerAmount: "NEWHUD.X810",
  HUDLine1102dSellerAmount: "NEWHUD2.X18",
  HUDLine1102dPayee: "NEWHUD2.X55",
  HUDLine1102dBuyerCanShop: "NEWHUD2.X3163",
  HUDLine1102eDescription: "NEWHUD.X811",
  HUDLine1102eBuyerAmount: "NEWHUD.X812",
  HUDLine1102eSellerAmount: "NEWHUD2.X19",
  HUDLine1102ePayee: "NEWHUD2.X56",
  HUDLine1102eBuyerCanShop: "NEWHUD2.X3196",
  HUDLine1102fDescription: "NEWHUD.X813",
  HUDLine1102fBuyerAmount: "NEWHUD.X814",
  HUDLine1102fSellerAmount: "NEWHUD2.X20",
  HUDLine1102fPayee: "NEWHUD2.X57",
  HUDLine1102fBuyerCanShop: "NEWHUD2.X3229",
  HUDLine1102gDescription: "NEWHUD.X815",
  HUDLine1102gBuyerAmount: "NEWHUD.X816",
  HUDLine1102gSellerAmount: "NEWHUD2.X21",
  HUDLine1102gPayee: "NEWHUD2.X58",
  HUDLine1102gBuyerCanShop: "NEWHUD2.X3262",
  HUDLine1102hDescription: "NEWHUD.X817",
  HUDLine1102hBuyerAmount: "NEWHUD.X818",
  HUDLine1102hSellerAmount: "NEWHUD2.X22",
  HUDLine1102hPayee: "NEWHUD2.X59",
  HUDLine1102hBuyerCanShop: "NEWHUD2.X3295",

  HUDLine1103BuyerAmount: "NEWHUD.X572",
  HUDLine1103SellerAmount: "NewHUD.X783",
  HUDLine1103Payee: "NEWHUD.X204",
  HUDLine1104BuyerAmount: "NEWHUD.X639",
  HUDLine1104SellerAmount: "NewHUD.X784",
  HUDLine1104Payee: "NEWHUD.X205",
  HUDLine1109Description: "NewHUD.X208",
  HUDLine1109BuyerAmount: "NewHUD.X215",
  HUDLine1109SellerAmount: "NewHUD.X218",
  HUDLine1109Payee: "NEWHUD.X1076",
  HUDLine1109BuyerCanShop: "NEWHUD2.X3394",
  HUDLine1110Description: "NewHUD.X209",
  HUDLine1110BuyerAmount: "NewHUD.X216",
  HUDLine1110SellerAmount: "NewHUD.X219",
  HUDLine1110Payee: "NEWHUD.X1077",
  HUDLine1110BuyerCanShop: "NEWHUD2.X3427",
  HUDLine1111Description: "1762",
  HUDLine1111BuyerAmount: "1763",
  HUDLine1111SellerAmount: "1764",
  HUDLine1111Payee: "NEWHUD.X1078",
  HUDLine1111BuyerCanShop: "NEWHUD2.X3460",
  HUDLine1112Description: "1767",
  HUDLine1112BuyerAmount: "1768",
  HUDLine1112SellerAmount: "1769",
  HUDLine1112Payee: "NEWHUD.X1079",
  HUDLine1112BuyerCanShop: "NEWHUD2.X3493",
  HUDLine1113Description: "1772",
  HUDLine1113BuyerAmount: "1773",
  HUDLine1113SellerAmount: "1774",
  HUDLine1113Payee: "NEWHUD.X1080",
  HUDLine1113BuyerCanShop: "NEWHUD2.X3526",
  HUDLine1114Description: "1777",
  HUDLine1114BuyerAmount: "1778",
  HUDLine1114SellerAmount: "1779",
  HUDLine1114Payee: "NEWHUD.X1081",
  HUDLine1114BuyerCanShop: "NEWHUD2.X3559",
  HUDLine1115Description: "NEWHUD.X1602",
  HUDLine1115BuyerAmount: "NEWHUD.X1604",
  HUDLine1115SellerAmount: "NEWHUD.X1605",
  HUDLine1115Payee: "NEWHUD.X1603",
  HUDLine1116Description: "NEWHUD.X1610",
  HUDLine1116BuyerAmount: "NEWHUD.X1612",
  HUDLine1116SellerAmount: "NEWHUD.X1613",
  HUDLine1116Payee: "NEWHUD.X1611",

  HUDLine1202DeedAmount: "2402",
  HUDLine1202MortgageAmount: "2403",
  HUDLine1202ReleaseAmount: "2404",
  HUDLine1202Description: "1636",
  HUDLine1202BuyerAmount: "390",
  HUDLine1202SellerAmount: "587",
  HUDLine1204DeedAmount: "2405",
  HUDLine1204MortgageAmount: "2406",
  HUDLine1203Description: "NEWHUD.X947",
  HUDLine1203BuyerAmount: "NEWHUD.X731",
  HUDLine1203SellerAmount: "NEWHUD.X787",
  HUDLine1204Description: "1637",
  HUDLine1204BuyerAmount: "647",
  HUDLine1204SellerAmount: "593",
  HUDLine1205DeedAmount: "2407",
  HUDLine1205MortgageAmount: "2408",
  HUDLine1205Description: "1638",
  HUDLine1205BuyerAmount: "648",
  HUDLine1205SellerAmount: "594",
  HUDLine1206Description: "373",
  HUDLine1206BuyerAmount: "374",
  HUDLine1206SellerAmount: "576",
  HUDLine1206Payee: "NEWHUD.X1082",
  HUDLine1207Description: "1640",
  HUDLine1207BuyerAmount: "1641",
  HUDLine1207SellerAmount: "1642",
  HUDLine1207Payee: "NEWHUD.X1083",
  HUDLine1208Description: "1643",
  HUDLine1208BuyerAmount: "1644",
  HUDLine1208SellerAmount: "1645",
  HUDLine1208Payee: "NEWHUD.X1084",
  HUDLine1209Description: "NEWHUD.X1618",
  HUDLine1209BuyerAmount: "NEWHUD.X1620",
  HUDLine1209SellerAmount: "NEWHUD.X1621",
  HUDLine1209Payee: "NEWHUD.X1619",

  HUDLine1303Description: "650",
  HUDLine1303Payee: "NEWHUD.X1086",
  HUDLine1303BuyerAmount: "644",
  HUDLine1303SellerAmount: "590",

  HUDLine1305Description: "40",
  HUDLine1305Payee: "NEWHUD.X1088",
  HUDLine1305BuyerAmount: "41",
  HUDLine1305SellerAmount: "42",

  HUDLine1306Description: "43",
  HUDLine1306Payee: "NEWHUD.X1089",
  HUDLine1306BuyerAmount: "44",
  HUDLine1306SellerAmount: "55",

  HUDLine1307Description: "1782",
  HUDLine1307Payee: "NEWHUD.X1090",
  HUDLine1307BuyerAmount: "1783",
  HUDLine1307SellerAmount: "1784",

  HUDLine1308Description: "1787",
  HUDLine1308Payee: "NEWHUD.X1091",
  HUDLine1308BuyerAmount: "1788",
  HUDLine1308SellerAmount: "1789",

  HUDLine1309Description: "1792",
  HUDLine1309Payee: "NEWHUD.X1092",
  HUDLine1309BuyerAmount: "1793",
  HUDLine1309SellerAmount: "1794",

  HUDLine1310Description: "NEWHUD.X252",
  HUDLine1310Payee: "NEWHUD.X1093",
  HUDLine1310BuyerAmount: "NEWHUD.X255",
  HUDLine1310SellerAmount: "NEWHUD.X259",

  HUDLine1312Description: "NEWHUD.X1632",
  HUDLine1312Payee: "NEWHUD.X1633",
  HUDLine1312BuyerAmount: "NEWHUD.X1634",
  HUDLine1312SellerAmount: "NEWHUD.X1635",

  HUDLine1313Description: "NEWHUD.X1640",
  HUDLine1313Payee: "NEWHUD.X1641",
  HUDLine1313BuyerAmount: "NEWHUD.X1642",
  HUDLine1313SellerAmount: "NEWHUD.X1643",

  HUDLine1314Description: "NEWHUD.X1648",
  HUDLine1314Payee: "NEWHUD.X1649",
  HUDLine1314BuyerAmount: "NEWHUD.X1650",
  HUDLine1314SellerAmount: "NEWHUD.X1651",

  HUDLine1315Description: "NEWHUD.X1656",
  HUDLine1315Payee: "NEWHUD.X1657",
  HUDLine1315BuyerAmount: "NEWHUD.X1658",
  HUDLine1315SellerAmount: "NEWHUD.X1659",

  HUDLine1316Description: "NEWHUD2.X4610",
  HUDLine1316Payee: "NEWHUD2.X4611",
  HUDLine1316BuyerAmount: "NEWHUD2.X4612",
  HUDLine1316SellerAmount: "NEWHUD2.X4613",

  HUDLine1317Description: "NEWHUD2.X4617",
  HUDLine1317Payee: "NEWHUD2.X4618",
  HUDLine1317BuyerAmount: "NEWHUD2.X4619",
  HUDLine1317SellerAmount: "NEWHUD2.X4620",

  HUDLine1318Description: "NEWHUD2.X4624",
  HUDLine1318Payee: "NEWHUD2.X4625",
  HUDLine1318BuyerAmount: "NEWHUD2.X4626",
  HUDLine1318SellerAmount: "NEWHUD2.X4627",

  HUDLine1319Description: "NEWHUD2.X4631",
  HUDLine1319Payee: "NEWHUD2.X4632",
  HUDLine1319BuyerAmount: "NEWHUD2.X4633",
  HUDLine1319SellerAmount: "NEWHUD2.X4634",

  HUDLine1320Description: "NEWHUD2.X4638",
  HUDLine1320Payee: "NEWHUD2.X4639",
  HUDLine1320BuyerAmount: "NEWHUD2.X4640",
  HUDLine1320SellerAmount: "NEWHUD2.X4641",

  SettlementServiceProviderDescription1: "SP{0}17",
  SettlementServiceProviderDescription2: "SP{0}19",
  SettlementServiceProviderDescription3: "SP{0}21",
  SettlementServiceProviderDescription4: "SP{0}23",
  SettlementServiceProviderDescription5: "SP{0}25",
  SettlementServiceProviderDescription6: "SP{0}27",
  SettlementServiceProviderDescription7: "SP{0}29",
  SettlementServiceProviderDescription8: "SP{0}31",
  SettlementServiceProviderDescription9: "SP{0}33",
  SettlementServiceProviderDescription10: "SP{0}35",

  SettlementServiceProviderFee1: "SP{0}18",
  SettlementServiceProviderFee2: "SP{0}20",
  SettlementServiceProviderFee3: "SP{0}22",
  SettlementServiceProviderFee4: "SP{0}24",
  SettlementServiceProviderFee5: "SP{0}26",
  SettlementServiceProviderFee6: "SP{0}28",
  SettlementServiceProviderFee7: "SP{0}30",
  SettlementServiceProviderFee8: "SP{0}32",
  SettlementServiceProviderFee9: "SP{0}34",
  SettlementServiceProviderFee10: "SP{0}36",

  ServiceProviderCompanyCategory: "SP{0}01",
  ServiceProviderCompanyName: "SP{0}02",
  ServiceProviderAddress: "SP{0}03",
  ServiceProviderCity: "SP{0}04",
  ServiceProviderState: "SP{0}05",
  ServiceProviderZipCode: "SP{0}06",
  ServiceProviderPhone: "SP{0}07",
  ServiceProviderEmail: "SP{0}15",

  PayOffLenderName: "FL{0}02",
  PayOffAccountType: "FL{0}08",
  PayOffAccountNumber: "FL{0}10",
  PayOffBalance: "FL{0}13",
  PayoffToBePaidOff: "FL{0}18",
  PayOffPhone: "FL{0}20",
  PayOffFax: "FL{0}21",

  BorrowerVestingName: "1859",
  BorrowerVestingState: "1860",
  BorrowerVestingType: "1861",
  BorrowerVestingTaxID: "1862",

  BorrowerVesting2Name: "Vesting.Trst2Name",
  BorrowerVesting2State: "Vesting.Trst2State",
  BorrowerVesting2Type: "Vesting.Trst2Type",
  BorrowerVesting2TaxID: "Vesting.Trst2TaxID",
};

export const API_URL = process.env.REACT_APP_ECP_API_URL as string;
