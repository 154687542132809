import { StewartConstants } from "constants/strings";
import { PriceQuote, SettlementServiceProviderFee } from "./priceQuotes";
import {
  getAmount,
  getEndorsementAmount,
  getEndorsementNode,
  getTitleFeeAmount,
  getTitleProps,
  getValueFromJson,
  loadEndorsements,
} from "./utils";

export const addSettlementServiceProviderFees = (
  priceQuote: PriceQuote,
  jsonData: any,
  endorsementTypes: string[],
  shouldPickTRIDValues: boolean,
  LenderSettings: any
) => {
  const titleFees = getValueFromJson(
    jsonData,
    "/Pricing/TitleServiceFee/ItemizedTitleServiceFeeList/ItemizedTitleServiceFee"
  );

  let amount = 0,
    buyerAmount = 0,
    sellerAmount = 0,
    feeName = "";

  const ownerTRIDPremiumAmount: number = getAmount(
    // Use getAmount for numeric values
    getValueFromJson(jsonData, "/Pricing/TRID/TRIDOwnerPolicy/Premium")
  );

  if (ownerTRIDPremiumAmount !== 0 && shouldPickTRIDValues) {
    buyerAmount =
      getAmount(
        getValueFromJson(
          jsonData,
          "/Pricing/TRID/TRIDOwnerPolicy/Splits/Buyer/Premium"
        )
      ) +
      getAmount(
        getValueFromJson(
          jsonData,
          "/Pricing/TRID/TRIDOwnerPolicy/Splits/Buyer/SalesTax"
        )
      );

    if (buyerAmount !== 0) {
      const SSPFee: SettlementServiceProviderFee = {
        BuyerAmount: buyerAmount,
        Description: "TRID - Owner's Policy",
      };

      priceQuote.SettlementServiceProviderFeeList.push(SSPFee);
    }
  } else {
    const ownerPremiumAmount: number = getAmount(
      // Use getAmount for numeric values
      getValueFromJson(jsonData, "/Pricing/RateManual/OwnerPolicy/Premium")
    );

    if (ownerPremiumAmount !== 0) {
      buyerAmount =
        getAmount(
          getValueFromJson(
            jsonData,
            "/Pricing/RateManual/OwnerPolicy/Splits/Buyer/Premium"
          )
        ) +
        getAmount(
          getValueFromJson(
            jsonData,
            "/Pricing/RateManual/OwnerPolicy/Splits/Buyer/SalesTax"
          )
        ) -
        getAmount(
          getValueFromJson(
            jsonData,
            "/Pricing/RateManual/OwnerPolicy/Splits/Buyer/TitleServiceCharge"
          )
        );

      if (buyerAmount !== 0) {
        const SSPFee: SettlementServiceProviderFee = {
          BuyerAmount: buyerAmount,
          Description: "Owner's Policy",
        };

        priceQuote.SettlementServiceProviderFeeList.push(SSPFee);
      }
    }
  }
  const lenderTRIDPremiumAmount: number = getAmount(
    // Use getAmount for numeric values
    getValueFromJson(jsonData, "/Pricing/TRID/TRIDLenderPolicy/Premium")
  );

  if (lenderTRIDPremiumAmount !== 0 && shouldPickTRIDValues) {
    buyerAmount =
      getAmount(
        getValueFromJson(
          jsonData,
          "/Pricing/TRID/TRIDLenderPolicy/Splits/Buyer/Premium"
        )
      ) +
      getAmount(
        getValueFromJson(
          jsonData,
          "/Pricing/TRID/TRIDLenderPolicy/Splits/Buyer/SalesTax"
        )
      );

    if (buyerAmount !== 0) {
      const SSPFee: SettlementServiceProviderFee = {
        BuyerAmount: buyerAmount,
        Description: "TRID - Lender's Policy",
      };

      priceQuote.SettlementServiceProviderFeeList.push(SSPFee);
    }
  } else {
    const lenderPremiumAmount: number =
      getAmount(
        getValueFromJson(jsonData, "/Pricing/RateManual/LenderPolicy/Premium")
      ) +
      getAmount(
        getValueFromJson(jsonData, "/Pricing/RateManual/LenderPolicy/SalesTax")
      ) -
      getAmount(
        getValueFromJson(
          jsonData,
          "/Pricing/RateManual/LenderPolicy/TitleServiceCharge"
        )
      );

    if (lenderPremiumAmount !== 0) {
      buyerAmount =
        getAmount(
          getValueFromJson(
            jsonData,
            "/Pricing/RateManual/LenderPolicy/Splits/Buyer/Premium"
          )
        ) +
        getAmount(
          getValueFromJson(
            jsonData,
            "/Pricing/RateManual/LenderPolicy/Splits/Buyer/SalesTax"
          )
        ) -
        getAmount(
          getValueFromJson(
            jsonData,
            "/Pricing/RateManual/LenderPolicy/Splits/Buyer/TitleServiceCharge"
          )
        );

      let sellerSplit: number =
        getAmount(
          getValueFromJson(
            jsonData,
            "/Pricing/RateManual/LenderPolicy/Splits/Seller/Premium"
          )
        ) +
        getAmount(
          getValueFromJson(
            jsonData,
            "/Pricing/RateManual/LenderPolicy/Splits/Seller/SalesTax"
          )
        ) -
        getAmount(
          getValueFromJson(
            jsonData,
            "/Pricing/RateManual/LenderPolicy/Splits/Seller/TitleServiceCharge"
          )
        );

      if (buyerAmount === 0 && sellerSplit === 0) {
        buyerAmount = lenderPremiumAmount;
      }

      const SSPFee: SettlementServiceProviderFee = {
        BuyerAmount: buyerAmount,
        Description: "Lender's Policy",
      };

      priceQuote.SettlementServiceProviderFeeList.push(SSPFee);
    }
  }

  // Assuming 'titleFees' is an array of objects
  titleFees.forEach((titleFeeNode: any) => {
    feeName = titleFeeNode?.Name ?? "";
    const {
      Amount,
      SalesTax,
      BuyerAmount,
      SalesTaxBuyerAmount,
      SellerAmount,
      SalesTaxSellerAmount,
    } = getTitleProps(titleFeeNode);
    amount = getTitleFeeAmount(Amount, SalesTax); // Assuming these functions exist and are adapted to work with JSON
    buyerAmount = getTitleFeeAmount(BuyerAmount, SalesTaxBuyerAmount);
    sellerAmount = getTitleFeeAmount(SellerAmount, SalesTaxSellerAmount);

    if (buyerAmount === 0 && sellerAmount === 0) {
      buyerAmount = amount;
    }

    if (buyerAmount !== 0) {
      const SSPFee: SettlementServiceProviderFee = {
        BuyerAmount: buyerAmount,
        Description: "", // Initialize with an empty string
      };

      if (feeName.includes(StewartConstants.SettlementFeeDescription)) {
        SSPFee.Description = StewartConstants.SettlementFeeDescription;
      } else if (feeName.includes(StewartConstants.ClosingFeeDescription)) {
        SSPFee.Description = StewartConstants.ClosingFeeDescription;
      } else if (feeName.includes(StewartConstants.EscrowFeeDescription)) {
        SSPFee.Description = StewartConstants.EscrowFeeDescription;
      } else if (
        feeName.includes(StewartConstants.CAAffordableHousingFeeDescription)
      ) {
        SSPFee.Description = StewartConstants.CAAffordableHousingFeeDescription;
      } else {
        SSPFee.Description = feeName;
      }

      priceQuote.SettlementServiceProviderFeeList.push(SSPFee);
    }
  });

  for (const endorsementType of endorsementTypes) {
    let totalBuyerAmount = 0;
    const endorsements: any[] = loadEndorsements(jsonData, endorsementType);

    for (const endorsementNode of endorsements) {
      const {
        Premium,
        SalesTax,
        buyerPremium,
        buyerSalesTax,
        sellelrPremium,
        sellelrSalesTax,
        Name,
      } = getEndorsementNode(endorsementNode);
      feeName = Name;

      const amount = getEndorsementAmount(Premium, SalesTax);
      let buyerAmount = getEndorsementAmount(buyerPremium, buyerSalesTax);
      sellerAmount = getEndorsementAmount(sellelrPremium, sellelrSalesTax);

      if (buyerAmount === 0 && sellerAmount === 0) {
        buyerAmount = amount;
      }

      if (buyerAmount !== 0) {
        if (LenderSettings.ShowEndorsementsSeparately) {
          const SSPFee: SettlementServiceProviderFee = {};
          SSPFee.Description = feeName;
          SSPFee.BuyerAmount = buyerAmount;

          priceQuote.SettlementServiceProviderFeeList.push(SSPFee);
        } else if (!LenderSettings.ShowEndorsementsSeparately) {
          if (
            feeName
              .toUpperCase()
              .includes(
                StewartConstants.ClosingProtectionLetterDescription.toUpperCase()
              ) ||
            feeName
              .toUpperCase()
              .includes(
                StewartConstants.InsuredClosingLetterDescription.toUpperCase()
              ) ||
            feeName
              .toUpperCase()
              .includes(StewartConstants.CPLDescription.toUpperCase())
          ) {
            const SSPFee: SettlementServiceProviderFee = {};
            SSPFee.Description = feeName;
            SSPFee.BuyerAmount = buyerAmount;

            priceQuote.SettlementServiceProviderFeeList.push(SSPFee);
          } else {
            totalBuyerAmount += buyerAmount;
          }
        }
      }
    }

    if (totalBuyerAmount !== 0) {
      const SSPFee: SettlementServiceProviderFee = {};
      SSPFee.Description = `${endorsementType}'s Endorsement(s)`;
      SSPFee.BuyerAmount = totalBuyerAmount;

      priceQuote.SettlementServiceProviderFeeList.push(SSPFee);
    }
  }
};
