import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiPathConsts } from "constants/strings";
import { RootState, UpstreamRequestParams } from "interfaces/rootState";
import axiosEcpInstance from "lib/axiosEcp";
import { getFullUrl } from "utils/UrlMap";

export const fetchPolicyInsuredTypes = createAsyncThunk(
  "policyInsuredTypes/fetch",
  async (requestParams: UpstreamRequestParams, { rejectWithValue }) => {
    const path = getFullUrl(
      { queryParams: { ...requestParams } },
      ApiPathConsts.getPricingPolicyInsuredTypes
    );
    try {
      const response = await axiosEcpInstance.get(path);
      return response.data;
    } catch (error: any) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const fetchEndorsements = createAsyncThunk(
  "endorsements/fetch",
  async (state: string, { rejectWithValue }) => {
    try {
      const response = await axiosEcpInstance(
        `papi/endorsements?stateCode=${state}`
      );
      const endorsements = {
        lender: [] as any,
        owner: [] as any,
      };
      response.data?.Endorsements?.forEach((item: any) => {
        if (item.Type === "ENDMP") {
          endorsements.lender.push(item);
        } else if (item.Type === "ENDOP") {
          endorsements.owner.push(item);
        } else if (item.Type === "END") {
          endorsements.lender.push(item);
          endorsements.owner.push(item);
        }
      });
      return endorsements;
    } catch (error: any) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);
export const fetchTransactionTypes = createAsyncThunk(
  "transactiontypes/fetch",
  async (state: string, { rejectWithValue }) => {
    try {
      const response = await axiosEcpInstance(
        `papi/transactiontypes?stateCode=${state}`
      );

      return response.data;
    } catch (error: any) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);
export const fetchCounty = createAsyncThunk(
  "countyCodes/fetch",
  async (state: string, { rejectWithValue }) => {
    try {
      const response = await axiosEcpInstance(`papi/getCounty/${state}`);

      return response.data;
    } catch (error: any) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const fetchStateSettings = createAsyncThunk(
  "stateSettings/fetch",
  async (state: string, { rejectWithValue }) => {
    try {
      const response = await axiosEcpInstance(`papi/statesettings/${state}`);

      return response.data?.stateSettings || {};
    } catch (error: any) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const fetchRecordings = createAsyncThunk(
  "recordings/fetch",
  async (
    payload: { state: string; countyCode: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosEcpInstance(
        `papi/ernstLookup?stateCode=${payload.state}&countyCode=${payload.countyCode}`
      );

      return response.data?.Recording || {};
    } catch (error: any) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const fetchCoverageTypes_OP = createAsyncThunk(
  "policyCoverageTypes/fetch",
  async (
    payload: {
      state: string;
      transactionType: string;
      policyInsuredTypeCode: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosEcpInstance(
        `papi/coverageTypes?stateCode=${payload.state}&transactionType=${payload.transactionType}&policyInsuredTypeCode=${payload.policyInsuredTypeCode}`
      );

      return response.data?.coverageTypes || [];
    } catch (error: any) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const fetchCoverageTypes_MP = createAsyncThunk(
  "policyCoverageTypes_MP/fetch",
  async (
    payload: {
      state: string;
      transactionType: string;
      policyInsuredTypeCode: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosEcpInstance(
        `papi/coverageTypes?stateCode=${payload.state}&transactionType=${payload.transactionType}&policyInsuredTypeCode=${payload.policyInsuredTypeCode}`
      );

      return response.data?.coverageTypes || [];
    } catch (error: any) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const fetchQuoteResponse = createAsyncThunk(
  "quoteResponse/fetch",
  async (payload: Record<string, any>, { rejectWithValue }) => {
    try {
      const response = await axiosEcpInstance.post(
        "pricingQuoteRequest",
        payload
      );

      return response.data?.QuoteResponse || {};
    } catch (error: any) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

export const fetchGetFeeImport = createAsyncThunk(
  "feeImport/fetch",
  async (requestParams: string, { rejectWithValue }) => {
    const Path = getFullUrl(
      { queryParams: { clientRefId: requestParams } },
      ApiPathConsts.importFees
    );
    try {
      const response = await axiosEcpInstance.get(Path);
      return response.data;
    } catch (error: any) {
      console.error(error);
      return rejectWithValue(error);
    }
  }
);

// export const getFeeImport = createAsyncThunk(
//   "feeImport/fetch",
//   async (payload: string, { rejectWithValue }) => {
//     const Path = getFullUrl(
//       { queryParams: { clientRefId: payload } },
//       ApiPathConsts.importFees
//     );
//     try {
//       const response = await axiosEcpInstance.get(Path);

//       return response.data?.QuoteResponse || {};
//     } catch (error: any) {
//       console.error(error);
//       return rejectWithValue(error);
//     }
//   }
// );

export const TransactionUpObject: Record<string, string> = {
  refi: "Refinance",
  cash: "Sale/Purchase Cash",
  sale: "Sale/Purchase with Mortgage",
};

export const selectLoanData = (state: RootState) => {
  const {
    loan: { data: { loan: { property = {} } = {} } = {} } = {},
    loan: { options: Options = {} } = {},
    officeData: {
      officeData: {
        Stewart: { ContractInfo: { PricingGuid = "" } = {} } = {},
      } = {},
    } = {},
    loader: { loading },
    src: { slectedTransaction },
    policyInsuredTypes: {
      data: { PolicyInsuredTypeList: { PolicyInsuredType = [] } = {} } = {},
    },
    loan: { data: { loan: loanData = {} } = {} } = {},
    countyCode: { data: { CountyList = null } = {}, countyCode = "" } = {},
    coverageTypes_OP: { data: coverageTypes_op = [] } = {},
    coverageTypes_MP: { data: coverageTypes_mp = [] } = {},
    src: { selectedTransObject, selectedProviders },
    endorsements: {
      data: {
        lender: lenderEndorsements = [],
        owner: ownerEndorsements = [],
      } = {},
    },
    recordings: { data: { IndexT = {} } = {} },
    stateSettings: {
      data: {
        DefaultNumberOfPagesDeed = 0,
        DefaultNumberOfPagesMortgageRefi = 0,
        DefaultNumberOfPagesMortgageSale = 0,
        DefaultNumberOfPagesRelease = 0,
      } = {},
    },
  } = state || {};

  return {
    loanData,
    property,
    Options,
    PricingGuid,
    loading,
    slectedTransaction,
    PolicyInsuredType,
    countyCode,
    CountyList,
    coverageTypes_mp,
    coverageTypes_op,
    selectedTransObject,
    ProviderID: selectedProviders?.ID,
    ownerEndorsements:
      ownerEndorsements
        .filter((_: any) => _.IsPreselected === "true")
        .map(
          ({ EndorsementFeeItemCode }: { EndorsementFeeItemCode: string }) => ({
            EndorsementFeeItemCode,
          })
        ) || [],
    lenderEndorsements:
      lenderEndorsements
        .filter((_: any) => _.IsPreselected === "true")
        .map(
          ({ EndorsementFeeItemCode }: { EndorsementFeeItemCode: string }) => ({
            EndorsementFeeItemCode,
          })
        ) || [],
    PageRec: IndexT.PageRec || "",
    IsTorren: IndexT.ShowTorrens,
    DefaultNumberOfPagesDeed,
    DefaultNumberOfPagesMortgageRefi,
    DefaultNumberOfPagesMortgageSale,
    DefaultNumberOfPagesRelease,
  };
};
