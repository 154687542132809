import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Button,
  Box,
  FormControlLabel,
  Typography,
} from "@mui/material";

import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

const StyledTableCellHeader = styled(TableCell)(({ theme }) => ({
  color: "#212121", // Access color from theme
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "normal",
  borderBottom: "1px solid #BDBDBD",
  height: "38px",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "#212121", // Using theme for color
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  padding: "8px 16px",
  borderBottom: "1px solid #BDBDBD",
  height: "38px",
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  width: 20,
  height: 20,
  // Ensure a default border is set, even if it's transparent
  color: "#212121",

  "&.Mui-checked": {
    color: "#0075AA", // Active filled color
  },
  // Now override the border color when checked
  "&.Mui-checked + .MuiSvgIcon-root": {
    border: "1px solid red",
  },
}));

interface ImportFeeGridProps {
  rows: any[];
  onImportSelectedFees: (selectedIds: string[]) => void;
  onClose: () => void; // Handler for closing the component
}

const ImportFeeGrid = ({
  rows,
  onImportSelectedFees,
  onClose,
}: ImportFeeGridProps) => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [checkState, setCheckState] = useState({
    addFees: false,
    addStewartRate: false,
    addStewartOffice: false,
  });

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelectedIds = rows.map((n) => n.HudLineNumber);
      setSelectedIds(newSelectedIds);
      return;
    }
    setSelectedIds([]);
  };

  const handleClick = (id: string) => {
    const selectedIndex = selectedIds.indexOf(id);
    let newSelectedIds: any[] = [];

    if (selectedIndex === -1) {
      newSelectedIds = newSelectedIds.concat(selectedIds, id);
    } else if (selectedIndex >= 0) {
      newSelectedIds = newSelectedIds.concat(
        selectedIds.slice(0, selectedIndex),
        selectedIds.slice(selectedIndex + 1)
      );
    }

    setSelectedIds(newSelectedIds);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckState({ ...checkState, [event.target.name]: event.target.checked });
  };

  const isSelected = (id: string) => selectedIds.indexOf(id) !== -1;
  console.log(rows, "rowsss");
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 934,
        bgcolor: "background.paper",

        animation: "fade-in 0.3s",
        // overflowY: 'auto',
        "&:focus-visible": {
          outline: "none",
        },
        borderRadius: "12px",
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        sx={{
          py: 4,
          overflowY: "auto",
          "&:focus-visible": {
            outline: "none",
          },
          paddingX: "5%",
        }}
      >
        <Box
          bgcolor="#0075AA"
          display="flex"
          justifyContent="center"
          alignItems="center "
          height="56px"
          width="56px"
          sx={{
            borderRadius: "6px",
            padding: "16px 16.5px 16px 15.5px",
            marginRight: "24px",
          }}
        >
          <ExitToAppIcon sx={{ cursor: "pointer", color: "#FFF" }} />
        </Box>
        <Typography
          variant="h6"
          component="div"
          flexGrow={1}
          color="#21212"
          fontSize="22px"
          fontWeight={700}
        >
          Select Fees to Import
        </Typography>
        <Box
          bgcolor="#EDF2F2"
          display="flex"
          justifyContent="center"
          alignItems="center "
          width="32px"
        >
          <CloseIcon
            onClick={onClose}
            sx={{ cursor: "pointer", color: "#3C494C" }}
          />
        </Box>
      </Box>
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <TableContainer
          component={Box}
          sx={{
            border: "1px solid black",
            borderRadius: "8px",
            width: "90%",
            borderColor: "#BDBDBD",
            maxHeight: 228,
          }}
        >
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow>
                <StyledTableCellHeader padding="checkbox">
                  <StyledCheckbox
                    // indeterminate={
                    //   selectedIds.length > 0 && selectedIds.length < rows.length
                    // }
                    checked={
                      rows.length > 0 && selectedIds.length === rows.length
                    }
                    onChange={handleSelectAllClick}
                  />
                </StyledTableCellHeader>
                <StyledTableCellHeader>HUD Line #</StyledTableCellHeader>
                <StyledTableCellHeader sx={{ width: "60%" }}>
                  Fee Description
                </StyledTableCellHeader>
                <StyledTableCellHeader>Buyer Amount</StyledTableCellHeader>
                <StyledTableCellHeader>Seller Amount</StyledTableCellHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.HudLineNumber}
                  hover
                  onClick={() => handleClick(row.HudLineNumber)}
                  role="checkbox"
                  aria-checked={isSelected(row.HudLineNumber)}
                  selected={isSelected(row.HudLineNumber)}
                >
                  <StyledTableCell padding="checkbox">
                    <StyledCheckbox checked={isSelected(row.HudLineNumber)} />
                  </StyledTableCell>
                  <StyledTableCell>{row.HudLineNumber}</StyledTableCell>
                  <StyledTableCell>{row.Description}</StyledTableCell>
                  <StyledTableCell>
                    {typeof row.BuyerAmount === "number"
                      ? `$${row.BuyerAmount.toFixed(2)}`
                      : ""}
                  </StyledTableCell>
                  <StyledTableCell>
                    {typeof row.SellerAmount === "number"
                      ? `$${row.SellerAmount.toFixed(2)}`
                      : ""}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        paddingX={"5%"}
        paddingY={6}
      >
        <FormControlLabel
          sx={{ margin: 0 }}
          control={
            <StyledCheckbox
              checked={checkState.addFees}
              onChange={handleCheckboxChange}
              name="addFees"
              sx={{ marginX: 4 }}
            />
          }
          label="Add Fees to 2015 Itemization Form"
        />
        <FormControlLabel
          sx={{ margin: 0 }}
          control={
            <StyledCheckbox
              checked={checkState.addStewartRate}
              onChange={handleCheckboxChange}
              name="addStewartRate"
              sx={{ marginX: 4, marginY: 3 }}
            />
          }
          label="Add Stewart Rate Quote document to the eFolder"
        />
        <FormControlLabel
          sx={{ margin: 0 }}
          control={
            <StyledCheckbox
              checked={checkState.addStewartOffice}
              onChange={handleCheckboxChange}
              name="addStewartOffice"
              sx={{ marginX: 4 }}
            />
          }
          label="Add Stewart office to the Settlement Service Provider List"
        />
      </Box>
      <Box display="flex" gap={5} pb={6} px={"5%"}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onImportSelectedFees(selectedIds)}
          // disabled={selectedIds.length === 0}
        >
          Import
        </Button>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default ImportFeeGrid;
