import { Component } from 'react';

class BaseComponent extends Component {

    // shared functions here
}

// shared dispatch type methods

export default BaseComponent;
