import React from 'react';
import * as Styled from './Library.styles';
import typography from '../Theme/typography';
import colors from '../Theme/colors';
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ColorName } from "./Library.styles";
import Dropdown from "../RateCalculator/Components/Dropdown/Dropdown";
import RadioButton from "../RateCalculator/Components/Radios/RadioButton";
import RadioGroup from "../RateCalculator/Components/Radios/RadioGroup";
import Checkbox from "../RateCalculator/Components/Checkbox/Checkbox";
import Button from "../RateCalculator/Components/Button/Button";
import Input from "../RateCalculator/Components/Input/Input";
import { fauxReducer, MockDropdownOptions } from "../RateCalculator/config";
import Modal from "../RateCalculator/Components/Modal/Modal";
import * as icons from '../Assets/index';
import { CHEVRON } from "../Assets/index";
import * as components from '../RateCalculator/Components/index';
import { ConfigComponents } from './Library.config';
import BaseComponent from "../Assets/BaseComponent";

class Library extends BaseComponent {
    constructor(props) {
        super(props);

        this.iconIterate = this.iconIterate.bind(this);
        this.typographyIterate = this.typographyIterate.bind(this);
        this.componentIterate = this.componentIterate.bind(this);
        this.colorIterate = this.colorIterate.bind(this);
    }

    componentDidMount() {
        document.title = 'Theme Library';
    }
    componentIterate() {
        const Parent = styled('div')`
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          margin-left:24px;
          width:700px;
          height: 100%;
        `;
        let list= ['Button','Checkbox', 'Dropdown', 'Input', 'Radio', 'RadioBox' ];
        const themes = list.map((item, idx) => {
            let componentProps = ConfigComponents[item];
            const Component = components[item];
            console.log('componentProps', componentProps);
            return (
              <Styled.Container key={idx} className='components'>
                     {componentProps.map((props, idx) =>
                         (
                             <Styled.Theme className='vertRadio' key={idx}
                             >
                                 <Styled.Type>
                                     {item}
                                 </Styled.Type>
                                 <Parent >
                                     <Component
                                         {...props}
                                     />
                                 </Parent>
                                 <Styled.Props>
                                     {JSON.stringify(props)}
                                 </Styled.Props>
                             </Styled.Theme>

                         ))
        }
               </Styled.Container>)
        })
        return themes;
    };

    typographyIterate() {
        const items = Object.keys(typography);

        const themes = items.map((item, idx)=> {
            // @ts-ignore
           // const styled = typography[item];
            // @ts-ignore
            const Parent = styled('div')`${typography[item]} 
              display: flex;
              justify-content: left;
              flex-direction: column;
              margin-left:24px;
              width:600px;`
            return (
                <Styled.Theme key={idx} >
                    <Styled.Type>
                        {item}
                    </Styled.Type>
                    <Parent>
                        The quick brown fox jumps over the lazy dog
                    </Parent>
                </Styled.Theme>);}
        );
        return themes;
    }
    iconIterate() {
        const Parent = styled('div')`
              display: flex;
              justify-content: left;
              margin-left:24px;
              width:600px;`

            let list= [];
            for (const property in icons) {
                list.push(
                    <Styled.IconTheme >
                        <Styled.Type>
                            {property}
                        </Styled.Type>
                        <Parent>
                            <Styled.Icon src ={icons[property]}  height={'40px'} alt="icon" />
                        </Parent>
                    </Styled.IconTheme>
                );
            }
            return list;
    }

  colorIterate() {
        const items = Object.keys(colors);

        const themes = items.map((item, idx)=> {
            const Parent = styled('div')`background-color:${colors[item]};
              display: flex;
              justify-content: left;
              flex-direction: column;
              margin-left:24px;
              width:600px;`
            return (
                <Styled.ColorTheme key={idx} >
                    <Styled.Type>
                        {item}
                    </Styled.Type>
                    <Parent >
                        <Styled.ColorName/>
                    </Parent>
                </Styled.ColorTheme>

            );
        });
        return themes;
    }

    render () {

        return (
            <Styled.ThemeLibraryContainer>
                <Styled.Typography>
                    <Styled.Title>
                        COMPONENTS
                    </Styled.Title>
                    {this.componentIterate()}
                </Styled.Typography>
                <Styled.Typography>
                    <Styled.Title>
                        ICONS
                    </Styled.Title>
                    {this.iconIterate()}
                </Styled.Typography>
                <Styled.Typography>
                    <Styled.Title>
                        TYPOGRAPHY
                    </Styled.Title>
                    {this.typographyIterate()}
                </Styled.Typography>
                <Styled.Typography>
                    <Styled.Title>
                        COLORS
                    </Styled.Title>
                        {this.colorIterate()}
                </Styled.Typography>
            </Styled.ThemeLibraryContainer>
        );
    }
}

export default Library;

