import { EncompassFieldIDs, StewartConstants } from "constants/strings";
import { addEndorsements } from "./addEndorsement";
import { addEndorsementsSeparately } from "./addEndorsementsSeparately";
import { addRecordingFee } from "./addRecordingFee";
import { addTitleFees } from "./addtitleFees";
import { addTitleInsuranceFees } from "./addtitleInsurance";
import {
  loadEndorsements,
  loadPricingProps,
  loadRecordingProps,
} from "./utils";
import { addTransferTaxes } from "./addTransferTaxes";
import { addSettlementServiceProviderFees } from "./addSettlementProviderFees";

interface Policy {
  FeeType: string;
  PolicyCoverageTypeCode: string;
  PolicyCoverageTypeName: string;
  Amount: string;
  TransactionRateCode: string;
  Premium: string;
  FullPremium: string;
  PremiumDiscount: string;
  RiskRate: string;
  TitleServiceCharge: string;
  SalesTax: string;
  Splits: {
    Buyer: {
      Premium: string;
      RiskRate: string;
      TitleServiceCharge: string;
      SalesTax: string;
    };
    Seller: {
      Premium: string;
      RiskRate: string;
      TitleServiceCharge: string;
      SalesTax: string;
    };
  };
  AdditionalChargeList: string;
  EndorsementList: {
    Endorsement: Endorsement[];
  };
  AdditionalPolicies: string;
}

export interface PriceQuoteFee {
  Description?: string;
  id?: number;
  Payee?: string;
  BuyerAmount?: number;
  SellerAmount?: number;
  HudLineNumber?: string | number;
  EncompassFieldID_Description?: string;
  EncompassFieldID_Payee?: string;
  EncompassFieldID_BuyerAmount?: string;
  EncompassFieldID_SellerAmount?: string;
  EncompassFieldID_BuyerCanShop?: string;
  TotalAmount?: string | number;
  EncompassFieldID_TotalAmount?: string | number;
}

export interface Endorsement {
  feeItemCode: string;
  Name: string;
  premium: string; // Assuming the premium is a string that needs to be parsed to a number
  riskRate: string;
  salesTax: string;
  Splits: {
    Buyer: {
      Premium: string;
      SalesTax: string;
    };
    Seller: {
      Premium: string;
      SalesTax: string;
    };
  };
}

export type SettlementServiceProviderFee = {
  Description?: string;
  BuyerAmount?: number;
};

export class PriceQuote {
  public feeList: PriceQuoteFee[];
  public SettlementServiceProviderFeeList: SettlementServiceProviderFee[];
  public officeName?: string;

  // ..   private const string c_EndorsementType_Lender = "Lender";
  private static readonly c_EndorsementType_Lender: string = "Lender";
  private static readonly c_EndorsementType_Owner: string = "Owner";

  constructor() {
    this.feeList = [];
    this.SettlementServiceProviderFeeList = [];
    this.officeName = "";
  }

  public static load(
    officeName: string,
    propertyState: string,
    LenderSettings: any,
    QuoteResponse: any
  ): PriceQuote {
    const priceQuote = new PriceQuote();
    let shouldPickTRIDValues: boolean = true;
    const {
      ownerTitleServiceChargeAmount,
      ownerTRIDPremiumAmount,
      lenderTitleServiceChargeAmount,
      lenderTRIDPremiumAmount,
      OwnersSplitBuyerAmount,
      TridOwnerSplitPremium,
      PricingtridOwnerSplitsSalesTax,
      TridOwnerSplitSellerPremium,
      PricingtridOwnerSplitsSellerSalesTax,
      pricingRMOwnerPremium,
      OwnersSplitBuyerPremium,
      OwnersSplitBuyerSalesTax,
      lenderSplitsSellerpremium,
      lenderSplitsSellerSalesTax,
      lenderSplitsSellertitleService,
      lendersSplitBuyerTitleservicecharge,
      OwnerrateSplitsSellerSalesTax,
      TridLenderSplitPremium,
      PricingLenderSplitsSalesTax,
      TridLenderSplitSellerPremium,
      PricingtridLenderSplitsSellerSalesTax,
      rateMannualLenderpremium,
      rateMannualLenderSalesTax,
      lendersSplitBuyerTitleservicePremium,
      lendersSplitBuyerTitleserviceSalesTax,
      lendersSplitSellerTitleservicePremium,
      ItemizedTitleServiceFee,
    } = loadPricingProps(QuoteResponse);

    priceQuote.officeName = officeName;
    console.log(QuoteResponse, "priceQuotes");

    shouldPickTRIDValues =
      !LenderSettings?.StateNamesForQuotePricingRules?.includes(propertyState);

    PriceQuote.AddTitleInsuranceFees(priceQuote, {
      ownerTitleServiceChargeAmount,
      ownerTRIDPremiumAmount,
      lenderTitleServiceChargeAmount,
      lenderTRIDPremiumAmount,
      OwnersSplitBuyerAmount,
      shouldPickTRIDValues,
      TridOwnerSplitPremium,
      PricingtridOwnerSplitsSalesTax,
      TridOwnerSplitSellerPremium,
      PricingtridOwnerSplitsSellerSalesTax,
      pricingRMOwnerPremium,
      OwnersSplitBuyerSalesTax,
      OwnersSplitBuyerPremium,
      lenderSplitsSellerpremium,
      lenderSplitsSellerSalesTax,
      lendersSplitBuyerTitleservicecharge,
      OwnerrateSplitsSellerSalesTax,
      lenderSplitsSellertitleService,
      TridLenderSplitPremium,
      PricingLenderSplitsSalesTax,
      TridLenderSplitSellerPremium,
      PricingtridLenderSplitsSellerSalesTax,
      rateMannualLenderpremium,
      rateMannualLenderSalesTax,
      lendersSplitBuyerTitleservicePremium,
      lendersSplitBuyerTitleserviceSalesTax,
      lendersSplitSellerTitleservicePremium,
    });
    PriceQuote.addEndorsementsMain(priceQuote, QuoteResponse, LenderSettings);
    PriceQuote.AddTitleFees(priceQuote, {
      ItemizedTitleServiceFee,
      ownerTitleServiceChargeAmount,
    });
    PriceQuote.AddTransferTaxes(priceQuote, QuoteResponse);
    PriceQuote.AddRecordingFees(priceQuote, QuoteResponse);

    if (LenderSettings.ImportFeesToSSPL == true) {
      PriceQuote.AddSettlementServiceProviderFees(
        priceQuote,
        QuoteResponse,
        [this.c_EndorsementType_Owner, this.c_EndorsementType_Lender],
        shouldPickTRIDValues,
        LenderSettings
      );
    }

    return priceQuote;
  }

  private static AddTitleInsuranceFees = addTitleInsuranceFees;
  private static AddEndorsementsSeparately = addEndorsementsSeparately;
  private static AddEndorsements = addEndorsements;
  private static AddTitleFees = addTitleFees;
  private static AddRecordingFee = addRecordingFee;
  private static AddTransferTaxes = addTransferTaxes;
  private static AddSettlementServiceProviderFees =
    addSettlementServiceProviderFees;

  public static addEndorsementsMain(
    priceQuote: PriceQuote,
    jsonData: any,
    LenderSettings: any
  ): void {
    if (LenderSettings?.ShowEndorsementsSeparately) {
      this.AddEndorsementsSeparately(priceQuote, jsonData, [
        this.c_EndorsementType_Owner,
        this.c_EndorsementType_Lender,
      ]);
    } else {
      const OwnerEndorsementList: any[] = loadEndorsements(
        jsonData,
        this.c_EndorsementType_Owner
      );
      const LenderEndorsementList: any[] = loadEndorsements(
        jsonData,
        this.c_EndorsementType_Lender
      );

      this.AddEndorsements(
        priceQuote,
        OwnerEndorsementList,
        this.c_EndorsementType_Owner
      );
      this.AddEndorsements(
        priceQuote,
        LenderEndorsementList,
        this.c_EndorsementType_Lender
      );
    }
  }

  public static AddRecordingFees(priceQuote: PriceQuote, jsonData: any): void {
    const { Recording = {} } = jsonData;
    console.log(Recording, "Recording");
    if (!Object.keys(Recording).length) return;
    const { Deed, Mortgage, Release } = loadRecordingProps(Recording);

    this.AddRecordingFee(
      priceQuote,
      Deed,
      StewartConstants.RecordingFeeDeedDescription,
      EncompassFieldIDs.HUDLine1202DeedAmount
    );
    this.AddRecordingFee(
      priceQuote,
      Mortgage,
      StewartConstants.RecordingFeeMortgageDescription,
      EncompassFieldIDs.HUDLine1202MortgageAmount
    );
    this.AddRecordingFee(
      priceQuote,
      Release,
      StewartConstants.RecordingFeeReleaseDescription,
      EncompassFieldIDs.HUDLine1202ReleaseAmount
    );
  }
}
