import { StewartConstants } from "constants/strings";
import { PriceQuote, PriceQuoteFee } from "./priceQuotes";
import { getAmount } from "./utils";

export function addRecordingFee(
  priceQuote: PriceQuote,
  jsonData: any,
  description: string,
  fieldID: string
): void {
  const { Fee, BuyerSplit, SellerSplit } = jsonData;
  const recordingFeeTotalAmount = getAmount(Fee);
  const recordingFeeBuyerAmount = getAmount(BuyerSplit);
  const recordingFeeSellerAmount = getAmount(SellerSplit);

  if (recordingFeeTotalAmount !== 0) {
    const recordingFee: PriceQuoteFee = {
      HudLineNumber: StewartConstants.RecordingFeesLineNumber,
      Description: description,
      TotalAmount: recordingFeeTotalAmount,
      BuyerAmount: recordingFeeBuyerAmount,
      SellerAmount: recordingFeeSellerAmount,
      EncompassFieldID_TotalAmount: fieldID,
    };

    if (recordingFeeBuyerAmount === 0 && recordingFeeSellerAmount === 0) {
      recordingFee.BuyerAmount = recordingFeeTotalAmount;
    }

    priceQuote.feeList.push(recordingFee);
  }
}
