import theme from './colors';

function generateCSS(fontFamily: string, fontWeight: number, fontSize: number, lineHeight: number, letterSpacing: number, textColor:string
    // , fontStyle: string | null | undefined, textDecoration: string | undefined
) {
    let css = '';
    if (value(fontFamily)) css += '\n' + `font-family: ${fontFamily};`;
    if (value(fontWeight)) css += '\n' + `font-weight: ${fontWeight};`;
    if (value(fontSize)) css += '\n' + `font-size: ${fontSize}px;`;
    if (value(lineHeight)) css += '\n' + `line-height: ${lineHeight}px;`;
    if (value(letterSpacing)) css += '\n' + `letter-spacing: ${letterSpacing}px;`;
    if (value(textColor)) css += '\n' + `color: ${textColor};`;
    // if (value(fontStyle)) css += '\n' + `font-style: ${fontStyle};`;
    // if (value(textDecoration)) css += '\n' + `text-decoration: ${textDecoration};`;
    return css;
}

function value(variable: string | number) {
    const acceptableValues = {
        'string': true,
        'number': true,
    };
    const type = typeof variable;
    let hasValue = false;
    // @ts-ignore
    if (acceptableValues[type]) hasValue = true;
    return hasValue;
}

const Roboto = '\'Roboto\',sans-serif';

const typography = {
    sectionTitle: generateCSS(Roboto, 700, 25, 24, 0.15, theme.titleBlue),
    gridTitle: generateCSS(Roboto, 700, 20, 24, 0.15, theme.title),
    rowTitle: generateCSS(Roboto, 700, 16, 24, 0.15, theme.title),
    grid: generateCSS(Roboto, 400, 16, 24, 0.15, theme.input),
    buttonPrimary: generateCSS(Roboto, 700, 14, 24, 0.4, theme.background),
    buttonSecondary: generateCSS(Roboto, 700, 14, 24, 0.4, theme.titleBlue),
    buttonPrimarySm: generateCSS(Roboto, 700, 12, 24, 0.4, theme.background),
    buttonSecondarySm: generateCSS(Roboto, 700, 12, 24, 0.4, theme.titleBlue),
    link: generateCSS(Roboto, 400, 12, 10, 0.4, theme.titleBlue),
    text: generateCSS(Roboto, 400, 12, 10, 0.4, theme.title),
    radio: generateCSS(Roboto, 400, 13, 10, 0.4, theme.title),
    modalTitle: generateCSS(Roboto, 700, 22, 22, 0.4, theme.title),

};

export default typography;
