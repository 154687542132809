import styled from '@emotion/styled'
import colors from '../Theme/colors';
import typography from '../Theme/typography';
export const ThemeLibraryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  background-color: white;
`;

export const Typography = styled.div`
  display: flex;
  justify-content: left;
  flex-direction: column;
  margin: 0 auto;
  width:100%;
  min-height:600px;
`;

export const Theme = styled.div`
  display: flex;
  flex-direction: row;
  width:100%;
  height:100%;
  margin-bottom:8px;
  justify-content: left;
  align-items: center;
  &.vertRadio {
   min-height: 40px;
  }
`;
export const IconTheme = styled.div`
  display: flex;
  flex-direction: row;
  width:100%;
  min-height:40px;
  margin-bottom:8px;
  justify-content: left;
  height: 50px;
`;
export const ColorTheme = styled.div`
  display: flex;
  flex-direction: row;
  width:100%;
  margin-bottom:8px;
  justify-content: left;
`;
export const Title = styled.div`
  display: flex;
  width:100%;
  min-height:40px;
  justify-content: left;
  align-items: center;
  background-color: ${colors.border};
  ${typography.gridTitle}
  margin-bottom:24px;
  padding-left: 24px;
`;

export const Type = styled.div`
  display: flex;
  justify-content: right;
  width:200px;
  ${typography.rowTitle}
  align-items: center;
  height: 100%;
`;
export const Icon = styled.img`
  justify-content: left;
  align-items: center;
  background-color: ${colors.liteBackground};
`;
export const Props = styled.div`
  justify-content: left;
  align-items: flex-start;
  width: 600px;
  ${typography.grid}
`;

export const ColorName = styled.div`
  ${typography.rowTitle}
  margin-left:10px;
  display:flex;
  align-self: center;
`;

export const Container = styled.div`
  ${typography.rowTitle}
  margin-left:10px;
  display:flex;
  flex-direction: column;
  &.components {
  }
`;




