import styled from '@emotion/styled';
import colors from '../../../Theme/colors';
import typography from '../../../Theme/typography';

export const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity linear 0.15s;
  z-index: 2000;
  margin: 40px auto;
  &.fade-in {
    opacity: 1;
    transition: opacity linear 0.15s;
  }
  &.fade-out {
    opacity: 0;
    transition: opacity linear 0.15s;
  }
  .background {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 1040;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    outline: 0;
  }
`;

export const HeaderIcon = styled.img`
  display: flex;
  flex-direction: row;
  width:24px;
  height:24px;
  align-items: center;
`;

export const IconBox = styled.div`
  display: flex;
  width:56px;
  height:56px;
  background-color:${colors.titleBlue};
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  z-index: 1080;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  height:56px;
  align-items: center;
  margin-bottom:24px;
`;
export const HeaderText = styled.div`
  display: flex;
  ${typography.modalTitle}
  width: 672px;
  margin-left: 24px;
`;

export const CloseButton = styled.img`
  display: flex;
  height: 24px;
  width: 24px;
  margin-bottom: 32px;
  cursor: pointer;
  background-color:${colors.liteBackground};
  border-radius: 50%;
  align-self: flex-end;
  &:hover {
  }
`;

export const ModalBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 855px;
  z-index: 1050;
  background-color: ${colors.background};
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  padding: 36px 40px 40px 40px;
`;

export const ModalBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: auto;
  height: 700px;
  border: 1px solid ${colors.border};
  border-radius: 4px;
`;

export const SearchBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex: auto;
  height: 44px;
  border-bottom: 1px solid ${colors.border};
`;

export const ListBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: auto;
  height: 700px;
  font-size: 14px;
`;

export const ModalButtons = styled.div`
  display: flex;
  flex-direction: row;
  margin-top:24px;
`;

export const Spacer = styled.div`
  width:  ${(props) => (props.width ? props.width : `100%`)};
  height: 100%;
`;
