import { EncompassFieldIDs, StewartConstants } from "constants/strings";
import { PriceQuote, PriceQuoteFee } from "./priceQuotes";
import { getEndorsementAmount, getEndorsementNode } from "./utils";

export function addEndorsements(
  priceQuote: PriceQuote,
  jsonData: any,
  endorsementType: string
): void {
  let amount = 0,
    buyerAmount = 0,
    sellerAmount = 0,
    totalAmount = 0,
    totalBuyerAmount = 0,
    totalSellerAmount = 0,
    cplEndorsementCount = 0;
  const cplEndorsements = [];
  for (const endorsement of jsonData) {
    const {
      Premium,
      SalesTax,
      buyerPremium,
      buyerSalesTax,
      sellelrPremium,
      sellelrSalesTax,
      Name: feeName,
    } = getEndorsementNode(endorsement);

    amount = getEndorsementAmount(Premium, SalesTax);
    buyerAmount = getEndorsementAmount(buyerPremium, buyerSalesTax);
    sellerAmount = getEndorsementAmount(sellelrPremium, sellelrSalesTax);

    if (buyerAmount == 0 && sellerAmount == 0) {
      buyerAmount = amount;
    }

    if (
      feeName
        ?.toUpperCase()
        .includes(
          StewartConstants?.ClosingProtectionLetterDescription?.toUpperCase()
        ) ||
      feeName
        ?.toUpperCase()
        .includes(
          StewartConstants?.InsuredClosingLetterDescription?.toUpperCase()
        ) ||
      feeName
        ?.toUpperCase()
        .includes(StewartConstants?.CPLDescription?.toUpperCase())
    ) {
      cplEndorsements.push(endorsement); // Use push to add to the array
    } else {
      totalAmount += amount;
      totalBuyerAmount += buyerAmount;
      totalSellerAmount += sellerAmount;
    }
  }

  if (totalAmount !== 0) {
    const endorsementFee: PriceQuoteFee = {};

    // String formatting using template literals
    endorsementFee.Description = `${endorsementType}'s Endorsement(s)`;

    endorsementFee.Payee = priceQuote.officeName; // Assuming officeName is public or you have a getter
    endorsementFee.BuyerAmount = totalBuyerAmount;
    endorsementFee.SellerAmount = totalSellerAmount;

    const fieldIdMapping: { [key: string]: { [key: string]: string } } = {
      Owner: {
        HudLineNumber: "1110",
        EncompassFieldID_Description: EncompassFieldIDs.HUDLine1110Description,
        EncompassFieldID_Payee: EncompassFieldIDs.HUDLine1110Payee,
        EncompassFieldID_BuyerAmount: EncompassFieldIDs.HUDLine1110BuyerAmount,
        EncompassFieldID_SellerAmount:
          EncompassFieldIDs.HUDLine1110SellerAmount,
        EncompassFieldID_BuyerCanShop:
          EncompassFieldIDs.HUDLine1110BuyerCanShop,
      },
      Lender: {
        HudLineNumber: "1111",
        EncompassFieldID_Description: EncompassFieldIDs.HUDLine1111Description,
        EncompassFieldID_Payee: EncompassFieldIDs.HUDLine1111Payee,
        EncompassFieldID_BuyerAmount: EncompassFieldIDs.HUDLine1111BuyerAmount,
        EncompassFieldID_SellerAmount:
          EncompassFieldIDs.HUDLine1111SellerAmount,
        EncompassFieldID_BuyerCanShop:
          EncompassFieldIDs.HUDLine1111BuyerCanShop,
      },
    };

    // Assign properties based on endorsementType
    if (endorsementType in fieldIdMapping) {
      const fieldIds = fieldIdMapping[endorsementType];
      console.log(endorsementType, "endorsementTsdsype", fieldIds);
      Object.assign(endorsementFee, fieldIds);
    } else {
      // Handle the case where endorsementType is not "Owner" or "Lender"
      console.error("Invalid endorsement type!");
      // Or throw an error if you prefer
    }

    priceQuote.feeList.push(endorsementFee);
  }

  for (const cplEndorsement of cplEndorsements) {
    cplEndorsementCount++;
    const {
      Premium,
      SalesTax,
      buyerPremium,
      buyerSalesTax,
      sellelrPremium,
      sellelrSalesTax,
      Name: feeName,
    } = getEndorsementNode(cplEndorsement);

    amount = getEndorsementAmount(Premium, SalesTax);
    buyerAmount = getEndorsementAmount(buyerPremium, buyerSalesTax);
    sellerAmount = getEndorsementAmount(sellelrPremium, sellelrSalesTax);

    if (buyerAmount === 0 && sellerAmount === 0) {
      buyerAmount = amount;
    }

    const endorsementFee: PriceQuoteFee = {};
    endorsementFee.Description = feeName; // Assuming 'Name' is a property of cplEndorsement
    endorsementFee.Payee = priceQuote.officeName;
    endorsementFee.BuyerAmount = buyerAmount;
    endorsementFee.SellerAmount = sellerAmount;

    if (endorsementType === "Owner") {
      endorsementFee.HudLineNumber = "1303";
      endorsementFee.EncompassFieldID_Description =
        EncompassFieldIDs.HUDLine1303Description;
      endorsementFee.EncompassFieldID_Payee =
        EncompassFieldIDs.HUDLine1303Payee;
      endorsementFee.EncompassFieldID_BuyerAmount =
        EncompassFieldIDs.HUDLine1303BuyerAmount;
      endorsementFee.EncompassFieldID_SellerAmount =
        EncompassFieldIDs.HUDLine1303SellerAmount;
      priceQuote.feeList.push(endorsementFee);
      // No need for 'break' in TypeScript's if-else structure
    } else {
      // Define a mapping object to store the field IDs for each cplEndorsementCount
      const fieldIdMapping: { [key: number]: { [key: string]: string } } = {
        1: {
          HudLineNumber: "1112",
          EncompassFieldID_Description:
            EncompassFieldIDs.HUDLine1112Description,
          EncompassFieldID_Payee: EncompassFieldIDs.HUDLine1112Payee,
          EncompassFieldID_BuyerAmount:
            EncompassFieldIDs.HUDLine1112BuyerAmount,
          EncompassFieldID_SellerAmount:
            EncompassFieldIDs.HUDLine1112SellerAmount,
          EncompassFieldID_BuyerCanShop:
            EncompassFieldIDs.HUDLine1112BuyerCanShop,
        },
        2: {
          HudLineNumber: "1113",
          EncompassFieldID_Description:
            EncompassFieldIDs.HUDLine1113Description,
          EncompassFieldID_Payee: EncompassFieldIDs.HUDLine1113Payee,
          EncompassFieldID_BuyerAmount:
            EncompassFieldIDs.HUDLine1113BuyerAmount,
          EncompassFieldID_SellerAmount:
            EncompassFieldIDs.HUDLine1113SellerAmount,
          EncompassFieldID_BuyerCanShop:
            EncompassFieldIDs.HUDLine1113BuyerCanShop,
        },
        3: {
          HudLineNumber: "1114",
          EncompassFieldID_Description:
            EncompassFieldIDs.HUDLine1114Description,
          EncompassFieldID_Payee: EncompassFieldIDs.HUDLine1114Payee,
          EncompassFieldID_BuyerAmount:
            EncompassFieldIDs.HUDLine1114BuyerAmount,
          EncompassFieldID_SellerAmount:
            EncompassFieldIDs.HUDLine1114SellerAmount,
          EncompassFieldID_BuyerCanShop:
            EncompassFieldIDs.HUDLine1114BuyerCanShop,
        },
        4: {
          HudLineNumber: "1115",
          EncompassFieldID_Description:
            EncompassFieldIDs.HUDLine1115Description,
          EncompassFieldID_Payee: EncompassFieldIDs.HUDLine1115Payee,
          EncompassFieldID_BuyerAmount:
            EncompassFieldIDs.HUDLine1115BuyerAmount,
          EncompassFieldID_SellerAmount:
            EncompassFieldIDs.HUDLine1115SellerAmount,
          // Note: EncompassFieldID_BuyerCanShop is missing in case 4, so we omit it here
        },
        5: {
          HudLineNumber: "1116",
          EncompassFieldID_Description:
            EncompassFieldIDs.HUDLine1116Description,
          EncompassFieldID_Payee: EncompassFieldIDs.HUDLine1116Payee,
          EncompassFieldID_BuyerAmount:
            EncompassFieldIDs.HUDLine1116BuyerAmount,
          EncompassFieldID_SellerAmount:
            EncompassFieldIDs.HUDLine1116SellerAmount,
          // Note: EncompassFieldID_BuyerCanShop is missing in case 5, so we omit it here
        },
      };

      // Assign properties based on cplEndorsementCount
      if (cplEndorsementCount in fieldIdMapping) {
        const fieldIds = fieldIdMapping[cplEndorsementCount];
        Object.assign(endorsementFee, fieldIds);
      } else {
        // Handle the case where cplEndorsementCount is out of range
        console.error("Invalid CPL endorsement count!");
        // Or throw an error if you prefer
      }

      priceQuote.feeList.push(endorsementFee);
    }
  }
}
